import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { ManageSchemaLibraryTagsButton } from "@kraaft/shared/core/modules/schemaLibraryTag/components/manageSchemaLibraryTagsButton";
import { selectSchemaLibraryTagArrayByLanguage } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.selectors";
import { Spacing, Text } from "@kraaft/ui";

import { FilteredListItem } from "../../dropdown/filteredList";
import { KDropdown } from "../../dropdown/kDropdown";

interface EditSchemaLibraryTagsProps {
  tagIds: Array<string> | undefined;
  onChangeTagIds: (tagIds: Array<string> | undefined) => void;
  language: LibrarySchemaLanguage;
}

export const EditSchemaLibraryTags = ({
  tagIds,
  onChangeTagIds,
  language,
}: EditSchemaLibraryTagsProps) => {
  const { t } = useTranslation();

  const schemaLibraryTags = useSelector(
    selectSchemaLibraryTagArrayByLanguage(language),
  );

  const schemaLibraryTagsSelectableItems = useMemo(() => {
    return schemaLibraryTags.map<FilteredListItem>((tag) => ({
      value: tag.id,
      label: tag.name,
    }));
  }, [schemaLibraryTags]);

  return (
    <div>
      <Text size="H2" weight="bold" style={styles.textSpacer}>
        {t("schemaLibraryViewTags.settings.title")}
      </Text>
      <View style={styles.filter}>
        <View style={styles.inputContainer}>
          <KDropdown
            allowMultiple
            items={schemaLibraryTagsSelectableItems}
            selectedItemIds={tagIds}
            variant="dark"
            onSelectionChange={onChangeTagIds}
            withEmptyRow
            withSearchBar
            placeholder={t("schemaLibraryViewTags.settings.noTagAssigned")}
          />
        </View>
        <ManageSchemaLibraryTagsButton language={language} />
      </View>
    </div>
  );
};

const styles = StyleSheet.create({
  textSpacer: {
    display: "flex",
    marginBottom: Spacing.S16,
  },
  filter: {
    maxWidth: 400,
    flexDirection: "row",
    gap: Spacing.S8,
  },
  inputContainer: {
    minWidth: 200,
    justifyContent: "center",
  },
});
