import { ImageContentFit } from "expo-image";
import { ImageStyle, StyleProp } from "react-native";

import { GeoCoordinates } from "@kraaft/shared/core/types";

import { ImagePropsPreferredSizeHelper, ImageSource } from "./image.type";
import { ImageDynamicSize } from "./imageDynamicSize";
import { ImageLoadedData } from "./imageImplementation.types";
import { ImageFormat, ImageProxyBuilder } from "./imageProxyBuilder";

export type { ImagePropsPreferredSizeHelper, ImageSource };
export { type ImageFormat, ImageProxyBuilder };

export type ImageProps<
  Source extends ImageSource = ImageSource,
  isLocal extends boolean = boolean,
> = {
  quality?: number;
  debugProxy?: boolean;
  geolocation?: GeoCoordinates;
  format?: ImageFormat;
  source: Source;
  contentFit?: ImageContentFit;
  placeholder?: Source;
  placeholderContentFit?: ImageContentFit;
  testID?: string | undefined;
  onLoad?(data: ImageLoadedData): void;
  style?: StyleProp<ImageStyle>;
  fadeDuration?: number;
  alt?: string;
  draggable?: boolean;
} & ImagePropsPreferredSizeHelper<Source, isLocal>;

export const Image = <
  Source extends ImageSource = ImageSource,
  isLocal extends boolean = boolean,
>({
  preferredSize,
  source,
  debugProxy = false,
  testID,
  onLoad,
  contentFit,
  format,
  geolocation,
  placeholder,
  placeholderContentFit,
  quality,
  style,
  fadeDuration,
  alt,
  draggable,
}: ImageProps<Source, isLocal>) => {
  return (
    <ImageDynamicSize
      source={source}
      onLoad={onLoad}
      debugProxy={debugProxy}
      preferredSize={preferredSize}
      contentFit={contentFit}
      format={format}
      geolocation={geolocation}
      placeholder={placeholder}
      placeholderContentFit={placeholderContentFit}
      quality={quality}
      style={style}
      fadeDuration={fadeDuration}
      testID={testID}
      alt={alt}
      draggable={draggable}
    />
  );
};
