import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
  AskUserJobForm,
  OTHER_USER_JOB_IDENTIFIER,
} from "@kraaft/shared/components/onboarding/askUserJob/askUserJobForm";
import { useUserJob } from "@kraaft/shared/components/onboarding/askUserJob/useUserJob";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./askUserJob.styles";

const AskUserJob = () => {
  const { t } = useTranslation();
  const { setUserJob, loading } = useUserJob();

  useTrackEventOnce({
    eventName: "Onboarding Need To Select Job",
  });

  const [selected, setSelected] = useState<string>("");
  const [other, setOther] = useState("");

  const next = useCallback(() => {
    const finalJob = selected === OTHER_USER_JOB_IDENTIFIER ? other : selected;
    setUserJob(finalJob).catch(console.error);
    trackEvent({
      eventName: "Onboarding Selected Job",
      job: finalJob,
    });
  }, [other, selected, setUserJob]);

  return (
    <View style={styles.root}>
      <Text weight="bold" size="H1" style={styles.jobText}>
        {t("userJob.whatIsYourJob")}
      </Text>
      <AskUserJobForm
        setSelected={setSelected}
        setOther={setOther}
        selected={selected}
        other={other}
      />
      <View style={styles.spacer} />
      <Button
        loading={loading}
        disabled={
          !selected || (selected === OTHER_USER_JOB_IDENTIFIER && !other)
        }
        style={styles.nextButton}
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={next}
      />
    </View>
  );
};

export { AskUserJob };
