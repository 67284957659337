import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Platform, Pressable, StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { CarouselVideoProps } from "@kraaft/shared/components/carousel/placeholders/carousel.props";
import { GenericCarouselAttachment } from "@kraaft/shared/components/carousel/placeholders/genericCarouselAttachment";
import { ImageManipulation } from "@kraaft/shared/components/carousel/placeholders/imageManipulation";
import { useLocalPathFromFilePath } from "@kraaft/shared/core/modules/file/fileAllocator/useLocalPathFromFilePath";
import { GeoLocation } from "@kraaft/shared/core/types";
import { getVideoMedia } from "@kraaft/shared/core/utils/utils";
import {
  CAROUSEL_UI_BACKGROUND,
  CarouselUI,
  Icon,
  Radius,
  Spacing,
  useBooleanFromSharedValue,
  useCarousel,
  useCarouselIsFocused,
  useVideoPlayerContext,
  VideoPlayer,
  VideoPlayerContext,
  VideoPlayerSlider,
} from "@kraaft/ui";

const CarouselVideo_ = <T,>({
  index,
  item,
  attachment,
  onLocationChange,
  roomId,
  onClose,
  uploadContext,
  onDelete,
}: CarouselVideoProps<T>) => {
  const { Tool } = useCarousel();
  const isFocused = useCarouselIsFocused(index);
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });

  const { context, ref } = useVideoPlayerContext();

  useEffect(() => {
    if (!isFocused) {
      ref.current?.pause();
    }
  }, [isFocused, ref]);

  const isPlaying = useBooleanFromSharedValue(context.playing);

  const handleLocationChange = useCallback(
    (location: GeoLocation | null | undefined) => {
      onLocationChange?.(item, location);
    },
    [item, onLocationChange],
  );

  const handleDelete = useCallback(() => {
    onDelete?.(item);
  }, [item, onDelete]);

  const videoMedia = getVideoMedia(attachment);

  const videoLocalPath = useLocalPathFromFilePath(videoMedia.downloadUrl);

  const video = useMemo(
    () =>
      videoLocalPath ? (
        <VideoPlayer
          autoplay={false}
          url={videoLocalPath}
          ref={ref}
          style={styles.video}
          onVideoSize={setVideoSize}
        />
      ) : null,
    [ref, videoLocalPath],
  );
  return (
    <VideoPlayerContext.Provider value={context}>
      <GenericCarouselAttachment
        attachment={attachment}
        index={index}
        onLocationChange={onLocationChange ? handleLocationChange : undefined}
        roomId={roomId}
        onClose={onClose}
        uploadContext={uploadContext}
        onDelete={handleDelete}
      >
        {isNative() && (
          <Tool index={index} forwardMeshContext={[VideoPlayerContext]}>
            <View style={styles.slider}>
              <VideoPlayerSlider />
            </View>
          </Tool>
        )}
        {isNative() && videoLocalPath && (
          <ImageManipulation
            name={videoMedia.filename}
            path={videoLocalPath}
            mediaSize={videoSize}
          >
            {video}
          </ImageManipulation>
        )}
        {!isNative() && video}
        {isNative() && !isPlaying && (
          <CarouselUI style={styles.playWrapper} index={index} inverted>
            <Pressable
              style={styles.playBackground}
              onPress={() => context.play()}
              accessibilityLabel="play"
            >
              <Icon
                style={styles.playIcon}
                name="play-fill"
                color="white"
                size="LARGE"
              />
            </Pressable>
          </CarouselUI>
        )}
      </GenericCarouselAttachment>
    </VideoPlayerContext.Provider>
  );
};

export const CarouselVideo = memo(CarouselVideo_) as typeof CarouselVideo_;

const styles = StyleSheet.create({
  slider: {
    paddingTop: Spacing.S16,
    paddingHorizontal: Spacing.S8,
  },
  playWrapper: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "box-none",
  },
  playBackground: {
    padding: Spacing.S16,
    borderRadius: Radius.MAXIMUM,
    backgroundColor: CAROUSEL_UI_BACKGROUND,
  },
  playIcon: {
    transform: [{ translateX: 2 }],
  },
  video: {
    flexGrow: 1,
    paddingVertical: Platform.select({ web: Spacing.S32, default: undefined }),
    paddingHorizontal: Platform.select({
      web: Spacing.S128,
      default: undefined,
    }),
  },
});
