import { createSelector } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import memoize from "lodash/memoize";
import orderBy from "lodash/orderBy";

import { compactMap } from "@kraaft/helper-functions";
import { ReadingStatus } from "@kraaft/shared/core/modules/filter/filterState";
import {
  AnyRoomCard,
  RoomCardQueryContext,
  RoomMemberCard,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";
import { RootState } from "@kraaft/shared/core/store";
import {
  getEmptyArray,
  lodashKeyResolver,
} from "@kraaft/shared/core/utils/utils";

function selectState({ roomCard }: RootState) {
  return roomCard;
}

export const selectAllRoomCards = createSelector(
  selectState,
  ({ roomCards }) => roomCards,
);

export const selectRoomCardsPage = memoize((filterId: string | undefined) =>
  createSelector(selectState, ({ pages }) =>
    filterId ? pages[filterId] : undefined,
  ),
);

export const selectAlreadyMoreRoomCardsLoaded = memoize((filterId: string) =>
  createSelector(
    selectRoomCardsPage(filterId),
    (page) => page?.cursorContext?.alreadyMoreLoaded,
  ),
);

export const selectRoomCardQueryContext = memoize((filterId: string) =>
  createSelector(selectRoomCardsPage(filterId), (page) => page?.query),
);

export const selectRoomCardCursorContext = memoize((filterId: string) =>
  createSelector(selectRoomCardsPage(filterId), (page) => page?.cursorContext),
);

export const selectCursorForFirstLoadedRoomCard = memoize((filterId: string) =>
  createSelector(
    selectRoomCardCursorContext(filterId),
    (cursorContext) => cursorContext?.firstLoaded,
  ),
);

export const selectIsLoadingMoreRoomCards = memoize(
  (filterId: string | undefined) =>
    createSelector(
      selectRoomCardsPage(filterId),
      (page) => page?.isLoadingMore,
    ),
);

export const selectPinnedRoomCardIds = memoize((poolId: string | undefined) =>
  createSelector(selectState, (state) => {
    if (poolId === undefined) {
      return getEmptyArray<string>();
    }
    return state.pinnedRoomCardIds[poolId] ?? getEmptyArray<string>();
  }),
);

export const selectFilteredPinnedRoomCards = memoize(
  (queryContext: RoomCardQueryContext, justReadRoomId?: string) => {
    return createSelector(
      selectAllRoomCards,
      selectPinnedRoomCardIds(queryContext.poolId),
      (allRoomCards, pinnedIds) => {
        const pinnedRoomCards = compactMap(
          pinnedIds,
          (id) => allRoomCards[id],
        ) as RoomMemberCard[];

        return orderBy(
          pinnedRoomCards.filter(
            RoomCardUtils.filter({
              filters: queryContext.filters,
              pinned: true,
              justReadRoomId,
            }),
          ),
          (roomCard) => roomCard.pinnedAt,
          "desc",
        );
      },
    );
  },
  lodashKeyResolver,
);

export const selectFilteredRoomCards = memoize(
  (queryContext: RoomCardQueryContext, justReadRoomId?: string) => {
    const filterId = RoomCardUtils.computeSubscriptionFilterId(queryContext);
    return createSelector(
      selectAllRoomCards,
      selectRoomCardsPage(filterId),
      (allRoomCards, page): AnyRoomCard[] => {
        if (!page) {
          return getEmptyArray();
        }

        const array =
          justReadRoomId &&
          queryContext.filters.readingStatus === ReadingStatus.UNREAD
            ? uniq([
                justReadRoomId,
                ...page.idsFromSubscription,
                ...page.idsFromLoaded,
              ])
            : [...page.idsFromSubscription, ...page.idsFromLoaded];

        const roomCards = compactMap(array, (id) => allRoomCards[id]);

        return orderBy(
          roomCards.filter(
            RoomCardUtils.filter({
              filters: queryContext.filters,
              pinned: false,
              justReadRoomId: justReadRoomId,
            }),
          ),
          (roomCard) => roomCard.lastEventAt,
          "desc",
        );
      },
    );
  },
  lodashKeyResolver,
);

export const selectAreRoomCardsLoading = memoize(
  (filterId: string | undefined) =>
    createSelector(selectRoomCardsPage(filterId), (page) => page?.isLoading),
);

export const selectArePinnedRoomCardsLoading = createSelector(
  selectState,
  (state) => state.arePinnedRoomCardsLoading,
);

export const selectHasMoreRoomCards = memoize((filterId: string | undefined) =>
  createSelector(
    selectRoomCardsPage(filterId),
    (page) => page?.cursorContext.hasMore,
  ),
);

export const selectRoomCardPageHasError = memoize(
  (filterId: string | undefined) =>
    createSelector(
      selectRoomCardsPage(filterId),
      (page) => page?.cursorContext.hasError,
    ),
);

export const selectRoomCard = memoize((roomId: string) =>
  createSelector(selectAllRoomCards, (roomCards) => roomCards[roomId]),
);

export const selectRoomCardSearchText = memoize(
  (filterId: string | undefined) =>
    createSelector(
      selectRoomCardsPage(filterId),
      (page) => page?.query.filters.searchText,
    ),
);

export const selectIsRoomPinnable = memoize((roomId: string) =>
  createSelector(
    selectRoomCard(roomId),
    (roomCard) => roomCard?.type === "member" && !roomCard.archived,
  ),
);

export const selectIsRoomPinned = memoize((roomId: string) =>
  createSelector(selectRoomCard(roomId), (roomCard) => {
    return roomCard?.pinned;
  }),
);

export const selectPinnedRoomsAmount = memoize((poolId: string) =>
  createSelector(
    selectState,
    (state) => state.pinnedRoomCardIds[poolId]?.length ?? 0,
  ),
);
