import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { noop } from "ts-essentials";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { getInviteLinkForTarget } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.utils";
import { Button, Color, Preloader, Text } from "@kraaft/ui";

import { Box } from "../box";
import { useCopyButton } from "../copyButton/copyButton";

interface InviteCollaboratorsAtPoolCreationInputProps {
  poolId: string;
}

export const InviteCollaboratorsAtPoolCreationInput = ({
  poolId,
}: InviteCollaboratorsAtPoolCreationInputProps) => {
  const [link, setLink] = useState("");

  const { t } = useTranslation();

  const pool = useSelector(selectOnePool(poolId));

  useEffect(() => {
    const updateLink = async () => {
      setLink("");
      setLink(
        await getInviteLinkForTarget({
          type: "pool",
          poolId,
        }),
      );
    };

    updateLink().catch(console.error);
  }, [poolId]);

  const { copyToClipboard, icon: rightCopyIcon } = useCopyButton({
    value: link,
  });

  return (
    <>
      <Box mb="S16">
        <Text size="BODY" color="FONT_LOW_EMPHASIS">
          {t("inviteCollaboratorsAtPoolCreation.subtitleWeb")}
        </Text>
      </Box>
      <Box mb="S8">
        <KInput
          label={t("shareInviteCollaborators.linkLabel", {
            poolName: pool?.name,
          })}
          value={link}
          onChangeText={noop}
          editable={false}
          onFocus={copyToClipboard}
          rightIcon={rightCopyIcon}
          selectTextOnFocus
        />
        {!link && <Preloader absoluteFill backgroundColor={Color.WHITE} />}
      </Box>
      <Box items="flex-start">
        <Button
          variant="QUATERNARY"
          accessibilityLabel={t("inviteCollaboratorsAtPoolCreation.copy")}
          text={t("inviteCollaboratorsAtPoolCreation.copy")}
          onPress={copyToClipboard}
          icon="copy-06"
        />
      </Box>
    </>
  );
};
