import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";
import sortBy from "lodash/sortBy";

import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { OfflineSchemaLibraryTagSelectors } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.offline";
import { RootState } from "@kraaft/shared/core/store";

export const selectState = ({ schemaLibraryTag }: RootState) =>
  schemaLibraryTag;

export const selectSchemaLibraryTagArrayByLanguage = memoize(
  (language: LibrarySchemaLanguage) =>
    createSelector(
      OfflineSchemaLibraryTagSelectors.selectAllArray,
      (schemaLibraryTags) =>
        sortBy(
          schemaLibraryTags.filter((tag) => tag.language === language),
          (tag) => tag.index,
        ),
    ),
);

export const selectSchemaLibraryTagByLanguage = memoize(
  (language: LibrarySchemaLanguage) =>
    createSelector(
      OfflineSchemaLibraryTagSelectors.selectAllArray,
      (schemaLibraryTags) =>
        Object.fromEntries(
          schemaLibraryTags
            .filter((tag) => tag.language === language)
            .map((tag) => [tag.id, tag]),
        ),
    ),
);

export const selectSchemaLibraryTagCountByLanguage = memoize(
  (language: LibrarySchemaLanguage) =>
    createSelector(
      selectSchemaLibraryTagArrayByLanguage(language),
      (schemaLibraryTags) => schemaLibraryTags.length,
    ),
);
