import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { poolCompanyIndustries } from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry/poolCompanyIndustry";

interface AskPoolCompanyIndustryFormProps {
  setSelected: (value: string) => void;
  setOther: (value: string) => void;
  selected: string;
  other: string;
}

export const OTHER_COMPANY_INDUSTRY_IDENTIFIER = "other";

export const AskPoolCompanyIndustryForm = ({
  selected,
  setSelected,
  setOther,
  other,
}: AskPoolCompanyIndustryFormProps) => {
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (newSelection: string[]) => {
      const [newSelected] = newSelection;

      if (!newSelected) {
        return;
      }

      setSelected(newSelected);
    },
    [setSelected],
  );

  const items = useMemo(
    () => [
      ...poolCompanyIndustries.map((companyIndustry) => ({
        label: t(`poolCompanyIndustry.choices.${companyIndustry}`),
        identifier: companyIndustry,
      })),
      {
        label: t("poolCompanyIndustry.choices.other"),
        identifier: OTHER_COMPANY_INDUSTRY_IDENTIFIER,
      },
    ],
    [t],
  );

  return (
    <>
      <KSelectionList
        selected={[selected]}
        setSelected={handleSelect}
        items={items}
        noPadding
        disableScroll
      />
      {selected === OTHER_COMPANY_INDUSTRY_IDENTIFIER && (
        <KInput
          label={t("poolCompanyIndustry.fillYourCompanyIndustry")}
          value={other}
          onChangeText={setOther}
        />
      )}
    </>
  );
};
