import { useMemo } from "react";
import { FlatList, FlatListProps, StyleSheet, View } from "react-native";

import { PressableMemberItem } from "@kraaft/shared/components/members/pressableMemberItem";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Text } from "@kraaft/ui";

import { styles } from "./members.styles";

export const defaultRenderItem: NonNullable<
  FlatListProps<Item>["renderItem"]
> = ({ item: { id, name, role } }) => (
  <PressableMemberItem
    key={id}
    userId={id}
    username={name ?? ""}
    details={role}
  />
);

const ItemSeparatorComponent = () => <View style={styles.separator} />;

const ListEmptyComponent = () => (
  <View style={styles.emptyState}>
    <Text size="BODY" color="FONT_LOW_EMPHASIS">
      <Trans i18nKey="noResults" />
    </Text>
  </View>
);

type Item = {
  id: string;
  name?: string;
  role?: string;
};

export type MembersProps = Omit<
  FlatListProps<Item>,
  "data" | "renderItem" | "scrollEnabled"
> & {
  items?: Item[];
  isScrollDisabled?: boolean;
  renderItem?: FlatListProps<Item>["renderItem"];
};

const Members = ({
  items,
  contentContainerStyle,
  isScrollDisabled = false,
  renderItem = defaultRenderItem,
}: MembersProps) => {
  const _contentContainerStyle = useMemo(
    () => StyleSheet.flatten([contentContainerStyle, styles.content]),
    [contentContainerStyle],
  );

  const flatListStyle = isScrollDisabled
    ? undefined
    : styles.scrollableFlatList;

  return (
    <FlatList
      data={items}
      contentContainerStyle={_contentContainerStyle}
      contentInsetAdjustmentBehavior="automatic"
      style={flatListStyle}
      renderItem={renderItem}
      maxToRenderPerBatch={100}
      scrollEnabled={!isScrollDisabled}
      ItemSeparatorComponent={ItemSeparatorComponent}
      ListEmptyComponent={ListEmptyComponent}
    />
  );
};

export { Members };
