import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { Spacing } from "@kraaft/ui";

import { SchemaLibraryTagButton } from "./schemaLibraryTagButton";
import { SchemaLibraryTagWithCounter } from "./useOrderedSchemaLibraryTagWithCounter";

type TagNavigationMenuProps = {
  schemaLibraryTags: Array<SchemaLibraryTagWithCounter>;
  setSelectedTag: (newTagId: string | undefined) => void;
  selectedTagId?: string | null;
  totalCount?: number;
};

export const TagNavigationMenu = ({
  schemaLibraryTags,
  setSelectedTag,
  selectedTagId,
  totalCount,
}: TagNavigationMenuProps) => {
  const { t } = useTranslation();

  return (
    <View style={style.menuContainer}>
      <SchemaLibraryTagButton
        key="SchemaLibraryTagButton-all"
        onPress={() => {
          setSelectedTag(undefined);
        }}
        label={t("schemaLibraryViewTags.tag.all")}
        selected={!selectedTagId}
        schemaCount={totalCount ?? 0}
      />
      {schemaLibraryTags.map((tag) => (
        <SchemaLibraryTagButton
          key={tag.id}
          data={tag.id}
          onPress={(id) => {
            setSelectedTag(id);
          }}
          label={tag.name}
          selected={selectedTagId === tag.id}
          schemaCount={tag.schemaCount}
        />
      ))}
    </View>
  );
};

const style = StyleSheet.create({
  menuContainer: {
    margin: Spacing.S24,
    rowGap: Spacing.S8,
  },
});
