import { useRef } from "react";

export function useFallbackRef<T>(
  ref: React.MutableRefObject<T> | undefined,
  fallbackValue: T,
): React.MutableRefObject<T> {
  const fallbackRef = useRef<T>(fallbackValue);

  return ref ?? fallbackRef;
}
