import { fromPairs } from "lodash";

import { compactMap } from "@kraaft/helper-functions";
import { isAtLeastPoolAdmin } from "@kraaft/shared/core/modules/pool/poolUtil";
import {
  LockingColumn,
  SchemaLockLookup,
} from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { AttachmentColumnMode } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/attachment";
import { SelectOption } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/select";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchema,
  KSchemaColumn,
  KSchemaColumnDefinition,
  KSchemaElement,
  KSchemaElementDescription,
  KSchemaIcon,
  KSchemaSection,
  SchemaElementWithPath,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { ExtractValue } from "@kraaft/shared/core/types";
import type { IconName } from "@kraaft/ui";

import { CompositeCondition } from "../modularFolder/conditions/conditionTypes";

export class KSchemaUtils {
  static get rootSectionKey() {
    return "root";
  }

  static schemaIconsMap = {
    unchecked: "check-done-01",
    flag: "flag-03",
    gallery_empty: "image-03",
    calendar: "calendar",
    forms: "file-06",
    key: "key-01",
    briefcase: "briefcase-01",
    tools: "tool-02",
    clip: "attachment-01",
    highlighter: "brush-01",
    paste: "clipboard",
    "bar-chart": "bar-chart-01",
    "message-circle-01": "message-circle-01",
  } as const satisfies Record<KSchemaIcon, IconName>;

  static *iterateColumns(element: KSchemaElement): Generator<KSchemaColumn> {
    if (element.elementType === "column") {
      yield element;
      return;
    }
    for (const innerElement of Object.values(element.elements).sort(
      KSchemaUtils.orderByIndex,
    )) {
      if (innerElement.elementType === "section") {
        yield* KSchemaUtils.iterateColumns(innerElement);
      }
      if (innerElement.elementType === "column") {
        yield innerElement;
      }
    }
  }

  static *iterateColumnsWithParentSection(
    parentSectionKey: string,
    element: KSchemaElement,
  ): Generator<[parentSectionKey: string, column: KSchemaColumn]> {
    if (element.elementType === "column") {
      yield [parentSectionKey, element];
      return;
    }
    for (const innerElement of Object.values(element.elements).sort(
      KSchemaUtils.orderByIndex,
    )) {
      if (innerElement.elementType === "section") {
        yield* KSchemaUtils.iterateColumnsWithParentSection(
          innerElement.key,
          innerElement,
        );
      }
      if (innerElement.elementType === "column") {
        yield [element.key, innerElement];
      }
    }
  }

  static mapColumns(
    section: KSchemaSection,
    iteratee: (column: KSchemaColumn) => KSchemaColumn,
  ): KSchemaSection {
    const mapped = { ...section, elements: { ...section.elements } };
    for (const innerElement of Object.values(mapped.elements).sort(
      KSchemaUtils.orderByIndex,
    )) {
      if (innerElement.elementType === "section") {
        mapped.elements[innerElement.key] = KSchemaUtils.mapColumns(
          innerElement,
          iteratee,
        );
      }
      if (innerElement.elementType === "column") {
        mapped.elements[innerElement.key] = iteratee(innerElement);
      }
    }
    return mapped;
  }

  static *iterateSections(element: KSchemaElement): Generator<KSchemaSection> {
    if (element.elementType === "column") {
      return;
    }

    for (const innerElement of Object.values(element.elements).sort(
      KSchemaUtils.orderByIndex,
    )) {
      if (innerElement.elementType === "section") {
        yield* KSchemaUtils.iterateSections(innerElement);
        yield { ...innerElement };
      }
    }
  }

  static *iterateAllSections(
    element: KSchemaSection,
  ): Generator<KSchemaSection> {
    yield element;

    yield* KSchemaUtils.iterateSections(element);
  }

  static automatedColumns = {
    [KColumnType.attachment]: false,
    [KColumnType.checkbox]: false,
    [KColumnType.currency]: false,
    [KColumnType.date]: false,
    [KColumnType.geolocation]: false,
    [KColumnType.longText]: false,
    [KColumnType.number]: false,
    [KColumnType.selectSingle]: false,
    [KColumnType.selectMultiple]: false,
    [KColumnType.shortText]: false,
    [KColumnType.signature]: false,
    [KColumnType.user]: false,
    [KColumnType.roomName]: false,
    [KColumnType.roomMembers]: false,
    [KColumnType.automatedCreatedBy]: true,
    [KColumnType.automatedCreatedAt]: true,
    [KColumnType.automatedAutoIncrement]: true,
    [KColumnType.join]: false,
  } as const satisfies Record<KColumnType, boolean>;

  static isColumnAutomated(
    column: KSchemaColumn,
  ): column is KSchemaColumn<
    ExtractValue<typeof KSchemaUtils.automatedColumns, true>
  > {
    return KSchemaUtils.automatedColumns[column.type];
  }

  static isColumnRoomProperty(
    column: KSchemaColumn,
  ): column is Extract<
    KSchemaColumn,
    { type: KColumnType.roomMembers | KColumnType.roomName }
  > {
    return [KColumnType.roomMembers, KColumnType.roomName].includes(
      column.type,
    );
  }

  static orderByIndex(a: KSchemaElement, b: KSchemaElement) {
    return a.index - b.index;
  }

  static orderedSectionColumns(section: KSchemaSection) {
    return Object.values(section.elements)
      .filter((e) => e.elementType === "column")
      .sort(KSchemaUtils.orderByIndex) as KSchemaColumn[];
  }

  static orderedSectionElements(section: KSchemaSection) {
    return Object.values(section.elements).sort(KSchemaUtils.orderByIndex);
  }

  static orderedColumns(rootSection: KSchemaSection) {
    return [...KSchemaUtils.iterateColumns(rootSection)];
  }

  static orderOptions(
    column:
      | KSchemaColumn<KColumnType.selectSingle>
      | KSchemaColumn<KColumnType.selectMultiple>,
  ) {
    return Object.entries(column.options).sort(
      ([, a], [, b]) => a.index - b.index,
    );
  }

  static flattenSection(section: KSchemaSection): KSchemaColumn[] {
    return [...KSchemaUtils.iterateColumns(section)].map((column, index) => ({
      ...column,
      index,
    }));
  }

  static flattenColumnsDict(element: KSchemaElement): {
    [columnKey: string]: KSchemaColumn;
  } {
    if (element.elementType === "column") {
      return {};
    }
    return Object.values(element.elements).reduce<{
      [columnKey: string]: KSchemaColumn;
    }>((acc, curr) => {
      if (curr.elementType === "column") {
        acc[curr.key] = curr;
      } else if (curr.elementType === "section") {
        for (const [key, value] of Object.entries(
          KSchemaUtils.flattenColumnsDict(curr),
        )) {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  }

  static flattenElementsDict(element: KSchemaElement): {
    [columnKey: string]: KSchemaElement;
  } {
    if (element.elementType === "column") {
      return {};
    }
    return Object.values(element.elements).reduce<{
      [columnKey: string]: KSchemaElement;
    }>((acc, curr) => {
      acc[curr.key] = curr;
      if (curr.elementType === "section") {
        for (const [key, value] of Object.entries(
          KSchemaUtils.flattenColumnsDict(curr),
        )) {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  }

  static flattenColumnsWithPath(
    element: KSchemaElement,
    path: KSchemaSection[] = [],
  ): {
    [columnKey: string]: {
      path: KSchemaSection[];
      column: KSchemaColumn;
    };
  } {
    if (element.elementType === "column") {
      return { [element.key]: { path, column: element } };
    }
    return Object.values(element.elements).reduce((acc, curr) => {
      Object.assign(
        acc,
        KSchemaUtils.flattenColumnsWithPath(curr, [...path, element]),
      );
      return acc;
    }, {});
  }

  static flattenSectionWithPath(
    baseSection: KSchemaSection,
  ): Record<string, SchemaElementWithPath> {
    function crawl(
      section: KSchemaSection,
      path: KSchemaSection[] = [],
      absoluteIndex = 0,
    ): Record<string, SchemaElementWithPath> {
      let newAbsoluteIndex = absoluteIndex;
      let result: Record<string, SchemaElementWithPath> = {};

      for (const innerElement of Object.values(section.elements).sort(
        KSchemaUtils.orderByIndex,
      )) {
        if (innerElement.elementType === "section") {
          result = {
            ...result,
            [innerElement.key]: {
              path,
              element: innerElement,
            },
            ...crawl(innerElement, [...path, innerElement], newAbsoluteIndex),
          };
        }
        if (innerElement.elementType === "column") {
          result[innerElement.key] = {
            absoluteIndex: newAbsoluteIndex++,
            path,
            element: innerElement,
          };
        }
      }
      return result;
    }

    return crawl(baseSection);
  }

  static getAllSections(element: KSchemaElement): {
    [columnKey: string]: KSchemaSection;
  } {
    if (element.elementType === "column") {
      return {};
    }
    return Object.values(element.elements).reduce<{
      [columnKey: string]: KSchemaSection;
    }>((acc, curr) => {
      if (curr.elementType === "section") {
        acc[curr.key] = curr;
        for (const [key, value] of Object.entries(
          KSchemaUtils.getAllSections(curr),
        )) {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  }

  // This will handle maximum 10 nested section
  static getDisplayOrderFromPathAndColmun(
    path: KSchemaSection[],
    column: KSchemaColumn,
  ) {
    const base = 10;
    return (
      path.reduce((acc, curr, index) => {
        return acc + curr.index * ((base - index) * 1000);
      }, 0) + column.index
    );
  }

  static getStringPath(
    path: KSchemaSection[],
    element: KSchemaElement,
    skipRootSection = true,
  ) {
    // We skip adding root section to path
    return `${[
      ...path.slice(skipRootSection ? 1 : 0).map((p) => p.name),
      element.name,
    ].join(" → ")}`;
  }

  static getParentSectionKey(rootSection: KSchemaSection, key: string) {
    const context = KSchemaUtils.findWithContext(rootSection, key);
    if (!context) {
      return undefined;
    }
    return context.parentSection.key;
  }

  static findWithContext(
    parentSection: KSchemaSection,
    key: string,
    parentPath: KSchemaSection[] = [],
  ):
    | {
        element: KSchemaElement;
        parentSection: KSchemaSection;
        path: KSchemaSection[];
      }
    | undefined {
    const path = [...parentPath, parentSection];
    for (const innerElement of Object.values(parentSection.elements)) {
      if (innerElement.key === key) {
        return { parentSection, element: innerElement, path };
      }
      if (innerElement.elementType === "section") {
        const foundNested = KSchemaUtils.findWithContext(
          innerElement,
          key,
          path,
        );
        if (foundNested) {
          return foundNested;
        }
      }
    }

    return undefined;
  }

  static findColumnWithContext(
    element: KSchemaSection,
    key: string,
  ):
    | {
        element: KSchemaColumn;
        parentSection: KSchemaSection;
        path: KSchemaSection[];
      }
    | undefined {
    const foundElement = KSchemaUtils.findWithContext(element, key);
    if (foundElement?.element.elementType === "column") {
      return {
        element: foundElement.element,
        parentSection: foundElement.parentSection,
        path: foundElement.path,
      };
    }
    return undefined;
  }

  static findSectionWithContext(
    element: KSchemaSection,
    key: string,
  ): { parentSection: KSchemaSection; element: KSchemaSection } | undefined {
    const foundElement = KSchemaUtils.findWithContext(element, key);
    if (foundElement?.element.elementType === "section") {
      return {
        parentSection: foundElement.parentSection,
        element: foundElement.element,
      };
    }
    return undefined;
  }

  static findElement(
    element: KSchemaElement,
    key: string,
  ): KSchemaElement | undefined {
    if (element.key === key) {
      return element;
    }
    if (element.elementType === "section") {
      for (const innerElement of Object.values(element.elements)) {
        if (innerElement.key === key) {
          return innerElement;
        }
        if (innerElement.elementType === "section") {
          const foundNested = KSchemaUtils.findElement(innerElement, key);
          if (foundNested) {
            return foundNested;
          }
        }
      }
    }
    return undefined;
  }

  static findColumn(
    element: KSchemaSection,
    key: string,
  ): KSchemaColumn | undefined;
  static findColumn<C extends KColumnType>(
    element: KSchemaSection,
    key: string,
    columnType: C,
  ): KSchemaColumn<C> | undefined;
  static findColumn<C extends KColumnType>(
    element: KSchemaSection,
    key: string,
    columnType: C[],
  ): KSchemaColumn<C> | undefined;
  static findColumn(
    element: KSchemaSection,
    key: string,
    columnType?: KColumnType | KColumnType[],
  ): KSchemaColumn | undefined {
    const found = KSchemaUtils.findColumnWithContext(element, key);
    if (
      found &&
      (!columnType ||
        (Array.isArray(columnType)
          ? columnType.includes(found.element.type)
          : columnType === found.element.type))
    ) {
      return found.element;
    }
    return undefined;
  }

  static deleteColumn(element: KSchemaElement, key: string) {
    if (element.elementType === "column") {
      return false;
    }
    if (element.elementType === "section") {
      for (const [innerKey, innerElement] of Object.entries(element.elements)) {
        if (innerElement.key === key) {
          delete element.elements[innerKey];
          return true;
        }
        if (innerElement.elementType === "section") {
          if (KSchemaUtils.deleteColumn(innerElement, key)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  static hasColumnOfType<T extends KColumnType>(
    schema: KSchema,
    columnType: T,
  ): boolean {
    for (const column of KSchemaUtils.iterateColumns(schema.rootSection)) {
      if (column.type === columnType) {
        return true;
      }
    }
    return false;
  }

  static isColumnOfType<T extends KColumnType>(...columnTypes: T[]) {
    return (
      column: KSchemaColumn,
    ): column is T extends KColumnType ? KSchemaColumn<T> : never =>
      (columnTypes as KColumnType[]).includes(column.type);
  }

  static getColumnByKey(
    rootSection: KSchemaSection,
    key: string,
  ): KSchemaColumn | undefined {
    return [...KSchemaUtils.iterateColumns(rootSection)].find(
      (col) => col.key === key,
    );
  }

  static getColumnsOfType<C extends KColumnType>(
    schema: KSchema,
    type: C,
  ): KSchemaColumn<C>[] {
    return [...KSchemaUtils.iterateColumns(schema.rootSection)].filter(
      this.isColumnOfType(type),
    );
  }

  static getFirstColumnOfType<C extends KColumnType>(
    schema: KSchema,
    ...types: C[]
  ) {
    return [...KSchemaUtils.iterateColumns(schema.rootSection)].find(
      this.isColumnOfType(...types),
    );
  }

  static getHiglightedCheckboxColumn(
    rootSection: KSchemaSection,
    highlightedColumnKey: string,
  ) {
    const column = this.getColumnByKey(rootSection, highlightedColumnKey);
    if (column?.type === KColumnType.checkbox) {
      return column;
    }
    return undefined;
  }

  static getAttachmentColumnOfMode(
    schema: KSchema,
    mode: AttachmentColumnMode,
  ) {
    for (const column of KSchemaUtils.iterateColumns(schema.rootSection)) {
      if (column.type === KColumnType.attachment && column.mode === mode) {
        return column;
      }
    }
    return undefined;
  }

  static deepFilterElements(
    element: KSchemaSection,
    filter: (element: KSchemaElement) => boolean,
  ): KSchemaSection | undefined {
    if (!filter(element)) {
      return element;
    }
    const thisElements = fromPairs(
      compactMap(Object.entries(element.elements), ([key, e]) => {
        if (e.elementType === "section") {
          const nestedSection = KSchemaUtils.deepFilterElements(e, filter);
          if (!nestedSection) {
            return undefined;
          }
          return [key, nestedSection];
        }
        if (e.elementType === "column") {
          return filter(e) ? [key, e] : undefined;
        }
        return undefined;
      }),
    );
    return {
      ...element,
      elements: thisElements,
    };
  }

  static withoutSpecificColumns<S extends KSchema>(
    schema: S,
    columnChecker: (column: KSchemaColumn) => boolean,
  ): S {
    return {
      ...schema,
      rootSection: KSchemaUtils.deepFilterElements(
        schema.rootSection,
        (element) => {
          if (element.elementType === "section") {
            return true;
          }
          return !columnChecker(element);
        },
      ),
    };
  }

  static withSpecificColumns<S extends KSchema>(
    schema: S,
    columnChecker: (column: KSchemaColumn) => boolean,
  ): S {
    return {
      ...schema,
      rootSection: KSchemaUtils.deepFilterElements(
        schema.rootSection,
        (element) => {
          if (element.elementType === "section") {
            return true;
          }
          return columnChecker(element);
        },
      ),
    };
  }

  static withoutColumnsOfType<S extends KSchema>(
    schema: S,
    columnTypes: KColumnType[],
  ) {
    return {
      ...schema,
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      rootSection: KSchemaUtils.deepFilterElements(
        schema.rootSection,
        (element) => {
          if (element.elementType === "section") {
            return true;
          }
          return !columnTypes.includes(element.type);
        },
      )!,
    };
  }

  /** Prefer using KSchemaUtils.getIcon when possible */
  // eslint-disable-next-line complexity
  static getIconFromColumnType(type: KColumnType): IconName {
    switch (type) {
      case KColumnType.number:
        return "hash-01";
      case KColumnType.currency:
        return "currency-euro";
      case KColumnType.selectSingle:
        return "triangle-down";
      case KColumnType.selectMultiple:
        return "list";
      case KColumnType.date:
        return "calendar";
      case KColumnType.automatedCreatedAt:
        return "calendar-automatic";
      case KColumnType.user:
        return "user-01";
      case KColumnType.automatedCreatedBy:
        return "user-automatic";
      case KColumnType.checkbox:
        return "check";
      case KColumnType.geolocation:
        return "map-04";
      case KColumnType.attachment:
        return "image-03";
      case KColumnType.signature:
        return "edit-04";
      case KColumnType.roomName:
        return "align-left";
      case KColumnType.join:
        return "none";
      case KColumnType.automatedAutoIncrement:
        return "align-left";
      default:
        return "type-01";
    }
  }

  static getIcon(column: KSchemaColumn): IconName {
    switch (column.type) {
      case KColumnType.attachment:
        return column.mode === "gallery" ? "image-03" : "file-04";
      case KColumnType.user:
        return column.allowMultiple ? "users-01" : "user-01";
      default:
        return KSchemaUtils.getIconFromColumnType(column.type);
    }
  }

  static isMultiSelect(
    column:
      | KSchemaColumn<KColumnType.user>
      | KSchemaColumn<KColumnType.selectSingle>
      | KSchemaColumn<KColumnType.selectMultiple>,
  ) {
    if (column.type === KColumnType.user && column.allowMultiple) {
      return true;
    }
    return column.type === KColumnType.selectMultiple;
  }

  static getSectionLength(section: KSchemaSection) {
    return Object.keys(section).length;
  }

  static getVisibleColumnCount(section: KSchemaSection) {
    return [...this.iterateColumns(section)].filter(
      (col) =>
        col.type !== KColumnType.roomName &&
        col.type !== KColumnType.roomMembers,
    ).length;
  }

  static getColumnByIndex(section: KSchemaSection, index: number) {
    return Object.values(section.elements).find((e) => e.index === index);
  }

  static getSchemaIconName(schemaIcon: KSchemaIcon) {
    return KSchemaUtils.schemaIconsMap[schemaIcon];
  }

  static isReadOnlyColumn(column: KSchemaColumn) {
    if (KSchemaUtils.isColumnAutomated(column)) {
      return true;
    }

    return column.type === KColumnType.roomName;
  }

  static getLockingColumnsForSection(section: KSchemaSection): LockingColumn[] {
    const lockingColumns: LockingColumn[] = [];

    for (const element of Object.values(section.elements)) {
      if (
        element.elementType === "column" &&
        element.type === "checkbox" &&
        element.isLockingSection
      ) {
        lockingColumns.push({
          columnKey: element.key,
          parentSection: {
            key: section.key,
            name: section.name,
          },
        });
      }
    }

    return lockingColumns;
  }

  static getLockingColumnsForSchema(schema: KSchema) {
    const lockingColumns: string[] = [];
    for (const element of this.iterateColumns(schema.rootSection)) {
      if (element.type === "checkbox" && element.isLockingSection) {
        lockingColumns.push(element.key);
      }
    }

    return lockingColumns;
  }

  static computeSchemaLockLookup(
    rootSection: KSchemaSection,
    currentUserRole?: UserPoolRole,
  ) {
    const isCurrentUserAuthorizedToUnlock = isAtLeastPoolAdmin(currentUserRole);

    const rootLockingColumns =
      KSchemaUtils.getLockingColumnsForSection(rootSection);

    const schemaLockInfo: SchemaLockLookup = {
      elementSectionKey: {},
      sections: {
        [rootSection.key]: {
          section: {
            key: rootSection.key,
            name: rootSection.name,
          },
          lockingColumns: rootLockingColumns,
        },
      },
      isCurrentUserAuthorizedToUnlock,
    };

    function crawl(
      sectionElement: KSchemaSection,
      parentLockingColumns: LockingColumn[] = [],
    ) {
      for (const element of Object.values(sectionElement.elements)) {
        schemaLockInfo.elementSectionKey[element.key] = sectionElement.key;

        if (element.elementType !== "section") {
          continue;
        }

        const lockingColumnsForSection = [
          ...parentLockingColumns,
          ...KSchemaUtils.getLockingColumnsForSection(element),
        ];

        schemaLockInfo.sections[element.key] = {
          lockingColumns: lockingColumnsForSection,
          section: {
            key: element.key,
            name: element.name,
          },
        };

        crawl(element, lockingColumnsForSection);
      }
    }

    crawl(rootSection, rootLockingColumns);
    return schemaLockInfo;
  }

  static isDescriptionEmpty(
    description: KSchemaElementDescription | undefined,
  ) {
    if (description === undefined) {
      return true;
    }
    return (
      description.text.length === 0 &&
      !description.image &&
      !description.document
    );
  }

  static hasConditions(
    condition: CompositeCondition | undefined,
  ): condition is CompositeCondition {
    if (!condition) {
      return false;
    }
    if (condition.conditions.length === 0) {
      return false;
    }
    for (const innerCondition of condition.conditions) {
      if (innerCondition.type !== "composite") {
        return true;
      }
      if (KSchemaUtils.hasConditions(innerCondition)) {
        return true;
      }
    }
    return false;
  }
}

export function getColorForSelectColumnOption(
  column:
    | KSchemaColumn<KColumnType.selectSingle>
    | KSchemaColumn<KColumnType.selectMultiple>,
  option: SelectOption,
) {
  return column.allowColor ? option.color : undefined;
}

export const getSelectColumnOption = (
  column:
    | KSchemaColumn<KColumnType.selectSingle>
    | KSchemaColumn<KColumnType.selectMultiple>,
  optionId: string,
) => column.options[optionId];

export function getSchemaTitleColumn(schema: KSchema) {
  return KSchemaUtils.findColumn(schema.rootSection, "title") as
    | KSchemaColumn<KColumnType.shortText>
    | KSchemaColumn<KColumnType.automatedAutoIncrement>;
}

export function ensureSchemaColumnsIndex(columns: KSchemaElement[]) {
  columns.forEach((c, index) => {
    c.index = index;
  });
}

export function getIndexFromPlacement(schema: KSchema, placement: HoverState) {
  const column = KSchemaUtils.findElement(schema.rootSection, placement.key);

  if (column) {
    if (placement.placement === "before") {
      return column.index;
    }
    return column.index + 1;
  }
  return 0;
}

export function addElementAfterColumnWithKey(
  schema: KSchema,
  newElement: KSchemaElement,
  placement: HoverState,
  inSection: string | undefined,
) {
  const targetContext = KSchemaUtils.findWithContext(
    schema.rootSection,
    placement.key,
  );

  if (!targetContext) {
    return;
  }

  const finalSection =
    (inSection &&
      KSchemaUtils.findSectionWithContext(schema.rootSection, inSection)
        ?.element) ||
    targetContext.parentSection;

  // const added = reordered.element.index > targetContext.element.index ? 1 : 0;
  const elements = Object.values(finalSection.elements).sort(
    KSchemaUtils.orderByIndex,
  );
  const index = getIndexFromPlacement(schema, placement);
  const wantedOrder = [
    ...elements.slice(0, index),
    newElement,
    ...elements.slice(index),
  ];
  ensureSchemaColumnsIndex(wantedOrder);
  finalSection.elements[newElement.key] = newElement;
}

export function legacyReorderElement(
  schema: KSchema,
  reorderedKey: string,
  placement: HoverState,
  tryToMoveInsideElement: boolean,
) {
  if (reorderedKey === placement.key) {
    return;
  }
  const reordered = KSchemaUtils.findWithContext(
    schema.rootSection,
    reorderedKey,
  );
  const targetContext = KSchemaUtils.findWithContext(
    schema.rootSection,
    placement.key,
  );

  if (!reordered || !targetContext) {
    return;
  }

  delete reordered.parentSection.elements[reordered.element.key];
  let wantedOrder: KSchemaElement[];
  let finalSection = targetContext.parentSection;

  if (
    tryToMoveInsideElement &&
    targetContext.element.elementType === "section"
  ) {
    finalSection = targetContext.element;
  }

  const added =
    reordered.parentSection.key !== targetContext.parentSection.key ||
    reordered.element.index > targetContext.element.index
      ? 1
      : 0;
  const elements = Object.values(finalSection.elements).sort(
    KSchemaUtils.orderByIndex,
  );
  if (placement.placement === "before") {
    wantedOrder = [
      ...elements.slice(0, targetContext.element.index - 1 + added),
      reordered.element,
      ...elements.slice(targetContext.element.index - 1 + added),
    ];
  } else {
    wantedOrder = [
      ...elements.slice(0, targetContext.element.index + added),
      reordered.element,
      ...elements.slice(targetContext.element.index + added),
    ];
  }
  ensureSchemaColumnsIndex(wantedOrder);
  finalSection.elements[reordered.element.key] = reordered.element;
}

export function reorderElement(
  schema: KSchema,
  payload: {
    targetKey: string;
    afterKey?: string;
    sectionKey: string;
  },
) {
  const targetContext = KSchemaUtils.findWithContext(
    schema.rootSection,
    payload.targetKey,
  );

  if (!targetContext) {
    return;
  }

  const section =
    payload.sectionKey === KSchemaUtils.rootSectionKey
      ? schema.rootSection
      : KSchemaUtils.findElement(schema.rootSection, payload.sectionKey);

  if (section?.elementType !== "section") {
    return;
  }

  delete targetContext.parentSection.elements[targetContext.element.key];

  const orderedElements = KSchemaUtils.orderedSectionElements(section);

  let wantedOrder: KSchemaElement[];

  const afterIndex = payload.afterKey
    ? orderedElements.findIndex((el) => el.key === payload.afterKey) + 1
    : undefined;

  if (afterIndex) {
    wantedOrder = [
      ...orderedElements.slice(0, afterIndex),
      targetContext.element,
      ...orderedElements.slice(afterIndex),
    ];
  } else {
    wantedOrder = [targetContext.element, ...orderedElements];
  }

  ensureSchemaColumnsIndex(wantedOrder);
  section.elements[targetContext.element.key] = targetContext.element;
}

export function getColumnDefinitionFromAutonumberingMode(
  autoNumberingMode: AutoNumberingMode,
): KSchemaColumnDefinition {
  return autoNumberingMode.mode === "autonumber"
    ? {
        type: KColumnType.automatedAutoIncrement,
        prefix: autoNumberingMode.prefix,
      }
    : {
        type: KColumnType.shortText,
      };
}
