/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import { History } from "history";
import { isNil, omitBy } from "lodash/fp";
import * as qs from "qs";

import { NAVIGATION_VERBOSE } from "@kraaft/shared/constants/global";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { FirebaseTypes } from "@kraaft/shared/core/services/firebase";
import {
  ScreenKey,
  ScreenKeyWithoutParams,
  ScreenParams,
  ScreenParamsUndefined,
} from "@kraaft/shared/core/services/navigation/navigationParams";
import { AnyUnexplained, GeoCoordinates } from "@kraaft/shared/core/types";
import { toCoordinatesString } from "@kraaft/web/src/components/geolocation/mapUtils";
import { LibrarySchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderTabs";
import { PoolSchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderTabs";
import { FormatType } from "@kraaft/web/src/components/visualisationSelector/visualisationSelector.types";
import { getCurrentRoute } from "@kraaft/web/src/core/services/navigation/getCurrentRoute";
import { RouteService } from "@kraaft/web/src/core/services/navigation/routeService";
import { AllNavigationOptions } from "@kraaft/web/src/core/services/navigation/types";
import {
  detailsQuery,
  geoDetailQuery,
  geoQuery,
  queries,
  routes,
  viewModeQuery,
} from "@kraaft/web/src/views/app/appRouter/routes";
import { templateRoute } from "@kraaft/web/src/views/app/appRouter/routeUtils";

type ConversationDetailsScreenKeys =
  | "ConversationInfo"
  | "ConversationFolder"
  | "ModularFolders"
  | "ModularFolderDetails";

type ConversationDetailsScreenParams =
  ScreenParamsUndefined<ConversationDetailsScreenKeys>;

type ViewModeParams = {
  [viewModeQuery.conversation]: undefined;
  [viewModeQuery.geolocation]: {
    [geoQuery.initialCenter]?: string;
  };
};

export class GeneratedRoute {
  constructor(
    private readonly pathname: string,
    private readonly search: () => string,
  ) {}

  public get route() {
    return `${this.pathname}?${this.search()}`;
  }
}

class NavigationService {
  private poolsInformation:
    | {
        currentPoolId: string | undefined;
        pools: Pool[];
      }
    | undefined;

  constructor(private readonly navigation: History) {}

  isReady() {
    return true;
  }

  setPoolsInformation(poolsInformation: {
    currentPoolId: string | undefined;
    pools: Pool[];
  }) {
    this.poolsInformation = poolsInformation;
    const newSearch = this.getPoolSearch();
    if (Object.keys(newSearch).length > 0) {
      const newSearchString = qs.stringify({
        ...qs.parse(this.navigation.location.search.slice(1)),
        ...newSearch,
      });
      if (newSearchString !== this.navigation.location.search.slice(1)) {
        this.navigation.replace({
          ...this.navigation.location,
          search: newSearchString,
        });
      }
    }
  }

  /** @todo move somewhere else */
  static confirmation: FirebaseTypes.ConfirmationResult | null = null;
  setConfirmation(confirmation: FirebaseTypes.ConfirmationResult) {
    NavigationService.confirmation = confirmation;
  }

  getConfirmation() {
    return NavigationService.confirmation;
  }

  private getOptions<Options extends AllNavigationOptions>(
    routeNameOrOptions: ScreenKey | Options,
    params: Options["params"],
  ) {
    let newOptions = {} as Options;
    if (typeof routeNameOrOptions === "string") {
      newOptions.routeName = routeNameOrOptions;
      newOptions.params = params;
    } else {
      newOptions = routeNameOrOptions;
    }

    return newOptions;
  }

  private isDetailsScreenOpened() {
    const route = getCurrentRoute(this.navigation);
    return route === "ConversationInfo" || route === "ConversationFolder";
  }

  getResetQuery(
    currentDetailScreen?: detailsQuery | null,
  ): Record<string, string> {
    switch (currentDetailScreen) {
      case detailsQuery.location:
      case detailsQuery.modularFolders:
        return {
          [queries.detailScreen]: currentDetailScreen,
        };

      case detailsQuery.modularFolder:
      case detailsQuery.modularFolderHistory:
        return {
          [queries.detailScreen]: detailsQuery.modularFolders,
          [queries.folderId]: "",
        };
      case detailsQuery.photoGallery:
        return {
          [queries.detailScreen]: detailsQuery.photoGallery,
          [queries.folderId]: "",
          [queries.schemaId]: "",
        };

      default:
        return {
          [queries.detailScreen]: "",
          [queries.folderId]: "",
          [queries.schemaId]: "",
          [queries.previousDetailScreen]: "",
          [queries.tab]: "",
          [queries.workflowId]: "",
          [queries.tagId]: "",
        };
    }
  }

  private setViewModeQuery<T extends viewModeQuery>(
    viewMode?: T | null,
    ...[params]: ViewModeParams[T] extends undefined ? [] : [ViewModeParams[T]]
  ): Record<string, string> {
    return {
      ...(viewMode ? { [queries.viewMode]: viewMode } : {}),
      ...(!params?.[geoQuery.initialCenter]
        ? { [geoQuery.initialCenter]: "" }
        : {}),
      ...(omitBy(isNil, params) || {}),
    };
  }

  private getRoomSearchParams() {
    const searchParams = new URLSearchParams(this.navigation.location.search);
    const detailScreen = searchParams.get(
      queries.detailScreen,
    ) as detailsQuery | null;

    return new URLSearchParams(this.getResetQuery(detailScreen));
  }

  private navigateToModularFoldersTable(
    params: ScreenParamsUndefined<"ModularFoldersTable">,
  ) {
    const searchParams = new URLSearchParams(this.navigation.location.search);

    return this.navigateAndPreserveSearch({
      pathname: templateRoute(routes.ModularFoldersTable, {
        schemaId: params.schemaId,
      }),
      search: new URLSearchParams({
        ...this.getResetQuery(),
        [queries.format]: searchParams.get(queries.format) ?? FormatType.TABLE,
      }),
    });
  }

  private navigateToModularFoldersTableForRoom(
    params: ScreenParamsUndefined<"ModularFoldersTableForRoom">,
  ) {
    const searchParams = new URLSearchParams(this.navigation.location.search);

    return this.navigateAndPreserveSearch({
      pathname: templateRoute(routes.ModularFoldersTableForRoom, {
        schemaId: params.schemaId,
        roomId: params.roomId,
      }),
      search: new URLSearchParams({
        ...this.getResetQuery(),
        [queries.format]: searchParams.get(queries.format) ?? FormatType.TABLE,
      }),
    });
  }

  private navigateToRoomSchemaBuilder(
    params: ScreenParamsUndefined<"RoomSchemaBuilder">,
  ) {
    const searchParams = new URLSearchParams(this.navigation.location.search);

    return this.navigateAndPreserveSearch({
      pathname: routes.RoomSchemaBuilder,
      search: new URLSearchParams({
        ...this.getResetQuery(),
        [queries.tab]:
          params?.tab ??
          searchParams.get(queries.tab) ??
          PoolSchemaBuilderTabs.schema,
      }),
    });
  }

  private navigateToFolderSchemaBuilder(
    params: ScreenParamsUndefined<"FolderSchemaBuilder">,
  ) {
    const searchParams = new URLSearchParams(this.navigation.location.search);

    return this.navigateAndPreserveSearch({
      pathname: templateRoute(routes.FolderSchemaBuilder, {
        schemaId: params.schemaId,
      }),
      search: new URLSearchParams({
        ...this.getResetQuery(),
        [queries.tab]:
          params.tab ??
          searchParams.get(queries.tab) ??
          PoolSchemaBuilderTabs.schema,
      }),
    });
  }

  private navigateToLibrarySchemaBuilder(
    params: ScreenParamsUndefined<"LibrarySchemaBuilder">,
  ) {
    const searchParams = new URLSearchParams(this.navigation.location.search);

    return this.navigateAndPreserveSearch({
      pathname: templateRoute(routes.LibrarySchemaBuilder, {
        librarySchemaId: params.librarySchemaId,
      }),
      search: new URLSearchParams({
        ...this.getResetQuery(),
        [queries.tab]:
          params.tab ??
          searchParams.get(queries.tab) ??
          LibrarySchemaBuilderTabs.schema,
      }),
    });
  }

  private navigateToSchemaView(params: ScreenParamsUndefined<"SchemaView">) {
    const searchParams = new URLSearchParams(this.navigation.location.search);

    return this.navigateAndPreserveSearch({
      pathname: templateRoute(routes.SchemaView, {
        schemaViewId: params.schemaViewId,
      }),
      search: new URLSearchParams({
        ...this.getResetQuery(),
        [queries.format]: searchParams.get(queries.format) ?? FormatType.TABLE,
      }),
    });
  }

  private navigateToConversationTable() {
    const searchParams = new URLSearchParams(this.navigation.location.search);

    return this.navigateAndPreserveSearch({
      pathname: routes.ConversationTable,
      search: new URLSearchParams({
        ...this.getResetQuery(),
        [queries.format]: searchParams.get(queries.format) ?? FormatType.TABLE,
      }),
    });
  }

  private navigateToLibrarySchemaView(
    params: ScreenParamsUndefined<"SchemaLibraryView">,
  ) {
    return this.navigateAndPreserveSearch({
      pathname: routes.SchemaLibraryView,
      search: new URLSearchParams({
        ...this.getResetQuery(),
        // why is this not automatically forwarded ?
        ...(params?.tagId ? { [queries.tagId]: params.tagId } : {}),
        [queries.tab]: params?.tab ?? "public",
      }),
    });
  }

  private navigateToLibrarySchemaShowcase(
    params: ScreenParamsUndefined<"LibrarySchemaShowcase">,
  ) {
    return this.navigateAndPreserveSearch({
      pathname: templateRoute(routes.LibrarySchemaShowcase, {
        librarySchemaId: params.librarySchemaId,
      }),
      search: new URLSearchParams({
        ...this.getResetQuery(),
      }),
    });
  }

  getPoolSearch(): Record<string, string> {
    if (
      this.poolsInformation &&
      this.poolsInformation.pools.length > 1 &&
      this.poolsInformation.currentPoolId
    ) {
      return { [queries.poolId]: this.poolsInformation.currentPoolId };
    }
    return {};
  }

  generateRoute(route: string) {
    return new GeneratedRoute(route, () => qs.stringify(this.getPoolSearch()));
  }

  navigateAndPreserveSearch(options: {
    pathname: string;
    state?: History.LocationState;
    search?: URLSearchParams;
  }) {
    const { pathname, state, search } = options;

    const newSearch = new URLSearchParams(this.navigation.location.search);

    if (search) {
      search.forEach((value, key) => {
        if (value !== "") {
          newSearch.set(key, value);
        } else {
          newSearch.delete(key);
        }
      });
    }

    for (const [key, value] of Object.entries(this.getPoolSearch())) {
      newSearch.set(key, value);
    }

    this.navigation.push({ pathname, state, search: newSearch.toString() });
  }

  navigateToPathname(pathname: string, query?: Record<string, string>) {
    this.navigation.push({
      pathname: pathname,
      search: qs.stringify({ ...this.getPoolSearch(), ...query }),
    });
  }

  navigate<NavigateRouteName extends ScreenKeyWithoutParams>(
    routeName: NavigateRouteName,
  ): void;
  navigate<NavigateRouteName extends ScreenKey>(
    routeName: NavigateRouteName,
    params: AllNavigationOptions<NavigateRouteName>["params"],
  ): void;
  navigate<NavigateRouteName extends ScreenKey>(
    routeName: NavigateRouteName,
    params?: AllNavigationOptions<NavigateRouteName>["params"],
  ): void {
    const newOptions = this.getOptions<AllNavigationOptions<NavigateRouteName>>(
      routeName,
      params,
    );

    if (NAVIGATION_VERBOSE) {
      console.log(
        `NavigationService.navigate(${newOptions.routeName}, ${JSON.stringify(
          newOptions.params,
        )})`,
      );
    }

    switch (newOptions.routeName) {
      case "Home":
        this.navigateToHome();
        break;
      case "Settings":
        this.navigateToSettings("/");
        break;
      case "ManageChecklist":
        this.navigateToSettings(routes.ManageChecklist);
        break;
      case "ManageMembers":
        this.navigateToSettings(routes.ManageMembers);
        break;
      case "ManagePool":
        this.navigateToSettings(routes.ManagePool);
        break;
      case "ManageTaskTemplates":
        this.navigateToSettings(routes.ManageTaskTemplates);
        break;
      case "ManageTemplates":
        this.navigateToSettings(routes.ManageTemplates);
        break;
      case "ConversationMessenger":
      case "Conversation":
        this.navigateToConversation(
          newOptions.params as ScreenParamsUndefined<"Conversation">,
        );
        break;
      case "ConversationInfo": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"ConversationInfo">;

        this.openConversationDrawer(routes.ConversationInfo, screenParams, {
          [queries.detailScreen]: screenParams.openedPanel ?? "",
        });
        break;
      }
      case "ConversationFolder": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"ConversationFolder">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.geoDetail]: geoDetailQuery.room,
          [queries.detailScreen]: screenParams.openedPanel ?? "",
        });
        break;
      }
      case "RoomDirectory": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"RoomDirectory">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.detailScreen]: detailsQuery.directory,
          [queries.directoryId]: screenParams.directoryId,
        });
        break;
      }
      case "ModularFolderDetails": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"ModularFolderDetails">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.detailScreen]: detailsQuery.modularFolder,
          [queries.folderId]: screenParams.folderId,
          [queries.schemaId]: screenParams.schemaId,
          [queries.geoDetail]: geoDetailQuery.modularFolder,
        });
        break;
      }
      case "ModularFolderHistory": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"ModularFolderHistory">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.detailScreen]: detailsQuery.modularFolderHistory,
          [queries.folderId]: screenParams.folderId,
          [queries.schemaId]: screenParams.schemaId,
        });
        break;
      }
      case "ModularFolders": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"ModularFolders">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.detailScreen]: detailsQuery.modularFolders,
          [queries.schemaId]: screenParams.schemaId,
          [queries.geoDetail]: geoDetailQuery.room,
        });
        break;
      }
      case "ModularFoldersTable": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"ModularFoldersTable">;

        this.navigateToModularFoldersTable(screenParams);
        break;
      }
      case "ModularFoldersTableForRoom": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"ModularFoldersTableForRoom">;

        this.navigateToModularFoldersTableForRoom(screenParams);
        break;
      }
      case "ConversationTable":
        this.navigateToConversationTable();
        break;
      case "RoomSchemaBuilder": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"RoomSchemaBuilder">;

        this.navigateToRoomSchemaBuilder(screenParams);
        break;
      }
      case "FolderSchemaBuilder": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"FolderSchemaBuilder">;

        this.navigateToFolderSchemaBuilder(screenParams);
        break;
      }
      case "LibrarySchemaBuilder": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"LibrarySchemaBuilder">;

        this.navigateToLibrarySchemaBuilder(screenParams);
        break;
      }
      case "SchemaLibraryList":
        this.navigateToSettings(routes.SchemaLibraryList);
        break;
      case "SchemaView": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"SchemaView">;

        this.navigateToSchemaView(screenParams);
        break;
      }
      case "SchemaLibraryView": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"SchemaLibraryView">;
        this.navigateToLibrarySchemaView(screenParams);
        break;
      }
      case "LibrarySchemaShowcase": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"LibrarySchemaShowcase">;

        this.navigateToLibrarySchemaShowcase(screenParams);
        break;
      }
      case "EditMembers": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"EditMembers">;

        this.openConversationDrawer(routes.ConversationInfo, screenParams, {
          [queries.detailScreen]: detailsQuery.editMembers,
        });
        break;
      }
      case "Members": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"Members">;

        this.openConversationDrawer(routes.ConversationInfo, screenParams, {
          [queries.detailScreen]: detailsQuery.members,
        });
        break;
      }
      case "RoomSharepointConnector": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"RoomSharepointConnector">;
        this.openConversationDrawer(routes.ConversationInfo, screenParams, {
          [queries.detailScreen]: detailsQuery.sharepointConnector,
        });
        break;
      }
      case "PhotoGallery": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"PhotoGallery">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.detailScreen]: detailsQuery.photoGallery,
          [queries.geoDetail]: geoDetailQuery.room,
        });
        break;
      }
      case "DocumentGallery": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"DocumentGallery">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.detailScreen]: detailsQuery.documentGallery,
          [queries.geoDetail]: geoDetailQuery.room,
        });
        break;
      }
      case "EditSchemaVisibilityForRoom": {
        const screenParams =
          newOptions.params as ScreenParamsUndefined<"EditSchemaVisibilityForRoom">;

        this.openConversationDrawer(routes.ConversationFolder, screenParams, {
          [queries.detailScreen]: detailsQuery.roomSchemaVisibility,
        });
        break;
      }
      case "AddMembersAtPoolCreation": {
        this.navigateAndPreserveSearch({
          pathname: routes.OnboardingInviteToPool,
          search: new URLSearchParams({
            ...this.getResetQuery(),
          }),
          state: newOptions.params,
        });
        break;
      }
      case "LoaderCreatingPool": {
        this.navigateAndPreserveSearch({
          pathname: routes.OnboardingPoolCreationLoader,
          search: new URLSearchParams({
            ...this.getResetQuery(),
          }),
        });
        break;
      }
      case "ManageWorkflows": {
        const workflowId = (params as ScreenParamsUndefined<"ManageWorkflows">)
          ?.workflowId;
        this.navigateAndPreserveSearch({
          pathname: routes.ManageWorkflows,
          search: new URLSearchParams({
            ...this.getResetQuery(),
            ...(workflowId ? { [queries.workflowId]: workflowId } : {}),
          }),
        });
        break;
      }
      default: {
        const path = routes[newOptions.routeName as keyof typeof routes];

        if (path !== undefined) {
          this.navigation.push(path, newOptions.params);
        }
        break;
      }
    }
  }

  replace<ReplaceRouteName extends ScreenKeyWithoutParams>(
    routeName: ReplaceRouteName,
  ): void;
  replace<ReplaceRouteName extends ScreenKey>(
    routeName: ReplaceRouteName,
    params: AllNavigationOptions<ReplaceRouteName>["params"],
  ): void;
  replace<ReplaceRouteName extends ScreenKey>(
    routeName: ReplaceRouteName,
    params?: AllNavigationOptions<ReplaceRouteName>["params"],
  ): void {
    const newOptions = this.getOptions<AllNavigationOptions<ReplaceRouteName>>(
      routeName,
      params,
    );

    if (NAVIGATION_VERBOSE) {
      console.log(
        `NavigationService.replace(${newOptions.routeName}, ${JSON.stringify(
          newOptions.params,
        )})`,
      );
    }

    this.navigate(newOptions as AnyUnexplained);
  }

  goBack() {
    return this.navigation.goBack();
  }

  getCurrentRoute() {
    return this.navigation;
  }

  getLocation() {
    return this.navigation.location.pathname;
  }

  isOnSuperadminPage() {
    return getCurrentRoute(this.navigation) === "Superadmin";
  }

  afterAttachInDirectory(roomId: string, directoryId: string) {
    const routeService = new RouteService<ScreenKey>(
      this.navigation as History<ScreenParams<ScreenKey>>,
    );

    if (routeService.isMapOpen()) {
      return;
    }
    this.navigate("RoomDirectory", { roomId, directoryId });
  }

  toggleConversationDetail(params: { roomId: string; open: boolean }) {
    this.navigateAndPreserveSearch({
      pathname: templateRoute(
        params.open ? routes.ConversationFolder : routes.Room,
        { roomId: params.roomId },
      ),
      state: params,
    });
  }

  closeConversationDetail() {
    const isDetailsScreenOpened = this.isDetailsScreenOpened();

    if (isDetailsScreenOpened) {
      const currentLocation = this.getLocation();

      this.navigation.replace(
        currentLocation
          .replace(routes.ConversationFolder, "")
          .replace(routes.ConversationInfo, ""),
      );
    }
  }

  goToSchemaMap(schemaId: string) {
    this.navigateAndPreserveSearch({
      pathname: this.navigation.location.pathname,
      search: new URLSearchParams({
        ...this.getResetQuery(detailsQuery.modularFolders),
        ...this.setViewModeQuery(viewModeQuery.geolocation, {}),
        [queries.schemaId]: schemaId,
        [queries.geoDetail]: geoDetailQuery.room,
        [queries.detailScreen]: detailsQuery.modularFolders,
      }),
    });
  }

  openRoomMapOnPhotoGallery({
    coords,
  }: { roomId?: string; coords?: GeoCoordinates }) {
    const pathname = this.getCurrentRoute().location.pathname;
    this.navigateAndPreserveSearch({
      pathname,
      search: new URLSearchParams({
        ...this.getResetQuery(detailsQuery.photoGallery),
        ...this.setViewModeQuery(viewModeQuery.geolocation, {
          [geoQuery.initialCenter]: coords
            ? toCoordinatesString(coords)
            : undefined,
        }),
        [queries.geoDetail]: geoDetailQuery.room,
        [queries.detailScreen]: detailsQuery.photoGallery,
      }),
    });
  }

  clickOnSchemaTab(schemaId: string) {
    this.goToSchemaMap(schemaId);
  }

  clickOnGoBackToList() {
    const search = new URLSearchParams(this.navigation.location.search);
    const schemaId = search.get(queries.schemaId);
    if (schemaId) {
      this.goToSchemaMap(schemaId);
    } else {
      console.warn("Cannot go back to list if schemaId is not defined");
    }
  }

  clickOnCloseRoomMap() {
    const shouldStayOnSameDrawer =
      this.isDrawerOpenOnSchema() || this.isDrawerOpenOnModularFolder();

    this.closeMap();

    if (shouldStayOnSameDrawer) {
      return;
    }

    if (this.wasDrawerOpenOnGallery()) {
      this.drawerGoToGallery();
    } else {
      this.drawerGoToCurrentRoot();
    }
  }

  clickOnConversationFromRoomList(roomId: string) {
    this.changeConversation(roomId);
  }

  changeConversation(roomId: string) {
    const currentRoute = getCurrentRoute(this.navigation);
    if (!currentRoute) {
      return;
    }

    const destination = templateRoute(routes[currentRoute], { roomId });

    if (this.isDrawerOpenOnModularFolder()) {
      this.navigateAndPreserveSearch({
        pathname: destination,
        search: new URLSearchParams({
          [queries.folderId]: "",
          [queries.detailScreen]: detailsQuery.modularFolders,
        }),
      });
    } else {
      this.navigateAndPreserveSearch({
        pathname: destination,
        search: new URLSearchParams({
          ...this.setViewModeQuery(viewModeQuery.conversation),
        }),
      });
    }
  }

  clickOnRoomMarkerFromRoomsMap(roomId: string) {
    this.closeMap();
    this.navigateAndPreserveSearch({
      pathname: templateRoute(routes.ConversationInfo, { roomId }),
    });
  }

  clickOnFolderMarkerFromRoomMap(schemaId: string, folderId: string) {
    this.navigateAndPreserveSearch({
      pathname: this.navigation.location.pathname,
      search: new URLSearchParams({
        ...this.getResetQuery(detailsQuery.modularFolder),
        ...this.setViewModeQuery(viewModeQuery.geolocation, {}),
        [queries.schemaId]: schemaId,
        [queries.folderId]: folderId,
        [queries.geoDetail]: geoDetailQuery.modularFolder,
        [queries.detailScreen]: detailsQuery.modularFolder,
      }),
    });
  }

  afterCreatingWorkspace(poolId: string) {
    this.navigateAndPreserveSearch({
      pathname: routes.Messenger,
      search: new URLSearchParams({
        [queries.poolId]: poolId,
      }),
    });
  }

  afterForwardingMessageTo(roomId: string) {
    const currentRoute = getCurrentRoute(this.navigation);
    if (!currentRoute) {
      return;
    }

    const destination = templateRoute(routes[currentRoute], { roomId });

    if (this.isDrawerOpenOnModularFolder()) {
      this.navigateAndPreserveSearch({
        pathname: destination,
        search: new URLSearchParams({
          [queries.folderId]: "",
          [queries.detailScreen]: detailsQuery.modularFolders,
        }),
      });
    } else {
      this.navigateAndPreserveSearch({ pathname: destination });
    }
  }

  private closeMap() {
    const pathname = this.navigation.location.pathname;
    this.navigateAndPreserveSearch({
      pathname,
      search: new URLSearchParams({
        ...this.setViewModeQuery(viewModeQuery.conversation),
        [queries.geoDetail]: "",
      }),
    });
  }

  clickOnOpenRoomMap({ coords }: { roomId?: string; coords?: GeoCoordinates }) {
    if (this.isDrawerOpenOnSchema()) {
      this.openRoomMap({ coords });
    } else if (this.isDrawerOpenOnModularFolder()) {
      this.openFolderMap({ coords });
    } else if (this.isDrawerOpenOnGallery()) {
      this.rememberPreviousDrawer();
      this.openRoomMapOnPhotoGallery({ coords });
    } else {
      this.openRoomMapOnPhotoGallery({ coords });
    }
  }

  rememberPreviousDrawer() {
    const search = this.navigation.location.search;
    const params = new URLSearchParams(search);

    const previousDrawer = params.get(queries.detailScreen);

    this.navigateAndPreserveSearch({
      pathname: this.navigation.location.pathname,
      search: new URLSearchParams({
        [queries.previousDetailScreen]: previousDrawer || "",
      }),
    });
  }

  private openRoomMap({ coords }: { coords?: GeoCoordinates }) {
    const pathname = this.navigation.location.pathname;
    this.navigateAndPreserveSearch({
      pathname,
      search: new URLSearchParams({
        ...this.setViewModeQuery(viewModeQuery.geolocation, {
          [geoQuery.initialCenter]: coords
            ? toCoordinatesString(coords)
            : undefined,
        }),
        [queries.geoDetail]: geoDetailQuery.room,
      }),
    });
  }

  private openFolderMap({ coords }: { coords?: GeoCoordinates }) {
    this.navigateAndPreserveSearch({
      pathname: this.navigation.location.pathname,
      search: new URLSearchParams({
        ...this.setViewModeQuery(viewModeQuery.geolocation, {
          [geoQuery.initialCenter]: coords
            ? toCoordinatesString(coords)
            : undefined,
        }),
        [queries.geoDetail]: geoDetailQuery.modularFolder,
      }),
    });
  }

  toggleOnRoomsMap() {
    this.navigateAndPreserveSearch({
      pathname: this.navigation.location.pathname,
      search: new URLSearchParams({
        ...this.setViewModeQuery(viewModeQuery.geolocation, {}),
        [queries.geoDetail]: geoDetailQuery.rooms,
      }),
    });
  }

  toggleOffRoomsMap() {
    this.navigateAndPreserveSearch({
      pathname: this.navigation.location.pathname,
      search: new URLSearchParams({
        ...this.setViewModeQuery(viewModeQuery.conversation),
        [queries.geoDetail]: "",
      }),
    });
  }

  isDrawerOpenOnDirectory() {
    const search = new URLSearchParams(this.navigation.location.search);
    const detailScreen = search.get(queries.detailScreen);
    return detailScreen === detailsQuery.directory;
  }

  navigateToWorkflow(workflowId?: string) {
    const pathname = routes.ManageWorkflows;
    const search = new URLSearchParams(this.getResetQuery());

    if (workflowId) {
      search.set("workflowId", workflowId);
    }

    this.navigateAndPreserveSearch({
      pathname,
      search,
    });
  }

  private isDrawerOpenOnSchema() {
    const search = this.navigation.location.search;
    const searchParams = new URLSearchParams(search);
    return (
      searchParams.has(queries.detailScreen) &&
      searchParams.has(queries.schemaId) &&
      searchParams.get(queries.detailScreen) === detailsQuery.modularFolders
    );
  }

  private isDrawerOpenOnModularFolder() {
    const search = this.navigation.location.search;
    const searchParams = new URLSearchParams(search);
    return (
      searchParams.has(queries.detailScreen) &&
      searchParams.has(queries.schemaId) &&
      searchParams.has(queries.folderId)
    );
  }

  private isDrawerOpenOnGallery() {
    const search = this.navigation.location.search;
    const searchParams = new URLSearchParams(search);
    return searchParams.get(queries.detailScreen) === detailsQuery.photoGallery;
  }

  private wasDrawerOpenOnGallery() {
    const search = this.navigation.location.search;
    const searchParams = new URLSearchParams(search);
    return (
      searchParams.get(queries.previousDetailScreen) ===
      detailsQuery.photoGallery
    );
  }

  private drawerGoToCurrentRoot() {
    const pathname = this.navigation.location.pathname;
    this.navigateAndPreserveSearch({
      pathname,
      search: new URLSearchParams({
        [queries.detailScreen]: "",
        [queries.schemaId]: "",
        [queries.folderId]: "",
      }),
    });
  }

  private drawerGoToGallery() {
    const pathname = this.navigation.location.pathname;
    this.navigateAndPreserveSearch({
      pathname,
      search: new URLSearchParams({
        [queries.detailScreen]: detailsQuery.photoGallery,
      }),
    });
  }

  private navigateToHome() {
    this.navigateAndPreserveSearch({
      pathname: routes.Messenger,
      search: new URLSearchParams({
        ...this.getResetQuery(),
      }),
    });
  }

  private navigateToSettings(pathname: string) {
    this.navigateAndPreserveSearch({
      pathname: pathname,
      search: new URLSearchParams(this.getResetQuery()),
    });
  }

  private navigateToConversation(
    params: ScreenParamsUndefined<"Conversation">,
  ) {
    const { preserveDetails } = params;

    const drawerOpenOnModularFolder = this.isDrawerOpenOnModularFolder();
    const wantedTab =
      params.openedTab === "folder"
        ? routes.ConversationFolder
        : params.openedTab === "infos"
          ? routes.ConversationInfo
          : routes.Room;
    const searchParams = this.getRoomSearchParams();

    const pathname = preserveDetails
      ? templateRoute(
          drawerOpenOnModularFolder
            ? routes.ConversationFolder
            : routes.ConversationInfo,
          {
            roomId: params.roomId,
          },
        )
      : templateRoute(wantedTab, {
          roomId: params.roomId,
        });

    this.navigateAndPreserveSearch({
      pathname,
      search: preserveDetails
        ? searchParams
        : new URLSearchParams(this.getResetQuery()),
      state: params,
    });
  }

  private openConversationDrawer(
    route:
      | (typeof routes)["ConversationInfo"]
      | (typeof routes)["ConversationFolder"],
    params: ConversationDetailsScreenParams,
    searchParams?: Partial<Record<queries, string>>,
  ) {
    const detailScreenOpened = this.isDetailsScreenOpened();

    if (!detailScreenOpened && searchParams?.[queries.detailScreen]) {
      params.isStickyPanel = true;
    }

    const shouldOpenDrawer =
      params.doNotTogglePanel && !this.isDetailsScreenOpened();

    this.navigateAndPreserveSearch({
      pathname: templateRoute(shouldOpenDrawer ? routes.Room : route, {
        roomId: params.roomId,
      }),
      state: params,
      search: new URLSearchParams({
        ...this.getResetQuery(),
        ...((searchParams ?? {}) as Record<string, string>),
      }),
    });
  }
}

export { NavigationService };
