import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";

import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { ManageTagsSheet } from "@kraaft/shared/core/modules/schemaLibraryTag/components/manageTags.sheet";
import { Button } from "@kraaft/ui";

type ManageSchemaLibraryTagsButtonProps = {
  language: LibrarySchemaLanguage;
};

export const ManageSchemaLibraryTagsButton = ({
  language,
}: ManageSchemaLibraryTagsButtonProps) => {
  const { open, element } = ManageTagsSheet.use({ language });
  const { t } = useTranslation();
  return (
    <>
      <Button
        style={styles.manage}
        text={t("schemaLibraryViewTags.settings.manageTag")}
        onPress={open}
        variant="SECONDARY"
        size="SMALL"
      />
      {element}
    </>
  );
};

const styles = StyleSheet.create({
  manage: {
    flexShrink: 0,
  },
});
