import keyBy from "lodash/keyBy";
import { eventChannel } from "redux-saga";
import { put, spawn, take, takeEvery } from "typed-redux-saga/macro";

import { OfflineSchemaLibraryTagStateActions } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.offline";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { Firestore } from "@kraaft/shared/core/services/firestore";

export function* subscribeToSchemaLibraryTagsSaga() {
  yield* spawn(subscribeToSchemaLibraryTags);
}

function* subscribeToSchemaLibraryTags() {
  while (true) {
    yield* take(UserActions.userConnectedToFirebase);

    const channel = eventChannel<Array<SchemaLibraryTag>>((emit) =>
      Firestore.subscribeToSchemaLibraryTags((data) => emit(data)),
    );

    yield* takeEvery(channel, receiveSchemaLibraryTags);

    yield* take(UserActions.userDisconnectedFromFirebase);
    channel.close();
  }
}

function* receiveSchemaLibraryTags(tags: Array<SchemaLibraryTag>) {
  const tagDict = keyBy(tags, (tag) => tag.id);
  yield* put(OfflineSchemaLibraryTagStateActions.receive(tagDict));
}
