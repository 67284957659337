import {
  FocusEventHandler,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";

import { Tooltip } from "@kraaft/shared/components/tooltip";
import { textEllipsis } from "@kraaft/shared/core/utils";
import { Text } from "@kraaft/ui";
import {
  UnlabeledTextInput,
  UnlabeledTextInputProps,
} from "@kraaft/web/src/components/unlabeledTextInput";

import { useStyles } from "./editableText.styles";

interface EditableTextProps {
  id?: string;

  editing: boolean;
  setEditing?: (newValue: boolean) => void;
  inputLimit?: number;
  overLimitHelperMessage?: string;

  numberOfLines?: number;
  textInputProps: UnlabeledTextInputProps;
}

export interface EditableTextHandle {
  blur: () => void;
}

const EditableText = forwardRef<EditableTextHandle, EditableTextProps>(
  (
    {
      id,
      editing,
      setEditing,
      inputLimit,
      overLimitHelperMessage,
      textInputProps,
      numberOfLines,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const classes = useStyles();

    const isOverTheLimit =
      inputLimit !== undefined
        ? (textInputProps.value?.length ?? 0) > inputLimit
        : false;

    const displayValue =
      inputLimit !== undefined
        ? textEllipsis(textInputProps.value ?? "", inputLimit)
        : textInputProps.value;

    useImperativeHandle(
      ref,
      useCallback(
        () => ({
          blur: () => inputRef.current?.blur(),
        }),
        [],
      ),
    );

    const startEditing = useCallback(() => {
      setEditing?.(true);
      setTimeout(() => {
        // The way it works today is that we need to wait for the next render
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 1);
    }, [setEditing]);

    const handleOnBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
      (event) => {
        textInputProps.onBlur?.(event);
        setEditing?.(false);
      },
      [setEditing, textInputProps],
    );

    return (
      <>
        {!editing && (
          <div role="button" onClick={startEditing} className={classes.text}>
            <Text size="BODY" id={id} numberOfLines={numberOfLines}>
              {displayValue}
            </Text>
          </div>
        )}
        {editing ? (
          <Tooltip
            title={overLimitHelperMessage ?? ""}
            open={isOverTheLimit}
            placement="bottom-start"
          >
            <UnlabeledTextInput
              id={`${id}-input`}
              {...textInputProps}
              onBlur={handleOnBlur}
              ref={inputRef}
            />
          </Tooltip>
        ) : null}
      </>
    );
  },
);

export { EditableText };
