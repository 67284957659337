import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Autosuggest, {
  InputProps,
  OnSuggestionsClearRequested,
  OnSuggestionSelected,
  RenderInputComponentProps,
  SuggestionsFetchRequested,
  Theme,
} from "react-autosuggest";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { noop } from "ts-essentials";

import {
  PoolSuggestion,
  SuperadminApi,
} from "@kraaft/shared/core/services/api/superadminApi";
import { languageFlag } from "@kraaft/shared/core/utils";
import { executeAfterStatePropagation, Icon } from "@kraaft/ui";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";
import { SuggestionsSheet } from "@kraaft/web/src/views/settings/backOffice/poolSuggestions.popup";

import { PoolSuggestionsPopupContent } from "./poolSuggestionsPopupContent";

import { useStyles } from "./poolSuggestions.styles";

type PoolSuggestionsProps = {
  onPoolSelected: (name: string, id: string | undefined) => void;
  alwaysRenderSuggestions?: boolean;
  focusInputOnSuggestionClick?: boolean;
  autoFocus?: boolean;
};

const PoolSuggestions = ({
  onPoolSelected,
  alwaysRenderSuggestions,
  focusInputOnSuggestionClick,
  autoFocus,
}: PoolSuggestionsProps) => {
  const inputRef = useRef<Autosuggest>(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [poolSuggestions, setPoolSuggestions] = useState<PoolSuggestion[]>([]);
  const [isLoading, setLoading] = useState(false);

  const debouncedRequestIndex = useRef(0);

  const getSuggestionValue = useCallback((suggestion: PoolSuggestion) => {
    return suggestion.name;
  }, []);

  const getPoolSuggestionsForValue = useMemo(
    () =>
      debounce(async (search: string) => {
        debouncedRequestIndex.current += 1;
        const localDebouncedRequestIndex = debouncedRequestIndex.current;
        if (!search) {
          setPoolSuggestions([]);
          setLoading(false);
          return;
        }

        setLoading(true);
        const result = await SuperadminApi.searchPoolName({ search });

        const shouldUpdateValue =
          localDebouncedRequestIndex === debouncedRequestIndex.current;

        if (shouldUpdateValue) {
          setPoolSuggestions(result);
          setLoading(false);
        }
      }, 150),
    [],
  );

  const handleSuggestionsFetchRequested =
    useCallback<SuggestionsFetchRequested>(
      async ({ value }) => {
        await getPoolSuggestionsForValue(value);
      },
      [getPoolSuggestionsForValue],
    );

  const handleSuggestionsClearRequested =
    useCallback<OnSuggestionsClearRequested>(() => {
      if (!inputValue) {
        setPoolSuggestions([]);
      }
    }, [inputValue]);

  const handleSuggestionsSelected = useCallback<
    OnSuggestionSelected<PoolSuggestion>
  >(
    (_, { suggestion }) => {
      inputRef.current?.input?.blur();
      onPoolSelected(suggestion.name, suggestion.id);
    },
    [onPoolSelected],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.defaultPrevented) {
        return;
      }
      if (event.key === "Enter") {
        onPoolSelected(inputValue, undefined);
      }
    },
    [onPoolSelected, inputValue],
  );

  const renderInputComponent = useCallback(
    ({ value, defaultValue, ...inputProps }: RenderInputComponentProps) => {
      return (
        <UnlabeledTextInput
          placeholder={t("searchPoolByName")}
          {...inputProps}
          defaultValue={defaultValue?.toString()}
          value={value}
          onChangeText={setInputValue}
          enableClearIcon
          isLoading={isLoading}
          className={classes.poolNameInput}
        />
      );
    },
    [classes.poolNameInput, isLoading, t],
  );

  const renderSuggestion = useCallback(
    (entry: PoolSuggestion) => {
      const flagEmoji = languageFlag[entry.language];
      const isRestricted = entry.isFreemium;

      return (
        <div className={classes.suggestionRow}>
          <div className={classes.suggestionRowContent}>
            <div className={classes.suggestionRowTitle}>{entry.name}</div>
            <div className={classes.suggestionRowTags}>
              {isRestricted && (
                <Icon
                  name="slash-octagon"
                  size="MEDIUM"
                  color="FONT_HIGH_EMPHASIS"
                />
              )}
              {Boolean(flagEmoji) && <div>{flagEmoji}</div>}
            </div>
          </div>
        </div>
      );
    },
    [
      classes.suggestionRow,
      classes.suggestionRowContent,
      classes.suggestionRowTags,
      classes.suggestionRowTitle,
    ],
  );

  const inputProps = useMemo<InputProps<PoolSuggestion>>(
    () => ({
      value: inputValue,
      onChange: (_, { newValue }) => setInputValue(newValue),
      onKeyDown: handleKeyDown,
    }),
    [handleKeyDown, inputValue],
  );

  const theme = useMemo<Theme>(
    () => ({
      suggestionsContainer: classes.suggestionsContainer,
      suggestionsList: classes.suggestionsList,
      suggestionHighlighted: classes.suggestionHighlighted,
    }),
    [
      classes.suggestionHighlighted,
      classes.suggestionsContainer,
      classes.suggestionsList,
    ],
  );

  useEffect(() => {
    if (autoFocus) {
      executeAfterStatePropagation(() => {
        inputRef.current?.input?.focus();
      });
    }
  }, [autoFocus]);

  const rootRef = useRef<any>(null);

  return (
    <div ref={rootRef} className={classes.container}>
      <Autosuggest
        ref={inputRef}
        id="pool-name"
        suggestions={poolSuggestions ?? []}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        onSuggestionSelected={handleSuggestionsSelected}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={({ children, containerProps }) => (
          <SuggestionsSheet.Host
            open
            bubbleOnClose
            onClose={noop}
            anchor={rootRef}
            placement="bottom-start"
            followRefWidth
            withoutBackdrop
            lockPlacement
            preventOverflow
          >
            {/* eslint-disable-next-line @kraaft/self-closing-sheet-child */}
            <PoolSuggestionsPopupContent ref={containerProps.ref}>
              {children}
            </PoolSuggestionsPopupContent>
          </SuggestionsSheet.Host>
        )}
        inputProps={inputProps}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        highlightFirstSuggestion
        theme={theme}
      />
    </div>
  );
};

export { PoolSuggestions };
