import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { taskManager } from "@kraaft/shared/core/store/offline";
import { DeclareOfflineFeature } from "@kraaft/shared/core/utils/optimistic/newOptimistic/createOfflineFeature";
import { reorderSchemaLibraryTagsOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/reorderSchemaLibraryTagsOperation";
import { renameSchemaLibraryTagOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/renameSchemaLibraryTagOperation";
import { deleteSchemaLibraryTagOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/deleteSchemaLibraryTagOperation";
import { createSchemaLibraryTagOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/createSchemaLibraryTagOperation";

export const SchemaLibraryTagOfflineFeature =
  DeclareOfflineFeature<SchemaLibraryTag>("SchemaLibraryTag")(
    {
      reorderSchemaLibraryTags: reorderSchemaLibraryTagsOperation,
      renameSchemaLibraryTag: renameSchemaLibraryTagOperation,
      deleteSchemaLibraryTag: deleteSchemaLibraryTagOperation,
      createSchemaLibraryTag: createSchemaLibraryTagOperation,
    },
    taskManager.createTaskManager("SchemaLibraryTag"),
  );
