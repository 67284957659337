import { makeStyles } from "@mui/styles";

export const useDragLayerStyles = makeStyles({
  layerBase: {
    display: "flex",
    alignItems: "flex-start",

    position: "fixed",
    pointerEvents: "none",
    // DragLayer must be on top of modals which are zIndex: 1500
    zIndex: 1600,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
});
