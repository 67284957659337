import React, { useCallback } from "react";

import { OverlayMenu } from "@kraaft/shared/components/overlayMenu";
import { PoolSelectorImplementationProps } from "@kraaft/shared/components/poolSelectorImplementation/poolSelectorImplementationProps";
import { PoolsMenu } from "@kraaft/shared/components/poolsMenu";
import { CreatePoolButton } from "@kraaft/shared/components/poolsMenu/createPoolButton";
import { useIsCurrentUserExternalInAllPools } from "@kraaft/shared/components/poolsMenu/useIsCurrentUserExternalInAllPools";
import { CreatePoolFlowSheet } from "@kraaft/shared/core/modules/pool/components/addPool/createPoolFlowSheet";
import { useCreateOrJoinPoolSheet } from "@kraaft/shared/core/modules/pool/components/addPool/useCreateOrJoinPoolSheet";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";

const PoolSelectorImplementation = ({
  open,
  onClose,
  currentPoolLocation,
  onChangePoolLocation,
  anchorRef,
}: PoolSelectorImplementationProps & {
  anchorRef: React.RefObject<Element>;
}) => {
  const handleChangePoolLocation = useCallback(
    (newLocation: PoolLocation, newLocationName: string) => {
      onChangePoolLocation(newLocation, newLocationName);
      onClose();
    },
    [onChangePoolLocation, onClose],
  );

  const isCurrentUserExternalInAllPools = useIsCurrentUserExternalInAllPools();

  const { element: createPoolFlowSheetElement, open: openCreatePoolFlowSheet } =
    CreatePoolFlowSheet.use({});

  const { openCreateOrJoinPool, createOrJoinPoolSheetElement } =
    useCreateOrJoinPoolSheet({
      closePoolSelectionMenu: onClose,
      openPoolCreationFlow: openCreatePoolFlowSheet,
    });

  return (
    <>
      <OverlayMenu visible={open} onClose={onClose} anchorRef={anchorRef}>
        <div>
          {/* This div is needed, otherwise poolsMenu height is 0 */}
          <PoolsMenu
            currentPoolLocation={currentPoolLocation}
            onChangePoolLocation={handleChangePoolLocation}
            maxHeight={400}
          />
          {isCurrentUserExternalInAllPools && (
            <CreatePoolButton onPress={openCreateOrJoinPool} />
          )}
        </div>
      </OverlayMenu>
      {createOrJoinPoolSheetElement}
      {createPoolFlowSheetElement}
    </>
  );
};

export { PoolSelectorImplementation };
