import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import type { ListRenderItem } from "@react-native/virtualized-lists";

import { useFallbackRef } from "@kraaft/helper-hooks";
import { Hoverable } from "@kraaft/shared/components/hoverable";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { conditionalEntry } from "@kraaft/shared/core/utils/utils";
import { Color, ColorStyle, Sheet, Spacing, Text } from "@kraaft/ui";

export type SuggestionsSheetProps = {
  items: Array<LibrarySchema>;
  onClick: (value: LibrarySchema) => void;
  suggestionSheetRef: React.RefObject<NavigableRef>;
};

type NavigableRef = {
  up: () => void;
  down: () => void;
  selectActive: () => void;
};

export function useSuggestionSheetNavigableRef() {
  return React.createRef<NavigableRef>();
}

export const SchemaLibrarySuggestionsSheet = Sheet({
  web: "anchored",
}).create<SuggestionsSheetProps>(
  ({ Paper }) =>
    ({ onClose, items, onClick, suggestionSheetRef }) => {
      const renderSeparator = useCallback(
        () => <View style={styles.separator} />,
        [],
      );

      const sheetRef = useFallbackRef(suggestionSheetRef, null);

      const keyExtractor = useCallback((item: LibrarySchema) => item.id, []);

      const handleOnItemClicked = useCallback(
        (item: LibrarySchema) => {
          onClick?.(item);
          onClose();
        },
        [onClick, onClose],
      );

      const [focusedItemIndex, setFocusedItemIndex] = useState<
        number | undefined
      >(undefined);

      const flatListRef = useRef<FlatList>(null);

      const renderItem = useCallback<ListRenderItem<LibrarySchema>>(
        ({ item, index }) => (
          <Hoverable>
            {({ hoverProps, isHovered }) => (
              <TouchableOpacity
                accessibilityLabel={item.name}
                style={[
                  styles.suggestion,
                  conditionalEntry(
                    styles.suggestionHovered,
                    isHovered || focusedItemIndex === index,
                  ),
                ]}
                onPress={() => handleOnItemClicked(item)}
                {...hoverProps}
              >
                <Text>{item.name}</Text>
              </TouchableOpacity>
            )}
          </Hoverable>
        ),
        [handleOnItemClicked, focusedItemIndex],
      );

      const incrementFocusedIndex = useCallback(
        () =>
          setFocusedItemIndex(
            (index) => (index === undefined ? 0 : index + 1) % items.length,
          ),
        [items.length],
      );

      const decrementFocusedIndex = useCallback(
        () =>
          setFocusedItemIndex((index) =>
            !index ? items.length - 1 : index - 1,
          ),
        [items.length],
      );

      useEffect(() => {
        setFocusedItemIndex(undefined);
      }, [items.length]);

      useEffect(() => {
        if (focusedItemIndex !== undefined) {
          flatListRef.current?.scrollToIndex({
            animated: true,
            index: focusedItemIndex,
          });
        }
      }, [focusedItemIndex]);

      useImperativeHandle(
        sheetRef,
        () => ({
          up() {
            decrementFocusedIndex();
          },
          down() {
            incrementFocusedIndex();
          },
          selectActive() {
            if (focusedItemIndex === undefined) {
              return;
            }
            if (items[focusedItemIndex] === undefined) {
              return;
            }
            onClick?.(items[focusedItemIndex]);
          },
        }),
        [
          decrementFocusedIndex,
          focusedItemIndex,
          incrementFocusedIndex,
          items,
          onClick,
        ],
      );

      return (
        <Paper noPadding noGap>
          <FlatList
            ref={flatListRef}
            data={items}
            style={styles.container}
            ItemSeparatorComponent={renderSeparator}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
          />
        </Paper>
      );
    },
);

export const styles = StyleSheet.create({
  container: {
    maxHeight: 200,
  },
  suggestion: {
    height: Spacing.S32,
    justifyContent: "center",
    paddingHorizontal: Spacing.S12,
  },
  suggestionHovered: {
    backgroundColor: ColorStyle.ACTION_HOVERED,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: Color.GREY_FRENCH,
    marginHorizontal: Spacing.S12,
  },
});
