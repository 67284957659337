import { takeEvery } from "typed-redux-saga/macro";

import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import { UserAuth } from "../../../user/userState";

export function* initializeUserSaga() {
  yield* takeEvery(
    UserActions.userConnectedToFirebase,
    onUserConnectedToFirebase,
  );
}

function* onUserConnectedToFirebase({
  payload: { userAuth },
}: ReturnType<typeof UserActions.userConnectedToFirebase>) {
  updateUserSequence(userAuth).catch(console.error);
}

async function updateUserSequence(userAuth: UserAuth) {
  await intercom.initializeForUser({
    userId: userAuth.uid,
  });

  await intercom.updateUserAuth({
    email: userAuth.email ?? "",
    phone: userAuth.phoneNumber ?? "",
  });
}
