import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { DirectoryListSelectionFooter } from "@kraaft/shared/components/actionCardList/directoryList/directoryListWithSelection/directoryListSelectionFooter";
import { VirtualizedDirectoryList } from "@kraaft/shared/components/actionCardList/directoryList/virtualizedDirectoryList";
import { AddDirectorySheet } from "@kraaft/shared/components/directory/addDirectorySheet";
import { DirectoryContentAddFooter } from "@kraaft/shared/components/directory/directoryContentAddFooter";
import {
  FillDirectoryDroppable,
  FillDirectoryDroppableHandle,
} from "@kraaft/shared/components/directory/fillDirectoryDroppable";
import { useFilteredDirectorySubItems } from "@kraaft/shared/components/directory/useFilteredDirectorySubItems";
import { HideWhenKeyboard } from "@kraaft/shared/components/hideWhenKeyboard";
import { useEnsurePermission } from "@kraaft/shared/components/permissionRequester/useEnsurePermission";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  Directory,
  DIRECTORY_MAX_DEPTH,
} from "@kraaft/shared/core/modules/directory/directory";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { selectChildDirectories } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { selectMessageSelectionCount } from "@kraaft/shared/core/modules/message/messageSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { useMediaSelectionContext } from "@kraaft/shared/core/utils/mediaSelection/useMediaSelectionContext";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import { ColorStyle, Preloader, Spacing } from "@kraaft/ui";

interface DirectoryContentProps {
  directory: Directory;
  filter: string;
}

export const DirectoryContent = ({
  directory,
  filter,
}: DirectoryContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id, roomId } = directory;
  const navigation = useNavigationService();
  const selectionCount = useSelector(
    selectMessageSelectionCount(roomId, "directoryList"),
  );
  const directories = useSelector(selectChildDirectories(roomId, id));
  const { directories: filteredDirectories, files: filteredFiles } =
    useFilteredDirectorySubItems(directory.id, filter);

  useEffect(() => {
    dispatch(DirectoryActions.subscribe({ roomId }));
    return () => {
      dispatch(DirectoryActions.unsubscribe({ roomId }));
    };
  }, [dispatch, roomId]);

  const finalDirectories =
    filter.length > 0 ? filteredDirectories : directories;
  const finalFiles = filter.length > 0 ? filteredFiles : directory.files;

  const droppableRef = useRef<FillDirectoryDroppableHandle>(null);

  const [isDroppableLoading, setIsDroppableLoading] = useState(false);

  const [isAddDirectoryFlowOpen, openAddDirectoryFlow, closeAddDirectoryFlow] =
    useBooleanState();

  const { loaderId: addDirectoryLoaderId, loading: isAddDirectoryLoading } =
    useLoader({
      prefix: "addDirectoryFromWorksiteFolder",
    });
  const { loaderId: takePhotoLoaderId, loading: isTakePhotoLoading } =
    useLoader({
      prefix: "takePhotoInDirectory",
    });

  const { handleSelect } = useMediaSelectionContext({
    roomId,
    medias: finalFiles ?? [],
    type: "directory",
  });

  const handleAddDirectory = useCallback(() => {
    if (directory.depth === DIRECTORY_MAX_DEPTH) {
      dispatch(showError({ title: t("directory.errorDepthExceeded") }));
      return;
    }
    openAddDirectoryFlow();
  }, [directory.depth, dispatch, openAddDirectoryFlow, t]);

  const handleOpenFilePicker = useCallback(() => {
    droppableRef.current?.open();
  }, []);

  const handlePressDirectory = useCallback(
    (directoryId: string) => {
      navigation.replace("RoomDirectory", {
        roomId,
        directoryId,
      });

      const pressedDirectory = finalDirectories.find(
        (_directory) => _directory.id === directoryId,
      );

      if (pressedDirectory) {
        trackEvent({
          eventName: "Click On Directory",
          room_id: roomId,
          directory_id: directoryId,
          directory_name: pressedDirectory.name,
          directory_level: pressedDirectory.depth,
        });
      }
    },
    [finalDirectories, navigation, roomId],
  );

  const handleTakePhoto = useEnsurePermission(
    useCallback(() => {
      navigation.navigate("Camera", {
        context: {
          type: "directory",
          directoryId: directory.id,
          roomId: directory.roomId,
          loaderId: takePhotoLoaderId,
          source: "camera",
        },
      });
    }, [navigation, directory.id, directory.roomId, takePhotoLoaderId]),
    "camera",
    "take-picture",
  );

  return (
    <View style={styles.droppableContainer}>
      <FillDirectoryDroppable
        ref={droppableRef}
        directory={directory}
        onLoading={setIsDroppableLoading}
      >
        <View style={styles.droppableChild}>
          <VirtualizedDirectoryList
            nativeID={`directory-list-${directory.name}`}
            roomId={roomId}
            directoryId={directory.id}
            style={styles.list}
            directories={finalDirectories}
            files={finalFiles}
            onPressDirectory={handlePressDirectory}
            onSelect={handleSelect}
            newDirectoryElement={
              <AddDirectorySheet
                open={isAddDirectoryFlowOpen}
                onClose={closeAddDirectoryFlow}
                roomId={roomId}
                parentId={directory.id}
                loaderId={addDirectoryLoaderId}
                loading={isAddDirectoryLoading}
              />
            }
          />

          {(isDroppableLoading || isTakePhotoLoading) && (
            <Preloader style={styles.loader} />
          )}

          {selectionCount ? (
            <DirectoryListSelectionFooter roomId={roomId} directoryId={id} />
          ) : (
            <HideWhenKeyboard>
              <DirectoryContentAddFooter
                directory={directory}
                onTakePhoto={handleTakePhoto}
                onAddDirectory={handleAddDirectory}
                onOpenFilePicker={handleOpenFilePicker}
              />
              <SafeAreaView style={styles.safeAreaContainer} />
            </HideWhenKeyboard>
          )}
        </View>
      </FillDirectoryDroppable>
    </View>
  );
};

const styles = StyleSheet.create({
  droppableContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
  droppableChild: {
    height: "100%",
  },
  loader: {
    paddingTop: Spacing.S16,
  },
  list: {
    flexGrow: 1,
    paddingVertical: Spacing.S8,
    paddingHorizontal: Spacing.S16,
  },
  safeAreaContainer: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
});
