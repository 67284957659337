import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { OfflineSchemaLibraryTagActions } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.offline";
import { selectSchemaLibraryTagCountByLanguage } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.selectors";
import { Button, Sheet } from "@kraaft/ui";

type AddTagSheetProps = {
  language: LibrarySchemaLanguage;
};
export const AddTagSheet = Sheet({
  web: "popup",
})
  .create<AddTagSheetProps>(
    ({ Content, Footer, Header, Paper }) =>
      ({ onClose, language }) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const [text, setText] = useState("");
        const tagCount = useSelector(
          selectSchemaLibraryTagCountByLanguage(language),
        );

        const handleCreateTag = useCallback(async () => {
          dispatch(
            OfflineSchemaLibraryTagActions.createSchemaLibraryTag({
              name: text,
              language: language,
              index: tagCount,
            }),
          );
          onClose();
        }, [dispatch, text, language, tagCount, onClose]);

        return (
          <Paper>
            <Header onClose={onClose}>
              {t("schemaLibraryViewTags.settings.createTag")}
            </Header>
            <Content>
              <KInput
                label={t("schemaLibraryViewTags.settings.tagName")}
                value={text}
                onChangeText={setText}
                autoFocus
                onSubmitEditing={handleCreateTag}
              />
            </Content>
            <Footer>
              <Button onPress={handleCreateTag} text={t("create")} />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });
