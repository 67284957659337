import { useMeshContext } from "@kraaft/helper-hooks";
import { WorkspaceNameInput } from "@kraaft/shared/components/createWorkspace/workspaceNameInput";

import { CreatePoolFlowContext } from "../createPoolFlow.context";

export interface TeamNameStepProps {
  submit: () => void;
}

export const TeamNameStep = ({ submit }: TeamNameStepProps) => {
  const { poolName, setPoolName, setIsSubmitPoolNameDisabled } = useMeshContext(
    CreatePoolFlowContext,
  );

  return (
    <WorkspaceNameInput
      setName={setPoolName}
      setIsSubmitDisabled={setIsSubmitPoolNameDisabled}
      name={poolName}
      submit={submit}
    />
  );
};
