/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Animated,
  Easing,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  Text,
  TextInputFocusEventData,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";

import { styles } from "@kraaft/shared/components/input/kInput.styles";
import {
  KTextInput,
  KTextInputHandle,
  KTextInputProps,
} from "@kraaft/shared/components/KTextInput";
import { useInputFocusAfterTransition } from "@kraaft/shared/core/services/navigation/useInputFocusAfterTransition";
import { TrackableViewProps } from "@kraaft/shared/core/types";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import {
  Color,
  ColorStyle,
  FontSize,
  Icon,
  IconName,
  Spacing,
} from "@kraaft/ui";

const ANIMATION_TIME = 200;
const ANIMATION_EASING = Easing.inOut(Easing.ease);

// prevent animations from using `useNativeDriver` on web
const getNativeDriver = (val: boolean) => ({
  useNativeDriver: Platform.OS === "web" ? false : val,
});

export type KInputProps = Omit<KTextInputProps, keyof TrackableViewProps> & {
  /** use defaultValue if you don't want the input value to be impacted during edition */
  defaultValue?: string;
  disableAccessibility?: boolean;
  disabled?: boolean;
  error?: boolean;
  label: string;
  leftIcon?: {
    name: IconName;
    accessibilityLabel: TrackableViewProps["accessibilityLabel"];
    action?: () => void;
    color?: string;
  };
  rightIcon?: React.ReactElement;
  numberOfLines?: number;
  onBlur?: (event?: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onFocus?: (event?: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onChangeText: (text: string) => void;
  showEditIcon?: boolean;
  value?: string;
  status?: "validated" | "erroneous";
  helperText?: string;
  withHelperTextPadding?: boolean;
  inputContainerStyle?: ViewStyle;
  disableAutocomplete?: boolean;
  lightVariant?: boolean;
};

export interface KInputHandle {
  focus(): void;
  blur(): void;
}

const KInput_ = forwardRef<KInputHandle, KInputProps>(
  ({ autoFocus = false, numberOfLines, ...inputProps }, ref) => {
    const {
      defaultValue,
      disableAccessibility,
      disabled,
      error,
      label,
      leftIcon,
      onBlur,
      onChangeText,
      onFocus,
      showEditIcon,
      style,
      status,
      value,
      rightIcon,
      helperText,
      withHelperTextPadding,
      inputContainerStyle,
      inputRef,
      disableAutocomplete,
      lightVariant,
      multiline,
      placeholder,
      nativeID,
      onSubmitEditing,
    } = inputProps;

    const { t } = useTranslation();

    const [textWhileEditing, setTextWhileEditing] = useState(value);
    const [isEditing, setIsEditing] = useState(autoFocus);
    const [isScrollEnabled, setScrollEnabled] = useState(true);
    const [wasEditingWhenIconPressedIn, setWasEditingWhenIconPressedIn] =
      useState(false);
    const textInputRef = useRef<KTextInputHandle>(null);

    const inputValue =
      value !== undefined ? value : isEditing ? textWhileEditing : defaultValue;

    const colorAnimation = useRef(
      new Animated.Value(autoFocus ? 1 : 0),
    ).current;
    const transformAnimation = useRef(
      new Animated.Value(inputValue || autoFocus ? 1 : 0),
    ).current;

    const handle = useMemo(
      () => ({
        focus: () => {
          textInputRef.current?.focus();
        },
        blur: () => {
          textInputRef.current?.blur();
        },
      }),
      [textInputRef],
    );

    useImperativeHandle(ref, () => handle);

    useInputFocusAfterTransition(textInputRef, autoFocus);

    const handleChangeText = useCallback(
      (newText: string) => {
        if (value === undefined) {
          // avoid re-render
          setTextWhileEditing(newText);
        }
        onChangeText?.(newText);
      },
      [onChangeText, value],
    );

    useEffect(() => {
      if (isEditing) {
        setScrollEnabled(false);

        setTimeout(() => {
          setScrollEnabled(true);
        }, 500);
      }
    }, [isEditing]);

    /**
     * when editing, we ignore value prop changes and trust the input content
     * when not editing, we update the text according to the value prop
     */
    useEffect(() => {
      if (value !== undefined) {
        return;
      }

      if (!isEditing) {
        setTextWhileEditing(defaultValue);
        return;
      }

      if (textWhileEditing === defaultValue) {
        return;
      }

      onChangeText?.(textWhileEditing ?? "");
    }, [defaultValue, isEditing, onChangeText, textWhileEditing, value]);

    function handleFocus(event: NativeSyntheticEvent<TextInputFocusEventData>) {
      setIsEditing(true);

      Animated.timing(colorAnimation, {
        toValue: 1,
        duration: ANIMATION_TIME,
        easing: ANIMATION_EASING,
        ...getNativeDriver(false),
      }).start();

      onFocus?.(event);
    }

    function handleBlur(event: NativeSyntheticEvent<TextInputFocusEventData>) {
      setIsEditing(false);

      Animated.timing(colorAnimation, {
        toValue: 0,
        duration: ANIMATION_TIME,
        easing: ANIMATION_EASING,
        ...getNativeDriver(false),
      }).start();

      onBlur?.(event);
    }

    useEffect(() => {
      if (!isEditing && !inputValue) {
        Animated.timing(transformAnimation, {
          toValue: 0,
          duration: ANIMATION_TIME,
          easing: ANIMATION_EASING,
          ...getNativeDriver(true),
        }).start();
      } else {
        Animated.timing(transformAnimation, {
          toValue: 1,
          duration: ANIMATION_TIME,
          easing: ANIMATION_EASING,
          ...getNativeDriver(true),
        }).start();
      }
    }, [inputValue, isEditing, transformAnimation]);

    useEffect(() => {
      if (disableAutocomplete) {
        textInputRef.current?.setNativeProps({
          autoComplete: "off",
          "data-lpignore": true,
          "data-form-type": "other",
        });
      }
    }, [disableAutocomplete, inputRef]);

    function handleIconPressIn() {
      setWasEditingWhenIconPressedIn(isEditing);
    }

    function handleIconPress() {
      if (!wasEditingWhenIconPressedIn) {
        textInputRef.current?.focus();
      } else {
        dismissNativeKeyboard();
      }
    }

    function handleContainerPress() {
      textInputRef.current?.focus();
    }

    const showError = error || status === "erroneous";
    const inactiveBorderColor =
      (status === "validated" && Color.GREEN_LAGOON) ||
      (showError && ColorStyle.ACTION_DESCTRUCTIVE_DISABLED) ||
      ColorStyle.BACKGROUND_STANDARD;

    const helperTextColor =
      (status === "validated" && Color.GREEN_LAGOON) ||
      (showError && ColorStyle.ACTION_DESCTRUCTIVE) ||
      ColorStyle.FONT_LOW_EMPHASIS;

    const borderColor = colorAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [inactiveBorderColor, ColorStyle.ACTION_CTA],
    });

    const labelColor = colorAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [ColorStyle.FONT_LOW_EMPHASIS, Color.BLUE_SAVOY],
    });

    const fontSize = FontSize.BODY;
    const activeFontSize = 12;
    const y0 = 56 / 2 - fontSize / 2;
    const y1 = Platform.OS === "android" ? 6 : 10;

    const labelContainerStyle = {
      transform: [
        {
          scale: transformAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [1, activeFontSize / fontSize],
          }),
        },
        {
          translateY: transformAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [y0, y1],
          }),
        },
      ],
    };

    const labelStyle = {
      lineHeight: fontSize,
      fontSize: fontSize,
      color: labelColor,
    };

    const containerStyle = useMemo(
      () => [
        { borderColor },
        styles.borderedContainer,
        lightVariant && styles.lightContainer,
        !leftIcon ? styles.iconLessContainer : null,
        inputContainerStyle,
      ],
      [borderColor, lightVariant, leftIcon, inputContainerStyle],
    );

    const inputStyle = {
      paddingRight: showEditIcon ? Spacing.S8 : Spacing.S16,
    };

    const iconName: IconName = isEditing
      ? error
        ? "x-close"
        : "check"
      : "edit-02";
    const iconColor = isEditing
      ? error
        ? ColorStyle.FONT_LOW_EMPHASIS
        : Color.BLUE_AZURE
      : ColorStyle.FONT_LOW_EMPHASIS;

    const lineHeight = Platform.select({ default: 21, android: 23 });
    const height =
      multiline && numberOfLines ? lineHeight * numberOfLines : undefined;

    return (
      <View style={style}>
        <Pressable
          accessibilityLabel={label}
          nativeID={nativeID}
          style={styles.container}
          onPress={handleContainerPress}
        >
          {/** keep the container view for IE11 multiline */}
          <Animated.View style={containerStyle}>
            {leftIcon && (
              <TouchableOpacity
                accessibilityLabel={leftIcon.accessibilityLabel}
                disabled={!leftIcon.action}
                onPress={() => leftIcon.action?.()}
                style={styles.leftIconContainer}
              >
                <Icon
                  name={leftIcon.name}
                  size={36}
                  color={
                    leftIcon.color
                      ? leftIcon.color
                      : ColorStyle.FONT_LOW_EMPHASIS
                  }
                />
              </TouchableOpacity>
            )}
            <View style={styles.centerView}>
              <Animated.View
                style={[styles.labelContainer, labelContainerStyle]}
                pointerEvents="none"
              >
                <Animated.Text
                  style={[styles.labelText, labelStyle]}
                  numberOfLines={1}
                  {...(disableAccessibility && { allowFontScaling: false })}
                >
                  {label}
                </Animated.Text>
              </Animated.View>

              <View
                style={[
                  styles.textInputContainer,
                  multiline ? styles.textInputPaddingTop : undefined,
                ]}
              >
                <KTextInput
                  {...inputProps}
                  accessibilityLabel={label}
                  placeholder={isEditing ? placeholder : ""}
                  {...(nativeID ? { nativeID: `${nativeID}-input` } : {})}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChangeText={handleChangeText}
                  value={inputValue}
                  defaultValue={undefined}
                  wrapperStyle={styles.textInputWrapper}
                  style={[
                    styles.textInput,
                    inputStyle,
                    multiline ? undefined : styles.textInputPaddingTop,
                    { height },
                  ]}
                  textLineHeight={20}
                  editable={!disabled}
                  ref={textInputRef}
                  inputRef={inputRef}
                  scrollEnabled={isScrollEnabled}
                  {...(disableAccessibility && { allowFontScaling: false })}
                  maxHeight={height} // needed for web
                  {...(disableAutocomplete && {
                    autoComplete: "off",
                    "data-lpignore": true,
                    "data-form-type": "other",
                  })}
                  onSubmitEditing={onSubmitEditing}
                />
              </View>
            </View>
            {rightIcon ? (
              rightIcon
            ) : !disabled && showEditIcon ? (
              <TouchableOpacity
                accessibilityLabel={t("edit")}
                style={styles.rightIconContainer}
                onPress={handleIconPress}
                onPressIn={handleIconPressIn}
                delayPressIn={0}
              >
                <View
                  {...(nativeID ? { nativeID: `${nativeID}-edit-button` } : {})}
                >
                  <Icon name={iconName} size="MEDIUM" color={iconColor} />
                </View>
              </TouchableOpacity>
            ) : null}
          </Animated.View>
        </Pressable>
        {(withHelperTextPadding || helperText) && (
          <View style={styles.helperTextContainer}>
            {helperText ? (
              <Text
                style={[styles.helperText, { color: helperTextColor }]}
                numberOfLines={3}
              >
                {helperText}
              </Text>
            ) : null}
          </View>
        )}
      </View>
    );
  },
);

export const KInput = memo(KInput_);
