import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { InviteCollaboratorsAtPoolCreationInput } from "@kraaft/web/src/components/inviteCollaboratorsAtPoolCreation/inviteCollaboratorsAtPoolCreationInput";

export const InviteCollaboratorsStep = () => {
  const poolId = useSelector(selectCurrentPoolId);

  if (!poolId) {
    return null;
  }

  return <InviteCollaboratorsAtPoolCreationInput poolId={poolId} />;
};
