import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";
import pickBy from "lodash/pickBy";
import { Dictionary } from "ts-essentials";

import { getUserPoolRole } from "@kraaft/shared/core/modules/pool/poolUtil";
import { SchemaLockLookup } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { CheckboxColumnDef } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/checkbox";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KFolderSchema,
  KRoomSchema,
  KSchemaColumn,
  KSchemaColumnBase,
  SchemaElementWithPath,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { OfflineSchemaSelectors } from "@kraaft/shared/core/modules/schema/schema.offline";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { SelectColumnDefinition } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { RootState } from "@kraaft/shared/core/store";
import {
  getEmptyArray,
  lodashKeyResolver,
} from "@kraaft/shared/core/utils/utils";

import { maybeId } from "../../store/utils";

export const selectState = ({ schema }: RootState) => schema;

export const selectSchemasExcludingPool = memoize((poolId: string) =>
  createSelector(OfflineSchemaSelectors.selectAll, (schemas) =>
    pickBy(schemas, (schema) => schema.poolId !== poolId),
  ),
);

export const selectFolderSchemas = createSelector(
  OfflineSchemaSelectors.selectAll,
  (schemas) =>
    pickBy(
      schemas,
      (schema) => schema.collection === "folder",
    ) as Dictionary<KFolderSchema>,
);

export const selectRoomSchemas = createSelector(
  OfflineSchemaSelectors.selectAll,
  (schemas) => {
    return pickBy(
      schemas,
      (schema) => schema.collection === "room",
    ) as Dictionary<KRoomSchema>;
  },
);

export const selectRoomSchema = memoize((poolId: string | undefined) =>
  createSelector([selectRoomSchemas], (schemas) => {
    return Object.values(schemas).filter(
      (schema) => schema.poolId === poolId,
    )[0];
  }),
);

export const selectCreatedColumn = memoize((schemaId: string) =>
  createSelector(selectState, (state) =>
    state.createdColumnKey?.schemaId === schemaId
      ? state.createdColumnKey.columnKey
      : undefined,
  ),
);

export const selectRoomSchemaPillColumns = memoize(
  (poolId: string | undefined) =>
    createSelector(selectRoomSchema(poolId), (poolSchema) => {
      return poolSchema?.roomCardDisplayColumns;
    }),
);

export const selectRoomSchemaLabelsColumn = memoize(
  (poolId: string | undefined) =>
    createSelector(selectRoomSchema(poolId), (roomSchema) => {
      if (!roomSchema) {
        return undefined;
      }

      const labelsColumn = KSchemaUtils.findColumn(
        roomSchema.rootSection,
        KSchemaRemarkableColumns.ROOM_LABELS,
        KColumnType.selectMultiple,
      );

      return labelsColumn;
    }),
);

export const selectRoomSchemaStatusColumn = memoize(
  (poolId: string | undefined) =>
    createSelector(selectRoomSchema(poolId), (roomSchema) => {
      if (!roomSchema) {
        return undefined;
      }

      const statusColumn = KSchemaUtils.findColumn(
        roomSchema.rootSection,
        KSchemaRemarkableColumns.ROOM_STATUS,
        KColumnType.selectSingle,
      );

      return statusColumn;
    }),
);

const EMPTY_DICT: SelectColumnDefinition["options"] = {};

export const selectRoomSchemaLabelsOptions = memoize(
  (poolId: string | undefined) =>
    createSelector(
      selectRoomSchemaLabelsColumn(poolId),
      (column) => column?.options ?? EMPTY_DICT,
    ),
);

export const selectCheckboxSchema = memoize((poolId: string | undefined) =>
  createSelector([selectFolderSchemas], (schemas) => {
    return Object.values(schemas).find(
      (schema) =>
        schema.poolId === poolId &&
        schema.collection === "folder" &&
        schema.type === "checkbox",
    );
  }),
);

export const selectPoolFolderSchemas = memoize((poolId: string | undefined) =>
  createSelector(selectFolderSchemas, (schemas) => {
    const folderSchemas = Object.values(schemas)
      .filter((schema) => schema.poolId === poolId)
      .sort((a, b) => a.index - b.index);
    return folderSchemas;
  }),
);

export const selectPoolFolderSchemaCount = memoize(
  (poolId: string | undefined) =>
    createSelector(
      [selectPoolFolderSchemas(poolId)],
      (schemas) => schemas.length,
    ),
);

export const selectPoolFolderSchemasDict = memoize(
  (poolId: string | undefined) =>
    createSelector([selectFolderSchemas], (schemas) =>
      pickBy(schemas, (schema) => schema.poolId === poolId),
    ),
);

export const selectFolderSchema = memoize((schemaId: string | undefined) =>
  createSelector([selectFolderSchemas], (schemas) =>
    schemaId ? schemas[schemaId] : undefined,
  ),
);

export const selectSchema = memoize((schemaId: string | undefined) =>
  createSelector(
    [selectFolderSchemas, selectRoomSchemas],
    (folderSchemas, roomSchemas) =>
      schemaId
        ? (folderSchemas[schemaId] ?? roomSchemas[schemaId] ?? undefined)
        : undefined,
  ),
);

const EMPTY_SCHEMA_ELEMENT_WITH_PATH_DICT = {} as Record<
  string,
  SchemaElementWithPath
>;
export const selectSchemaElementsWithPath = memoize(
  (schemaId: string | undefined) =>
    createSelector(selectSchema(schemaId), (schema) => {
      if (schema === undefined) {
        return EMPTY_SCHEMA_ELEMENT_WITH_PATH_DICT;
      }

      return KSchemaUtils.flattenSectionWithPath(schema.rootSection);
    }),
);

export const selectIsSchemaModularFolderTitleAutomated = memoize(
  (schemaId: string | undefined) =>
    createSelector(selectSchema(schemaId), (schema) => {
      if (!schema) {
        return false;
      }
      const titleColumn = KSchemaUtils.findColumn(
        schema.rootSection,
        KSchemaRemarkableColumns.TITLE,
      );
      return titleColumn?.type === KColumnType.automatedAutoIncrement;
    }),
);

export const selectAreSchemasLoaded = memoize((poolId: string | undefined) =>
  createSelector(selectState, (state) =>
    poolId ? state.areSchemasLoadedByPoolId[poolId] : undefined,
  ),
);

export const selectSchemaName = memoize((schemaId: string | undefined) =>
  createSelector(selectSchema(schemaId), (schema) => {
    if (!schema) {
      return undefined;
    }
    return schema.name;
  }),
);

export const selectSchemaRootSection = memoize((schemaId: string) =>
  createSelector(selectSchema(schemaId), (schema) => {
    return schema?.rootSection;
  }),
);

export const selectSchemaHasGeolocationColumn = memoize((schemaId: string) =>
  createSelector(selectSchema(schemaId), (schema) => {
    if (!schema) {
      return false;
    }
    return KSchemaUtils.hasColumnOfType(schema, KColumnType.geolocation);
  }),
);

const selectUserRoleFromSchemaId = memoize((schemaId: string) =>
  createSelector(selectCurrentUser, selectSchema(schemaId), (user, schema) => {
    return getUserPoolRole(user, schema?.poolId);
  }),
);

const defaultSchemaLockLookup: SchemaLockLookup = {
  elementSectionKey: {},
  sections: {},
  isCurrentUserAuthorizedToUnlock: false,
};
export const selectSchemaLockLookup = memoize((schemaId: string) =>
  createSelector(
    selectUserRoleFromSchemaId(schemaId),
    selectSchema(schemaId),
    (role, schema) => {
      if (!schema) {
        return defaultSchemaLockLookup;
      }
      return (
        KSchemaUtils.computeSchemaLockLookup(schema.rootSection, role) ??
        defaultSchemaLockLookup
      );
    },
  ),
);

export const selectSchemaOrderedColumns = memoize((schemaId: string) =>
  createSelector(selectSchema(schemaId), (schema): Array<KSchemaColumn> => {
    if (!schema) {
      return getEmptyArray();
    }
    return KSchemaUtils.orderedColumns(schema.rootSection);
  }),
);

export const selectSchemaLoaded = memoize((schemaId: string | undefined) =>
  createSelector(selectSchema(schemaId), (schema) => {
    return Boolean(schema);
  }),
);

export const selectSchemaExists = memoize((schemaId: string) =>
  createSelector(
    selectSchema(schemaId),
    selectAreSchemasLoaded,
    (schema, loaded) => {
      if (!loaded) {
        return true;
      }
      if (!schema) {
        return false;
      }
      return true;
    },
  ),
);

export const selectSchemaFirstColumnOfType = memoize(
  (schemaId: string, columnType: KColumnType) =>
    createSelector(selectSchema(schemaId), (schema) => {
      return schema
        ? KSchemaUtils.getFirstColumnOfType(schema, columnType)
        : undefined;
    }),
  lodashKeyResolver,
);

export const selectSchemaFirstColumnKeyOfType = memoize(
  (schemaId: string, columnType: KColumnType) =>
    createSelector(
      selectSchemaFirstColumnOfType(schemaId, columnType),
      (column) => column?.key,
    ),
  lodashKeyResolver,
);

export const selectSchemaIcon = memoize((schemaId: string) =>
  createSelector(selectSchema(schemaId), (schema) => {
    return schema?.icon ?? "unchecked";
  }),
);

export const selectSchemaColumnsOfType = memoize(
  <C extends KColumnType>(schemaId: string, columnType: C) =>
    createSelector(
      selectSchema(schemaId),
      (schema): Array<KSchemaColumn<C>> => {
        return schema
          ? KSchemaUtils.getColumnsOfType(schema, columnType)
          : getEmptyArray();
      },
    ),
  lodashKeyResolver,
);

export const selectSchemaType = memoize((schemaId: string) =>
  createSelector(selectFolderSchema(schemaId), (schema) => {
    return schema?.type;
  }),
);

export const selectSchemaHighlightedColumn = memoize((schemaId: string) =>
  createSelector(
    selectFolderSchema(schemaId),
    (schema): (KSchemaColumnBase & CheckboxColumnDef) | undefined => {
      return schema?.highlightedCheckbox
        ? KSchemaUtils.getHiglightedCheckboxColumn(
            schema.rootSection,
            schema.highlightedCheckbox,
          )
        : undefined;
    },
  ),
);

export const selectSchemaHighlightedColumnKey = memoize((schemaId: string) =>
  createSelector(
    selectSchemaHighlightedColumn(schemaId),
    (column) => column?.key,
  ),
);

export const selectRoomSchemaId = memoize((poolId: string | undefined) =>
  createSelector(selectRoomSchema(poolId), (schema) => schema?.id),
);

export const selectSchemaCollection = memoize((schemaId: string) =>
  createSelector(selectSchema(schemaId), (schema) => schema?.collection),
);

export const selectOfflineModularFolderSchema = maybeId((schemaId: string) =>
  createSelector(OfflineSchemaSelectors.select(schemaId), (schema) => schema),
);
