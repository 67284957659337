import { ReactNode, useMemo, useState } from "react";

import { ModalContextType } from "@kraaft/shared/components/modalContext/modalContext";

interface FrameProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

interface ModalState<Props> {
  key: string | undefined;
  element: ReactNode;
  options: Props | undefined;
}

export function withModalFrame<Props>(
  FrameComponent: React.ComponentType<Props & FrameProps>,
  Context: React.Context<ModalContextType<Props>>,
): (props: { children: ReactNode }) => JSX.Element {
  return ({ children }) => {
    const [content, setContent] = useState<ModalState<Props>>({
      key: undefined,
      element: null,
      options: undefined,
    });

    const commands = useMemo(
      () => ({
        open: (key: string, element: ReactNode, options: Props) => {
          setContent({ key, element, options });
        },
        close: (key: string) => {
          setContent((state) => {
            if (state.key === key) {
              return { ...state, key: undefined };
            }
            return state;
          });
        },
        closeCurrent: () => {
          setContent((state) => {
            return { ...state, key: undefined };
          });
        },
      }),
      [],
    );

    const isModalOpen = content.key !== undefined;
    const element = content?.element;

    return (
      <Context.Provider value={commands}>
        {children}
        {element && (
          <FrameComponent
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            {...content.options!}
            open={isModalOpen}
            onClose={commands.closeCurrent}
          >
            {element}
          </FrameComponent>
        )}
      </Context.Provider>
    );
  };
}
