import { StyleSheet } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const MAX_WIDTH = isNative() ? "100%" : "60%";

export const nativeStylesObject = ensureValidStyleSheet({
  messageSelectionContainer: {
    flexDirection: "row",
  },

  messageContentContainer: {
    paddingHorizontal: !isNative() ? Spacing.S32 : Spacing.S16,
    marginTop: 8,
    marginBottom: 6,
  },
  messageSelectionContent: {
    flex: 1,
  },
  messageSelectionContentContainer: {
    flex: 1,
  },

  messageContentDefault: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  messageContentReply: {
    flexDirection: "row",
  },

  messageBody: {
    flex: 1,
  },
  messageBodyDefault: {
    alignItems: "flex-end",
  },
  messageBodyReply: {
    alignItems: "flex-start",
  },

  messageBox: {
    flexShrink: 1,
  },
  message: {
    borderRadius: 17,
    position: "relative",
    maxWidth: "100%",
    borderColor: Color.TRANSPARENT,
    overflow: "hidden",
  },
  messageDefault: {
    borderTopRightRadius: 2,
    backgroundColor: Color.BLUE_SAVOY,
  },

  text: {
    padding: Spacing.S12,
  },
  textDefault: {
    color: Color.WHITE,
    fontFamily: "Apercu",
  },
  textReply: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },

  modifiedReply: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  modifiedDefault: {
    color: Color.BLUE_LAVENDER,
  },

  selected: {
    backgroundColor: ColorStyle.ACTION_SELECTED,
  },
  hovered: {
    backgroundColor: ColorStyle.ACTION_HOVERED,
  },

  flag: {
    width: 20,
    height: 20,
  },
  selectButtonContainer: {
    paddingHorizontal: Spacing.S8,
    justifyContent: "center",
  },

  receiptWrap: {
    alignItems: "flex-end",
    flexDirection: "row-reverse",
    marginTop: 4,
  },
  receiptText: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.SMALL,
    flexShrink: 1,
  },
  receiptIcon: {
    marginBottom: -2,
  },
  date: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: FontSize.SMALL,
    marginTop: 4,
  },
  textNoUriStatus: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
    fontSize: FontSize.SMALL,
  },
  messageSpacer: {
    marginTop: 3,
  },
  messageReply: {
    borderTopLeftRadius: 2,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },

  modulesContainer: {
    flexDirection: "column",
  },

  footerReply: {
    alignItems: "flex-start",
  },
  footerSelf: {
    alignItems: "flex-end",
  },
});

export const styles = StyleSheet.create(nativeStylesObject);
