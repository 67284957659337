import { useCallback, useImperativeHandle, useRef, useState } from "react";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
  useControls,
  useTransformEffect,
} from "react-zoom-pan-pinch";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { useBooleanState } from "@kraaft/helper-hooks";
import { ImageManipulationProps } from "@kraaft/shared/components/carousel/placeholders/imageManipulation/imageManipulation.props";
import { DeleteToolbar } from "@kraaft/shared/components/carousel/placeholders/imageManipulation/web/deleteToolbar";
import { DownloadToolbar } from "@kraaft/shared/components/carousel/placeholders/imageManipulation/web/downloadToolbar";
import { RotateToolbar } from "@kraaft/shared/components/carousel/placeholders/imageManipulation/web/rotateToolbar";
import { IdentifiableElements, Spacing, stopPropagation } from "@kraaft/ui";

import { ZoomToolbar } from "./zoomToolbar";

export const findClosest = <T extends Array<number> | ReadonlyArray<number>>(
  haystack: T,
  needle: number,
): T[number] =>
  haystack.reduce((a, b) => {
    return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
  });

const MIN_ZOOM_SCALE = 0.1;
const MAX_ZOOM_SCALE = 10;
const DEFAULT_ZOOM_SCALE = 1;
const CLICK_ZOOM_STEPS = [0.1, 0.25, 0.5, 0.75, 1, 1.5, 3, 5, 7.5, 10];

interface WrappedImageManipulationProps extends ImageManipulationProps {
  isPanning: boolean;
}

export const WrappedImageManipulation = ({
  children,
  onDelete,
  isPanning,
  handle,
  name,
  path,
  mediaSize,
}: WrappedImageManipulationProps) => {
  const [currentScale, setCurrentScale] = useState(1);
  const [rotationValue, setRotationValue] = useState(0);

  const { zoomIn, zoomOut, resetTransform } = useControls();

  const classes = useStyles();

  useImperativeHandle(
    handle,
    useCallback(
      () => ({
        reset() {
          setCurrentScale(1);
          setRotationValue(0);
        },
      }),
      [],
    ),
  );

  const zoom = useCallback(
    (direction: "in" | "out") => {
      let scaleOffset = 0;
      let targetMilestoneIndex = 0;

      const closestMilestone = findClosest(CLICK_ZOOM_STEPS, currentScale);
      const closestMilestoneIndex = CLICK_ZOOM_STEPS.indexOf(closestMilestone);

      if (direction === "in") {
        // Zoom In
        if (currentScale >= closestMilestone) {
          targetMilestoneIndex = closestMilestoneIndex + 1;
        } else if (currentScale < closestMilestone) {
          targetMilestoneIndex = closestMilestoneIndex;
        }
        const targetMilestone = CLICK_ZOOM_STEPS[targetMilestoneIndex];
        if (targetMilestone === undefined) {
          return;
        }
        scaleOffset = targetMilestone - currentScale;

        zoomIn(scaleOffset, 100);
      } else {
        // Zoom Out
        if (currentScale > closestMilestone) {
          targetMilestoneIndex = closestMilestoneIndex;
        } else if (currentScale <= closestMilestone) {
          targetMilestoneIndex = closestMilestoneIndex - 1;
        }
        const targetMilestone = CLICK_ZOOM_STEPS[targetMilestoneIndex];
        if (targetMilestone === undefined) {
          return;
        }
        scaleOffset = currentScale - targetMilestone;

        zoomOut(scaleOffset, 100);
      }
    },
    [currentScale, zoomIn, zoomOut],
  );

  useTransformEffect(({ state }) => {
    setCurrentScale(state.scale);
  });

  const handleZoomIn = useCallback(() => {
    zoom("in");
  }, [zoom]);

  const handleZoomOut = useCallback(() => {
    zoom("out");
  }, [zoom]);

  const handleZoomReset = useCallback(() => {
    resetTransform();
  }, [resetTransform]);

  const aspectRatio =
    mediaSize !== undefined ? mediaSize.width / mediaSize.height : undefined;

  return (
    <>
      <div
        className={clsx(
          IdentifiableElements.IMAGE_MANIPULATION,
          classes.container,
        )}
      >
        <TransformComponent
          wrapperClass={clsx(classes.canvasWrapper, "panningDisabled")}
          contentClass={classes.canvasContent}
        >
          <div
            className={clsx(
              classes.wrapper,
              aspectRatio === undefined && classes.wrapperGrow,
            )}
            onClick={stopPropagation}
            style={{
              transform: `rotate(${rotationValue}deg)`,
              cursor: isPanning ? "grabbing" : "grab",
              aspectRatio,
            }}
          >
            {children}
          </div>
        </TransformComponent>
      </div>

      <div className={classes.toolbarContainer}>
        {onDelete && <DeleteToolbar onDelete={onDelete} />}
        <DownloadToolbar filename={name} path={path} />
        <RotateToolbar setRotationValue={setRotationValue} />
        <span className={classes.toolbarWithSpacer}>
          <ZoomToolbar
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onResetZoomScale={handleZoomReset}
            zoomScale={currentScale}
          />
        </span>
      </div>
    </>
  );
};

export const ImageManipulation = (props: ImageManipulationProps) => {
  const canvasRef = useRef<ReactZoomPanPinchRef>(null);
  const [isPanning, setPanning, setNotPanning] = useBooleanState();

  return (
    <TransformWrapper
      ref={canvasRef}
      centerOnInit
      wheel={{ step: 0.03 }}
      pinch={{ step: 0.03 }}
      minScale={MIN_ZOOM_SCALE}
      maxScale={MAX_ZOOM_SCALE}
      initialScale={DEFAULT_ZOOM_SCALE}
      smooth={false}
      onPanningStart={setPanning}
      onPanningStop={setNotPanning}
      panning={{ excluded: ["panningDisabled"] }}
    >
      <WrappedImageManipulation {...props} isPanning={isPanning} />
    </TransformWrapper>
  );
};

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
  },
  toolbarContainer: {
    display: "flex",
    justifyContent: "center",
    gap: Spacing.S16,
    marginTop: Spacing.S16,
  },
  toolbarWithSpacer: {
    marginLeft: Spacing.S32,
  },
  canvasWrapper: {
    display: "flex",
    flexGrow: 1,
    height: "100%",

    pointerEvents: "none",
  },
  canvasContent: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,

    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
  wrapper: {
    display: "flex",
    width: "100%",
    maxHeight: "100%",

    pointerEvents: "all",
    "& *": {
      pointerEvents: "none",
    },

    transition: "transform .25s ease-in-out",
  },
  wrapperGrow: {
    flexGrow: 1,
  },
});
