import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const renameSchemaLibraryTagOperation =
  EditOneOperationBuilder.create<SchemaLibraryTag>()
    .payload<{
      name: string;
      language: LibrarySchemaLanguage;
    }>()
    .expected((tag, payload) => {
      tag.name = payload.name;
    })
    .mutate(async ({ id, language, name }) => {
      const { updatedAt } = await Api.renameSchemaLibraryTag({
        id,
        language,
        name,
      });
      return { updatedAt };
    });
