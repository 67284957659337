import moment from "moment";

import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, Text, View } from "react-native";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { useHover } from "@kraaft/helper-hooks";
import { Checkbox } from "@kraaft/shared/components/checkbox";
import { FileCardIcon } from "@kraaft/shared/components/fileCard/fileCardIcon";
import { MoreDirectories } from "@kraaft/shared/components/fileCard/moreDirectories";
import { useFileCardOnPress } from "@kraaft/shared/components/fileCard/useFileCardOnPress";
import { Pill } from "@kraaft/shared/components/pill";
import { selectDirectoriesOfMessage } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { selectIsLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { selectMessageSelection } from "@kraaft/shared/core/modules/message/messageSelectors";
import { MessageSelectionSource } from "@kraaft/shared/core/modules/message/messageState";
import { generateOpenMiniMediaLoaderId } from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { OpenDocumentSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { ColorStyle } from "@kraaft/ui";

import { styles } from "./fileCard.styles";

interface FileCardProps {
  media: MiniMedia;
  roomId: string;
  directoryId?: string;
  onSelect?: (messageIds: string[], status: boolean) => void;
  selectionSource: MessageSelectionSource;
  forceSelection?: boolean;
  hideDirectories?: boolean;
  disabled?: boolean;
  trackingSource: OpenDocumentSource;
}

const CHECKBOX_SIZE = 32;

const FileCard = ({
  media,
  roomId,
  directoryId,
  onSelect,
  selectionSource,
  forceSelection,
  hideDirectories,
  disabled,
  trackingSource,
}: FileCardProps) => {
  const { t } = useTranslation();

  const isLoading = useSelector(
    selectIsLoading(generateOpenMiniMediaLoaderId(roomId, media.messageId)),
  );
  const directories = useSelector(selectDirectoriesOfMessage(media.messageId));
  const rootRef = useRef(null);
  const dateAsString = useMemo(
    () => moment(media.createdAt).format("DD/MM/YYYY"),
    [media.createdAt],
  );

  const { selection } = useSelector(
    selectMessageSelection(roomId, selectionSource),
  );
  const selected = selection[media.messageId] !== undefined;

  const handleSelect = useCallback(() => {
    onSelect?.([media.messageId], !selected);
  }, [media.messageId, onSelect, selected]);

  const { onLongPress, onPress } = useFileCardOnPress(
    media,
    directoryId,
    selectionSource,
    forceSelection ?? false,
    handleSelect,
    trackingSource,
  );

  const hover = useHover(rootRef);
  const showCheckbox = selected || (hover && !isNative()) || forceSelection;

  const checkboxStyles = useMemo(
    () => [
      {
        opacity: showCheckbox ? 1 : 0,
      },
    ],
    [showCheckbox],
  );

  return (
    <Pressable
      accessibilityRole="button"
      accessibilityLabel="file-card"
      disabled={disabled || isLoading}
      ref={rootRef}
      style={styles.root}
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <View style={styles.left}>
        <FileCardIcon isLoading={isLoading} media={media} />
      </View>
      <View style={styles.middle}>
        <Text
          accessibilityLabel={media.name}
          style={styles.title}
          numberOfLines={1}
          ellipsizeMode="middle"
        >
          {media.name}
        </Text>
        <View style={styles.pills}>
          <Pill
            style={styles.pill}
            content={dateAsString}
            icon="calendar"
            size="small"
            color={ColorStyle.BACKGROUND_STANDARD}
          />
          {!hideDirectories && (
            <MoreDirectories roomId={media.roomId} directories={directories} />
          )}
        </View>
      </View>
      <View
        style={checkboxStyles}
        pointerEvents={showCheckbox ? "auto" : "none"}
      >
        <Checkbox
          onPress={handleSelect}
          checked={selected}
          greyedNotSelected
          size={CHECKBOX_SIZE}
          disabled={disabled}
        />
      </View>
    </Pressable>
  );
};

export { FileCard };
