import { useCallback } from "react";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";

import { CreateOrJoinPoolSheet } from "./createOrJoinPoolSheet";

interface UseCreateOrJoinPoolSheetProps {
  closePoolSelectionMenu: () => void;
  openPoolCreationFlow: () => void;
}

export function useCreateOrJoinPoolSheet({
  closePoolSelectionMenu,
  openPoolCreationFlow,
}: UseCreateOrJoinPoolSheetProps) {
  const {
    element: createOrJoinPoolSheetElement,
    open: openCreateOrJoinPoolSheet,
    close: closeCreateOrJoinPoolSheet,
  } = CreateOrJoinPoolSheet.use({});

  const handleJoinPoolPress = useCallback(() => {
    closeCreateOrJoinPoolSheet();

    setTimeout(() => {
      intercom
        .openArticle(getEnvironment().INTERCOM.ARTICLES.JOIN_A_WORKSPACE)
        .catch(console.error);
    }, 150);
  }, [closeCreateOrJoinPoolSheet]);

  const handleCreatePoolPress = useCallback(() => {
    closeCreateOrJoinPoolSheet();
    openPoolCreationFlow();
  }, [closeCreateOrJoinPoolSheet, openPoolCreationFlow]);

  const openCreateOrJoinPool = useCallback(() => {
    closePoolSelectionMenu();
    openCreateOrJoinPoolSheet({
      onJoinPress: handleJoinPoolPress,
      onCreatePress: handleCreatePoolPress,
    });
  }, [
    closePoolSelectionMenu,
    openCreateOrJoinPoolSheet,
    handleJoinPoolPress,
    handleCreatePoolPress,
  ]);

  return {
    openCreateOrJoinPool,
    createOrJoinPoolSheetElement,
  };
}
