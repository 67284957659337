import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button, ColorStyle } from "@kraaft/ui";
import { OrderableOption } from "@kraaft/web/src/components/sortableListWithAddAndDelete/orderableOptionRenderer/orderableOption";

import { OrderableOptionInput } from "./orderableOptionInput";

import { useStyles } from "./orderableOptionRenderer.styles";

interface Props {
  option: OrderableOption;
  onDelete: (option: OrderableOption) => void;
  onPressEnter: (id: string) => void;
  onChange: (id: string, value: OrderableOption) => void;
}

export const OrderableOptionRenderer = ({
  option,
  onDelete,
  onPressEnter,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleDelete = useCallback(() => {
    onDelete(option);
  }, [onDelete, option]);

  return (
    <div className={classes.option}>
      <div className={classes.labelContainer}>
        <OrderableOptionInput
          option={option}
          onPressEnter={onPressEnter}
          onChange={onChange}
        />
      </div>
      <Button
        accessibilityLabel={t("del")}
        icon="trash-03"
        contentColor={ColorStyle.FONT_HIGH_EMPHASIS}
        variant="QUATERNARY"
        onPress={handleDelete}
      />
    </div>
  );
};
