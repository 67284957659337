import { useMeshContext } from "@kraaft/helper-hooks";
import { AskPoolCompanySizeForm } from "@kraaft/shared/components/onboarding/askPoolCompanySize/askPoolCompanySizeForm";

import { CreatePoolFlowContext } from "../createPoolFlow.context";

export const CompanySizeStep = () => {
  const { setCompanySize, companySize } = useMeshContext(CreatePoolFlowContext);

  return (
    <AskPoolCompanySizeForm
      setSelected={setCompanySize}
      selected={companySize}
    />
  );
};
