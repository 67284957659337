import { StyleSheet } from "react-native";

import { authScreensTokens } from "@kraaft/shared/components/auth/authScreenTokens";
import { Color, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Color.WHITE,
  },

  title: {
    marginBottom: authScreensTokens.marginBelowTitleAndSubtitle,
  },

  buttonSpacer: {
    height: Spacing.S40,
    flexGrow: 1,
    flexShrink: 1,
  },
});
