import CryptoES from "crypto-es";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { Intercom } from "@kraaft/shared/core/modules/intercom/intercom.types";
import { generateHMACSha256 } from "@kraaft/shared/core/utils/hashUtils";

declare global {
  interface Window {
    Intercom: (...args: any) => any;
  }
}

const INTERCOM_API_BASE = "https://api-iam.eu.intercom.io";

export class ApiIntercom implements Intercom {
  async initializeForUser({
    userId,
  }: {
    userId: string;
  }): Promise<void> {
    const userHash = generateHMACSha256(
      userId,
      getEnvironment().INTERCOM.ID_VERIFICATION_KEY_WEB,
      CryptoES.enc.Hex,
    );
    window.Intercom("boot", {
      user_id: userId,
      user_hash: userHash,

      app_id: getEnvironment().INTERCOM.APP_ID,
      api_base: INTERCOM_API_BASE,
      hide_default_launcher: true,
    });
  }

  async updateUserAuth({
    email,
    phone,
  }: {
    email?: string;
    phone?: string;
  }): Promise<void> {
    window.Intercom("update", {
      email,
      phone,
    });
  }

  async updateUser({
    username,
    firstName,
    lastName,
  }: {
    username: string;
    firstName: string;
    lastName: string;
  }): Promise<void> {
    window.Intercom("update", {
      name: username,
      custom_attributes: {
        firstName,
        lastName,
      },
    });
  }

  async resetSession(): Promise<void> {
    window.Intercom("shutdown");
  }

  async openChat(): Promise<void> {
    window.Intercom("show");
  }

  async openChatWithMessage(initialMessage: string): Promise<void> {
    window.Intercom("showNewMessage", initialMessage);
  }

  async openArticle(articleId: number): Promise<void> {
    window.Intercom("showArticle", articleId);
  }
}
