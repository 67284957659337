import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { dismissNativeKeyboard } from "@kraaft/helper-functions";
import { useMeshContext } from "@kraaft/helper-hooks";
import { OTHER_COMPANY_INDUSTRY_IDENTIFIER } from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry/askPoolCompanyIndustryForm";
import { OTHER_USER_JOB_IDENTIFIER } from "@kraaft/shared/components/onboarding/askUserJob/askUserJobForm";
import { Stepper } from "@kraaft/shared/components/stepper";
import { clearLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { UserJobStep } from "@kraaft/shared/core/modules/pool/components/addPool/createPoolFlow/steps/userJobStep";
import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { CREATE_POOL_FROM_POOL_LOADER_ID } from "@kraaft/shared/core/modules/pool/sagas/createPoolFromPool.constants";
import { ButtonProps } from "@kraaft/ui";

import { CompanyIndustryStep } from "./steps/companyIndustryStep";
import { CompanySizeStep } from "./steps/companySizeStep";
import { CreationLoadingStep } from "./steps/creationLoadingStep";
import { CreationReasonStep } from "./steps/creationReasonStep";
import { InviteCollaboratorsStep } from "./steps/inviteCollaboratorsStep";
import { TeamNameStep } from "./steps/teamNameStep";
import { CreatePoolFlowContext } from "./createPoolFlow.context";

interface CreatePoolFlowStep {
  stepTitle?: string;
  stepContent: React.ReactNode;
  stepper?: React.ReactNode;
  shouldPreventClose?: boolean;
  hideCancelButton?: boolean;
  nextStepButton?: ButtonProps;
}

interface UseCreatePoolFlowProps {
  closeHost: () => void;
}

export function useCreatePoolFlow({ closeHost }: UseCreatePoolFlowProps): {
  stepId: keyof typeof steps;
  stepElements: CreatePoolFlowStep;
} {
  const { t } = useTranslation();
  const [currentStepId, setCurrentStepId] =
    useState<keyof typeof steps>("team-name");
  const dispatch = useDispatch();

  const loader = useSelector(selectLoader(CREATE_POOL_FROM_POOL_LOADER_ID));

  const {
    isSubmitPoolNameDisabled,
    isSubmitCompanyIndustryDisabled,
    isSubmitCompanySizeDisabled,
    isSubmitCreationReasonDisabled,
    creationReason,
    poolName,
    companyIndustry,
    companySize,
    otherCompanyIndustry,
    userJob,
    otherUserJob,
    isSubmitUserJobDisabled,
  } = useMeshContext(CreatePoolFlowContext);

  const handleTeamNameSubmit = useCallback(() => {
    if (isSubmitPoolNameDisabled) {
      return;
    }

    dismissNativeKeyboard();
    setCurrentStepId("user-job");
  }, [isSubmitPoolNameDisabled]);

  const handleFinalSubmit = useCallback(() => {
    if (isSubmitCreationReasonDisabled) {
      return;
    }
    dismissNativeKeyboard();
    setCurrentStepId("creation-loading");
    dispatch(
      PoolActions.createPoolFromPool({
        poolCreationReason: creationReason,
        name: poolName,
        userJob: userJob !== OTHER_USER_JOB_IDENTIFIER ? userJob : otherUserJob,
        companySize,
        companyIndustry:
          companyIndustry !== OTHER_COMPANY_INDUSTRY_IDENTIFIER
            ? companyIndustry
            : otherCompanyIndustry,
      }),
    );
  }, [
    companyIndustry,
    companySize,
    creationReason,
    dispatch,
    isSubmitCreationReasonDisabled,
    otherCompanyIndustry,
    otherUserJob,
    poolName,
    userJob,
  ]);

  useEffect(() => {
    dispatch(clearLoader(CREATE_POOL_FROM_POOL_LOADER_ID));
  }, [dispatch]);

  useEffect(() => {
    switch (loader?.status) {
      case LoaderStatus.FAILURE: {
        setCurrentStepId("team-name");
        break;
      }
      case LoaderStatus.SUCCESS: {
        setCurrentStepId("invite-collaborators");
        dispatch(clearLoader(CREATE_POOL_FROM_POOL_LOADER_ID));
        break;
      }
      default:
    }
  }, [dispatch, loader?.status]);

  const steps = useMemo(
    () =>
      ({
        "team-name": {
          stepTitle: t("newWorkspace.title"),
          stepContent: <TeamNameStep submit={handleTeamNameSubmit} />,
          nextStepButton: {
            disabled: isSubmitPoolNameDisabled,
            accessibilityLabel: t("next"),
            text: t("next"),
            onPress: handleTeamNameSubmit,
          },
        },
        "user-job": {
          stepTitle: t("userJob.whatIsYourJob"),
          stepper: <Stepper steps={4} selected={0} />,
          stepContent: <UserJobStep />,
          nextStepButton: {
            disabled: isSubmitUserJobDisabled,
            accessibilityLabel: t("next"),
            text: t("next"),
            onPress: () => {
              dismissNativeKeyboard();
              setCurrentStepId("company-industry");
            },
          },
        },
        "company-industry": {
          stepTitle: t("poolCompanyIndustry.whatIsYourCompanyIndustry"),
          stepper: <Stepper steps={4} selected={1} />,
          stepContent: <CompanyIndustryStep />,
          nextStepButton: {
            disabled: isSubmitCompanyIndustryDisabled,
            accessibilityLabel: t("next"),
            text: t("next"),
            onPress: () => {
              dismissNativeKeyboard();
              setCurrentStepId("company-size");
            },
          },
        },
        "company-size": {
          stepTitle: t("poolCompanySize.whatIsYourCompanySize"),
          stepper: <Stepper steps={4} selected={2} />,
          stepContent: <CompanySizeStep />,
          nextStepButton: {
            disabled: isSubmitCompanySizeDisabled,
            accessibilityLabel: t("next"),
            text: t("next"),
            onPress: () => setCurrentStepId("creation-reason"),
          },
        },
        "creation-reason": {
          stepTitle: t("addPool.reason.title"),
          stepper: <Stepper steps={4} selected={3} />,
          stepContent: <CreationReasonStep />,
          nextStepButton: {
            disabled: isSubmitCreationReasonDisabled,
            accessibilityLabel: t("next"),
            text: t("next"),
            onPress: handleFinalSubmit,
          },
        },
        "creation-loading": {
          stepContent: <CreationLoadingStep />,
          shouldPreventClose: true,
          hideCancelButton: true,
        },
        "invite-collaborators": {
          stepContent: <InviteCollaboratorsStep />,
          stepTitle: t("inviteCollaboratorsAtPoolCreation.title"),
          hideCancelButton: true,
          nextStepButton: {
            accessibilityLabel: t("finished"),
            text: t("finished"),
            onPress: () => {
              dismissNativeKeyboard();
              closeHost();
            },
          },
        },
      }) as const satisfies Record<string, CreatePoolFlowStep>,
    [
      t,
      handleTeamNameSubmit,
      isSubmitPoolNameDisabled,
      isSubmitCompanyIndustryDisabled,
      isSubmitUserJobDisabled,
      isSubmitCompanySizeDisabled,
      isSubmitCreationReasonDisabled,
      handleFinalSubmit,
      closeHost,
    ],
  );

  return useMemo(
    () => ({
      stepId: currentStepId,
      stepElements: steps[currentStepId],
    }),
    [currentStepId, steps],
  );
}
