import moment from "moment";

import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";

import { styles } from "@kraaft/shared/components/attachmentList/attachmentList.styles";
import { buildUserAcronym } from "@kraaft/shared/components/conversation/header/conversationTitleUtils";
import {
  Attachment,
  DocumentAttachment,
  ImageAttachment,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getBasename, getExtension } from "@kraaft/shared/core/utils";
import { Icon, IconName, Image } from "@kraaft/ui";

export interface AttachmentListContext {
  folderTitle: string;
  roomTitle: string;
  users: { [userId: string]: User };
  index?: number;
}

export const ImageThumbnail = ({
  attachment,
}: { attachment: ImageAttachment }) => {
  return (
    <Image
      style={styles.baseThumbnail}
      preferredSize="thumbnail"
      contentFit="cover"
      source={attachment.original.downloadUrl}
    />
  );
};

export const AttachmentThumbnail = ({
  attachment,
}: { attachment: Attachment }) => {
  return (
    <View style={[styles.baseThumbnail, styles.documentThumbnail]}>
      <Icon
        name={getIconFromAttachmentType(attachment)}
        size="MEDIUM"
        color="ACTION_CTA"
      />
    </View>
  );
};

const getIconFromAttachmentType = (attachment: Attachment): IconName => {
  switch (attachment.type) {
    case "image":
      return "image-03";
    case "video":
      return "play";
    case "audio":
      return "microphone-01";
    default:
      return "file-04";
  }
};

export const DocumentDeleter = ({ onDelete }: { onDelete: () => void }) => {
  const { t } = useTranslation();

  return (
    <TouchableOpacity accessibilityLabel={t("del")} onPress={onDelete}>
      <Icon name="trash-03" size="MEDIUM" color="ACTION_DESCTRUCTIVE" />
    </TouchableOpacity>
  );
};

export const extractImageTitle =
  (context: AttachmentListContext) => (attachment: Attachment) => {
    const { folderTitle, roomTitle, users, index } = context;

    const momentDate = moment(attachment.createdAt);
    const date = momentDate.format("YYYYMMDD");
    const hours = momentDate.format("HH");
    const minutes = momentDate.format("mm");

    const userAcronym =
      buildUserAcronym(users[attachment.senderUserId]) ??
      i18n.t("userNonRecognized");

    return `${folderTitle}-${date}-${hours}h-${minutes}m-${userAcronym}-${roomTitle}-${i18n.t(
      "numeroSign",
    )}${index}`;
  };

export const extractDocumentTitle =
  (context: AttachmentListContext) => (attachment: Attachment) => {
    const { roomTitle, users, index } = context;

    const momentDate = moment(attachment.createdAt);
    const date = momentDate.format("YYYYMMDD");
    const hours = momentDate.format("HH");
    const minutes = momentDate.format("mm");

    const filenameClean = getBasename(
      (attachment as DocumentAttachment).original.filename,
    );

    const fileExtension = getExtension(
      (attachment as DocumentAttachment).original.filename,
    );

    const userAcronym =
      buildUserAcronym(users[attachment.senderUserId]) ??
      i18n.t("userNonRecognized");

    return `${filenameClean}-${date}-${hours}h-${minutes}m-${userAcronym}-${roomTitle}-${i18n.t(
      "numeroSign",
    )}${index}.${fileExtension}`;
  };
