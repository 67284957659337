import { takeEvery } from "typed-redux-saga/macro";

import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import { UserStateActions } from "@kraaft/shared/core/modules/user/userActions";

export function* updateUserSaga() {
  yield* takeEvery(UserStateActions.loggedUserReceived, onLoggedUserReceived);
}

function* onLoggedUserReceived({
  payload: { username, firstName, lastName },
}: ReturnType<typeof UserStateActions.loggedUserReceived>) {
  if (!username) {
    return;
  }

  intercom
    .updateUser({
      username,
      firstName: firstName ?? "",
      lastName: lastName ?? "",
    })
    .catch(console.error);
}
