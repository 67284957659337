import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { useVoidOnPress } from "@kraaft/helper-hooks";
import { useCreatePoolFlow } from "@kraaft/shared/core/modules/pool/components/addPool/createPoolFlow/useCreatePoolFlow";
import { Button, Sheet, Spacing } from "@kraaft/ui";
import { PopupSheetDefinition } from "@kraaft/ui/src/layout/sheet/popupSheet/popupSheet.definition/popupSheet.definition.types";
import { Transition } from "@kraaft/web/src/components/animated/transition";

import { CreatePoolFlowContextProvider } from "./createPoolFlow/createPoolFlow.context";

export const CreatePoolFlowSheet = Sheet({ web: "popup" })
  .create((sheetDefinition) => ({ onClose, setPreventClose }) => {
    return (
      <CreatePoolFlowContextProvider>
        <CreatePoolFlowSheetContent
          {...sheetDefinition}
          onClose={onClose}
          setPreventClose={setPreventClose}
        />
      </CreatePoolFlowContextProvider>
    );
  })
  .withDefaults({ size: "SMALL" });

interface CreatePoolFlowSheetContentProps
  extends Omit<PopupSheetDefinition, "Host"> {
  onClose: (params?: { bypassPreventClose: boolean }) => void;
  setPreventClose: (value: boolean) => void;
}

export const CreatePoolFlowSheetContent = ({
  Paper,
  Content,
  Header,
  Footer,
  onClose,
  setPreventClose,
}: CreatePoolFlowSheetContentProps) => {
  const {
    stepId,
    stepElements: {
      stepTitle,
      stepContent,
      stepper,
      nextStepButton,
      shouldPreventClose,
      hideCancelButton,
    },
  } = useCreatePoolFlow({ closeHost: onClose });

  const { t } = useTranslation();

  const styles = useStyles();

  useEffect(() => {
    setPreventClose(Boolean(shouldPreventClose));
  }, [setPreventClose, shouldPreventClose]);

  const handleClose = useVoidOnPress(onClose);

  return (
    <Paper>
      {!shouldPreventClose && (
        <Header onClose={handleClose}>{stepTitle}</Header>
      )}
      <Content>
        {stepper && <div className={styles.stepperContainer}>{stepper}</div>}
        <Transition identifier={stepId}>{stepContent}</Transition>
      </Content>
      <Footer>
        {!hideCancelButton && (
          <Button
            variant="QUATERNARY"
            onPress={handleClose}
            text={t("cancel")}
          />
        )}
        {nextStepButton && <Button {...nextStepButton} />}
      </Footer>
    </Paper>
  );
};

const useStyles = makeStyles({
  stepperContainer: {
    paddingTop: Spacing.S8,
    paddingBottom: Spacing.S16,
  },
});
