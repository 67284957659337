import { t } from "i18next";
import { all, call, put, select } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { PoolRoleType } from "@kraaft/shared/core/modules/pool/poolState";
import {
  CREATE_POOL_FROM_POOL_FAKE_LOADING_MS,
  CREATE_POOL_FROM_POOL_LOADER_ID,
} from "@kraaft/shared/core/modules/pool/sagas/createPoolFromPool.constants";
import { Api } from "@kraaft/shared/core/services/api";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";
import { wait } from "@kraaft/shared/core/utils/promiseUtils";

export function* createPoolFromPoolSaga(
  action: ReturnType<typeof PoolActions.createPoolFromPool>,
) {
  try {
    const pool = yield* select(selectCurrentPool);

    if (!pool) {
      return;
    }

    const { timeZone, poolLanguage } = pool;

    yield* put(
      setLoader({
        id: CREATE_POOL_FROM_POOL_LOADER_ID,
        status: LoaderStatus.LOADING,
      }),
    );

    const { poolId } = yield* all({
      poolId: createPoolFromPool(action, timeZone, poolLanguage),
      _: call(() => wait(CREATE_POOL_FROM_POOL_FAKE_LOADING_MS)),
    });

    yield* put(
      PoolActions.switchPool({
        poolId,
        roleType: PoolRoleType.AT_LEAST_STANDARD,
      }),
    );

    yield* put(
      BatchActions({
        actions: [
          showSuccess({
            title: t("poolChanged", {
              name: action.payload.name,
            }),
          }),
          setLoader({
            id: CREATE_POOL_FROM_POOL_LOADER_ID,
            status: LoaderStatus.SUCCESS,
          }),
        ],
      }),
    );
  } catch (e) {
    yield* put(
      BatchActions({
        actions: [
          setLoader({
            id: CREATE_POOL_FROM_POOL_LOADER_ID,
            status: LoaderStatus.FAILURE,
            error: new Error("Error while creating to create the workspace"),
          }),
          showError({ title: t("internalError") }),
        ],
      }),
    );
  }
}

function* createPoolFromPool(
  action: ReturnType<typeof PoolActions.createPoolFromPool>,
  poolTimeZone: string,
  poolLanguage: string,
) {
  const { name, poolCreationReason, companySize, companyIndustry, userJob } =
    action.payload;

  const poolId = yield* call(
    Api.createOwnWorkspace,
    name,
    poolLanguage,
    poolTimeZone,
  );

  try {
    yield* all([
      yield* call(Api.setUserJob, userJob),
      yield* call(Api.setPoolCompanyIndustry, poolId, companyIndustry),
      yield* call(Api.setPoolCompanySize, poolId, companySize),
      yield* call(Api.setPoolAcquisitionSource, poolId, "alreadyMember"),
      yield* call(Api.setPoolCreationReason, { poolId, poolCreationReason }),
    ]);
  } catch (e) {}

  return poolId;
}
