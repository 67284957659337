import { useCallback, useEffect, useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { useStopEditingMessageText } from "@kraaft/shared/components/messageTextEditor/messageTextEditor.hooks";
import { MessageTextEditorHeader } from "@kraaft/shared/components/messageTextEditor/messageTextEditorHeader";
import { MentionPortalRenderer } from "@kraaft/shared/components/textInputWithMentions/mentionPortalRenderer";
import { useMentionAwareTextState } from "@kraaft/shared/components/textInputWithMentions/useMentionAwareTextState";
import {
  TextMessageInput,
  TextMessageInputHandle,
} from "@kraaft/shared/components/textMessageInput/textMessageInput";
import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { MessageActions } from "@kraaft/shared/core/modules/message/messageActions";
import { MessageWithText } from "@kraaft/shared/core/modules/message/messageState";
import { getMessageInputPartitions } from "@kraaft/shared/core/modules/message/messageUtils";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import { Spacing } from "@kraaft/ui";

interface Props {
  roomId: string;
  message: MessageWithText;
}

const MessageTextEditor = (props: Props) => {
  const onStopEditing = useStopEditingMessageText();

  const { roomId, message } = props;
  const dispatch = useDispatch();
  const messageInputRef = useRef<TextMessageInputHandle>(null);

  const { mentionAwareText, availableMentions } =
    useMentionAwareTextState(roomId);
  const messagePartitions = getMessageInputPartitions(message);
  const allowEmpty =
    message.type === "text"
      ? false
      : !InputPartitionHelper.isEmpty(messagePartitions);

  useEffect(() => {
    const partitions = getMessageInputPartitions(message);
    if (partitions) {
      mentionAwareText.resetFromInputPartitions(
        partitions,
        mentionAwareText.getUsernameFromUserId,
      );
    }
    setTimeout(
      () => messageInputRef.current?.focus(),
      Platform.OS === "android" ? 100 : 0,
    );
  }, [message, mentionAwareText]);

  const onEditTranscription = useCallback(() => {
    const trimmedInputPartitions = InputPartitionHelper.trimInputPartitions(
      InputPartitionHelper.segmentsToInputPartitions(
        mentionAwareText.getSegments(),
      ),
    );
    dispatch(
      MessageActions.editMessageTextRequest({
        roomId,
        messageId: message.id,
        text: trimmedInputPartitions,
        messageType: message.type,
      }),
    );

    dismissNativeKeyboard();
    onStopEditing();
  }, [
    mentionAwareText,
    dispatch,
    roomId,
    message.id,
    message.type,
    onStopEditing,
  ]);

  const isSubmitDisabled =
    mentionAwareText.getTextLength() === 0 && !allowEmpty;

  return (
    <View id="message-text-editor">
      <View style={styles.mentionPortal}>
        <MentionPortalRenderer />
      </View>
      <MessageTextEditorHeader message={message} onClose={onStopEditing} />
      <View style={styles.input}>
        <TextMessageInput
          mentionAwareText={mentionAwareText}
          availableMentions={availableMentions}
          large={isNative()}
          onPublish={onEditTranscription}
          ref={messageInputRef}
          isEditingMessage
          isSubmitDisabled={isSubmitDisabled}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    padding: Spacing.S8,
  },
  mentionPortal: {
    position: "absolute",
    top: -Spacing.S8,
    left: 0,
    right: 0,
  },
});

export { MessageTextEditor };
