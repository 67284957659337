import { useMemo } from "react";
import { useSelector } from "react-redux";

import { isAtLeastPoolStandard } from "@kraaft/shared/core/modules/pool/poolUtil";
import {
  selectCurrentUserIsSuperadmin,
  selectCurrentUserPools,
} from "@kraaft/shared/core/modules/user/userSelectors";

export function useIsCurrentUserExternalInAllPools() {
  const userPools = useSelector(selectCurrentUserPools);
  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);

  return useMemo(() => {
    if (isSuperAdmin) {
      return false;
    }

    const userPoolValues = userPools ? Object.values(userPools) : [];

    return !userPoolValues.some((userPoolInfos) =>
      isAtLeastPoolStandard(userPoolInfos.role),
    );
  }, [isSuperAdmin, userPools]);
}
