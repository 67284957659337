import { useCallback, useMemo } from "react";
import { StatusBar } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { CarouselImage } from "@kraaft/shared/components/carousel/placeholders/carouselImage";
import { CarouselVideo } from "@kraaft/shared/components/carousel/placeholders/carouselVideo";
import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { MessageActions } from "@kraaft/shared/core/modules/message/messageActions";
import {
  ImageMessage,
  Message,
  VideoMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { GeoLocation } from "@kraaft/shared/core/types";
import { useRemoveMessage } from "@kraaft/shared/core/utils/useRemoveMessage";
import {
  Carousel,
  Sheet,
  Spacing,
  useCarouselOpenClose,
  useMaxSafeAreaInsets,
} from "@kraaft/ui";
import { CarouselItemRenderer } from "@kraaft/ui/src/display/carousel/carousel.types";

import {
  selectRoomCarousel,
  selectRoomCarouselData,
} from "../../roomCarouselSelectors";

interface RoomCarouselProps {
  roomId: string;
}

function messageKeyExtractor(message: ImageMessage | VideoMessage) {
  return message.id;
}

const RoomCarouselSheet = Sheet({
  default: "overlay",
}).create<RoomCarouselProps>(({ Content }) => ({ roomId, onClose }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectRoomCarouselData(roomId));

  const handleGeolocationChange = useCallback(
    (message: Message, geolocation: GeoLocation | null | undefined) => {
      dispatch(
        MessageActions.updateAttachmentGeolocation({
          roomId,
          messageId: message.id,
          geolocation: geolocation ?? undefined,
        }),
      );
    },
    [dispatch, roomId],
  );

  const uploadContext = useMemo<UploadAttachmentContext>(
    () => ({
      type: "room",
      roomId,
    }),
    [roomId],
  );

  const removeMessage = useRemoveMessage({ roomId });

  const guardDelete = Guard.useGuard("Message.delete");

  const renderItem = useCallback<
    CarouselItemRenderer<ImageMessage | VideoMessage>
  >(
    ({ index, item }) => {
      const [canShowDelete] = guardDelete(item);

      if (item.type === "image") {
        return (
          <CarouselImage
            index={index}
            item={item}
            roomId={roomId}
            attachment={item.attachment}
            onLocationChange={handleGeolocationChange}
            onClose={onClose}
            uploadContext={uploadContext}
            onDelete={canShowDelete ? removeMessage : undefined}
          />
        );
      }
      if (item.type === "video") {
        return (
          <CarouselVideo
            index={index}
            item={item}
            roomId={roomId}
            attachment={item.attachment}
            onLocationChange={handleGeolocationChange}
            onClose={onClose}
            uploadContext={uploadContext}
            onDelete={canShowDelete ? removeMessage : undefined}
          />
        );
      }
      return null;
    },
    [
      onClose,
      handleGeolocationChange,
      removeMessage,
      roomId,
      uploadContext,
      guardDelete,
    ],
  );

  const insets = useMaxSafeAreaInsets({
    top: Spacing.S8,
    bottom: Spacing.S8,
  });

  if (!data) {
    return null;
  }

  return (
    <Content>
      <StatusBar backgroundColor="black" barStyle="light-content" />
      <Carousel
        insets={insets}
        initialIndex={data.initialIndex}
        items={data.messages}
        open
        onClose={onClose}
        keyExtractor={messageKeyExtractor}
        renderItem={renderItem}
      />
    </Content>
  );
});

export const RoomCarousel = ({ roomId }: RoomCarouselProps) => {
  const dispatch = useDispatch();
  const roomCarousel = useSelector(selectRoomCarousel);
  const { open, onClose } = useCarouselOpenClose(roomCarousel?.show);

  const closeCarousel = useCallback(() => {
    dispatch(CarouselStateActions.close());
  }, [dispatch]);

  const { element } = RoomCarouselSheet.use({
    open,
    onClose,
    onClosed: closeCarousel,
    roomId,
  });

  if (!roomCarousel?.show) {
    return null;
  }

  return element;
};
