import { makeStyles } from "@mui/styles";

import { ColorStyle, FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: Spacing.S16,
  },
  headerText: {
    flex: 1,
    marginRight: Spacing.S16,
  },
  headerButton: {
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    gap: Spacing.S8,
  },
  title: {
    display: "flex",
    alignItems: "center",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.H1,
    fontWeight: 500,
    minHeight: 32,
  },
  subtitle: {
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    fontSize: FontSize.TITLE,
    marginTop: Spacing.S12,
  },
});
