import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  SectionHeaderIconButton,
  SectionHeaderLabelButton,
} from "@kraaft/shared/components/conversationDetails/sectionHeader";
import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ColorStyle } from "@kraaft/ui";
import { useNavigationService } from "@kraaft/web/src/core/services/navigation/useNavigationService";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";

const SchemaListSectionHeaderActions = ({ roomId }: { roomId: string }) => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const menuRef = useRef(null);
  const [isMenuOpen, openMenu, closeMenu] = useBooleanState();

  const room = useSelector(selectRoom(roomId));

  const canSelectRoomSchemas = Guard.use("Room.toggleSchemas");

  const goToRoomSchemaVisibility = useCallback(() => {
    navigationService.navigate("EditSchemaVisibilityForRoom", { roomId });
  }, [navigationService, roomId]);

  const goToConfigureSchemas = useCallback(() => {
    trackEvent({
      eventName: "Click On Configure Schema From Folder",
      room_id: roomId,
      room_name: getExistingRoomRecordField(
        room?.record.properties,
        "title",
        "",
      ),
    });

    navigationService.navigateToPathname(routes.SchemasView);
  }, [navigationService, room, roomId]);

  const menuActions = useMemo<ActionSheetItem[]>(
    () => [
      {
        icon: "link-external-02",
        label: t("conversationFolder.schemas.configure"),
        onPress: goToConfigureSchemas,
      },
    ],
    [goToConfigureSchemas, t],
  );

  if (!canSelectRoomSchemas) {
    return null;
  }

  return (
    <>
      <SectionHeaderLabelButton
        onPress={goToRoomSchemaVisibility}
        label={t("conversationFolder.schemas.showOrHide")}
      />
      <SectionHeaderIconButton
        accessibilityLabel={t("menu")}
        ref={menuRef}
        icon="dots-vertical"
        iconColor={ColorStyle.ACTION_CTA}
        onPress={openMenu}
      />
      <LegacyActionSheet
        anchorRef={menuRef}
        options={menuActions}
        open={isMenuOpen}
        onClose={closeMenu}
      />
    </>
  );
};

export { SchemaListSectionHeaderActions };
