import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectGridLibrarySchemasForLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import {
  selectCurrentPool,
  selectCurrentPoolLanguage,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectSchemaLibraryTagArrayByLanguage } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.selectors";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";

export type SchemaLibraryTagWithCounter = SchemaLibraryTag & {
  schemaCount: number;
};

export function useOrderedSchemaLibraryTagWithCounter(): Array<SchemaLibraryTagWithCounter> {
  const language = useSelector(selectCurrentPoolLanguage);
  const schemaLibraryTags = useSelector(
    selectSchemaLibraryTagArrayByLanguage(language || "fr-FR"),
  );
  const pool = useSelector(selectCurrentPool);
  const librarySchemas = useSelector(
    selectGridLibrarySchemasForLanguage(pool?.poolLanguage),
  );

  const schemaLibraryCountByTagId = useMemo(
    () =>
      librarySchemas.reduce(
        (prev, curr) => {
          if (!curr.companyId && curr.tagIds) {
            for (const tagId of curr.tagIds) {
              prev[tagId] = (prev[tagId] ?? 0) + 1;
            }
          }
          return prev;
        },
        {} as { [tagId: string]: number },
      ),
    [librarySchemas],
  );

  return useMemo<Array<SchemaLibraryTagWithCounter>>(
    () =>
      schemaLibraryTags.map((tagInfo) => ({
        ...tagInfo,
        schemaCount: schemaLibraryCountByTagId[tagInfo.id] || 0,
      })),
    [schemaLibraryTags, schemaLibraryCountByTagId],
  );
}
