import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { SectionHeaderIconButton } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { DocumentSectionMenuProps } from "@kraaft/shared/components/conversationDetails/worksiteFolder/documentSection/documentSectionMenu/documentSectionMenu.types";
import {
  ActionSheetItem,
  LegacyActionSheet,
} from "@kraaft/shared/components/legacyActionSheet";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { ColorStyle } from "@kraaft/ui";
import { EditDirectoryTreeDialog } from "@kraaft/web/src/components/directory/editDirectoryTreeDialog";

export const DocumentSectionMenu = (props: DocumentSectionMenuProps) => {
  const { roomId } = props;
  const { t } = useTranslation();
  const canManageTree = Guard.use("Directory.manageRoomTree");
  const menuRef = useRef(null);
  const [isMenuOpen, openMenu, closeMenu] = useBooleanState();
  const [
    isEditDirectoryTreeDialogOpen,
    openEditDirectoryTreeDialog,
    closeEditDirectoryTreeDialog,
  ] = useBooleanState();

  const menuActions = useMemo<ActionSheetItem[]>(
    () => [
      ...(canManageTree
        ? [
            {
              icon: "folder-fill",
              label: t("directory.manageDirectories"),
              onPress: openEditDirectoryTreeDialog,
            } as const,
          ]
        : []),
    ],
    [openEditDirectoryTreeDialog, t, canManageTree],
  );

  if (menuActions.length === 0) {
    return null;
  }

  return (
    <>
      <SectionHeaderIconButton
        accessibilityLabel={t("menu")}
        ref={menuRef}
        icon="dots-vertical"
        iconColor={ColorStyle.ACTION_CTA}
        onPress={openMenu}
      />
      <LegacyActionSheet
        anchorRef={menuRef}
        options={menuActions}
        open={isMenuOpen}
        onClose={closeMenu}
      />
      <EditDirectoryTreeDialog
        roomId={roomId}
        open={isEditDirectoryTreeDialogOpen}
        onClose={closeEditDirectoryTreeDialog}
      />
    </>
  );
};
