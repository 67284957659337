import { spawn } from "typed-redux-saga/macro";

import { companySaga } from "@kraaft/shared/core/modules/company/company.saga";
import { formSagas } from "@kraaft/shared/core/modules/form";
import { librarySchemaSagas } from "@kraaft/shared/core/modules/librarySchema";
import { schemaLibraryTagsSagas } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.saga";
import { pollingSagas } from "@kraaft/shared/core/modules/polling";
import { schemaViewSagas } from "@kraaft/shared/core/modules/schemaView";
import { workflowSagas } from "@kraaft/shared/core/modules/workflows";
import { alertSagas } from "@kraaft/web/src/core/modules/alert/alertSagas";
import { poolAdminSagas } from "@kraaft/web/src/core/modules/poolAdmin";
import { routerSagas } from "@kraaft/web/src/core/modules/router/routerSaga";

import { commonSagas } from "./commonSagas";

export function* platformSagas() {
  yield* spawn(commonSagas);

  yield* spawn(alertSagas);
  yield* spawn(poolAdminSagas);
  yield* spawn(formSagas);
  yield* spawn(routerSagas);
  yield* spawn(pollingSagas);
  yield* spawn(workflowSagas);
  yield* spawn(schemaViewSagas);
  yield* spawn(librarySchemaSagas);
  yield* spawn(schemaLibraryTagsSagas);
  yield* spawn(companySaga);
}
