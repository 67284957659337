import { CSSProperties, FC } from "react";
import { useDragLayer, XYCoord } from "react-dnd";

import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { Portal } from "@kraaft/ui";

import {
  DragPreviewMessageDocument,
  DragPreviewMessageGeolocation,
  DragPreviewMessageImage,
  DragPreviewMessageText,
  DragPreviewMessageVideo,
} from "./dragPreview";

const layerStyles: CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 2000, // header zIndex is 1100
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

function getItemStyles(clientOffset: XYCoord | null) {
  if (!clientOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = clientOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const CustomDragLayer: FC = () => {
  const { itemType, isDragging, item, clientOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      clientOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  );

  function renderItem() {
    switch (itemType) {
      case DraggableMessageType.MessageImage:
        return <DragPreviewMessageImage item={item} />;
      case DraggableMessageType.MessageDocument:
        return <DragPreviewMessageDocument item={item} />;
      case DraggableMessageType.MessageText:
        return <DragPreviewMessageText item={item} />;
      case DraggableMessageType.MessageGeolocation:
        return <DragPreviewMessageGeolocation item={item} />;
      case DraggableMessageType.MessageVideo:
        return <DragPreviewMessageVideo item={item} />;
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }

  return (
    <Portal>
      <div style={layerStyles}>
        <div style={getItemStyles(clientOffset)}>{renderItem()}</div>
      </div>
    </Portal>
  );
};
