import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Text } from "@kraaft/ui";

export function useDeleteSignature(onDelete: () => void) {
  const { t } = useTranslation();
  const [isOpen, open, close] = useBooleanState();

  const handleDelete = useCallback(() => {
    close();
    onDelete();
  }, [close, onDelete]);

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("del"),
        text: t("del"),
        onPress: handleDelete,
        destructive: true,
      },
      { accessibilityLabel: t("cancel"), text: t("cancel"), onPress: close },
    ],
    [t, handleDelete, close],
  );

  const dialog = (
    <KDialog
      title={t("signature.eraseDialog.title")}
      open={isOpen}
      onClose={close}
      content={<Text>{t("signature.eraseDialog.content")}</Text>}
      actions={actions}
    />
  );

  return { dialog, askDelete: open };
}
