import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, StyleSheet, View } from "react-native";

import { useModularFoldersSelection } from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";
import { Button, Spacing } from "@kraaft/ui";

import { ModularFolderBulkExportSheet } from "./modularFolderBulkExportSheet";

interface Props {
  roomId: string;
  schemaId: string;
}

export const ModularFolderListSelectionFooter = ({
  roomId,
  schemaId,
}: Props) => {
  const anchorRef = useRef(null);
  const selectionCount = useModularFoldersSelection().size;

  const { element: sheetElement, open: openSheet } =
    ModularFolderBulkExportSheet.use({});

  const { ReportTemplateSelection, openReportTemplateSelection } =
    useReportTemplateSelection({
      schemaId,
      callback: async (reportTemplate, format) => {
        openSheet({
          roomId,
          schemaId,
          reportTemplate,
          format,
        });
      },
      anchorRef,
      allowPDF: true,
      withAnchorWidth: true,
    });

  const { t } = useTranslation();

  return (
    <View style={styles.wrapper}>
      <Button
        text={t("modularFolderBulkExport.downloadButton", {
          count: selectionCount,
        })}
        onPress={openReportTemplateSelection}
        ref={anchorRef}
        disabled={selectionCount === 0}
      />
      {ReportTemplateSelection}
      {sheetElement}
      <SafeAreaView />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    padding: Spacing.S16,
  },
});
