import moment, { type Moment } from "moment";

import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DateCalendar,
  DateTimeField,
  LocalizationProvider,
  MultiSectionDigitalClock,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from "react";

import { FontSize, Spacing } from "../../constants";
import { Button } from "../button";
import type { DateTimePickerContentProps } from "./dateTimePicker.types";
import { DateTimePickerSheet } from "./dateTimePickerSheet";

const DIGITAL_CLOCK_SX = {
  "& .MuiMultiSectionDigitalClockSection-root": {
    maxHeight: "100%",
  },
  "& .MuiMultiSectionDigitalClockSection-root:after": {
    height: 0,
  },
};

const DATE_TIME_FIELD_SX = {
  "& .MuiInputBase-input": {
    fontSize: FontSize.BODY,
    paddingTop: `${Spacing.S8}px`,
    paddingBottom: `${Spacing.S8}px`,
  },
};

export const DateTimePickerSheetContent = ({
  date,
  onChange,
  onErase,
  forceValue,
  displayTime,
  translate,
}: DateTimePickerContentProps) => {
  const classes = useStyles();

  const [value, setValue] = useState<Moment | null>(moment(date));
  const isValid = value?.isValid();

  const submit = (submitted_?: Moment | null) => {
    const submitted = submitted_ ?? value;

    if (!submitted) {
      onErase();
    } else if (isValid) {
      const result = displayTime ? submitted : submitted.startOf("day");
      onChange(result.toDate());
    }
  };

  const onKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const handleChange = (newValue: Moment | null) => {
    setValue(newValue);
    if (!displayTime) {
      submit(newValue);
    }
  };

  const handleSubmit = () => {
    submit();
  };

  return (
    <DateTimePickerSheet.Paper>
      <DateTimePickerSheet.Content noPadding>
        <div id="ide2e-datePicker" onKeyUp={onKeyUp}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {displayTime && (
              <div className={classes.field}>
                <DateTimeField
                  value={value}
                  onChange={handleChange}
                  fullWidth
                  sx={DATE_TIME_FIELD_SX}
                  onKeyDown={onKeyUp}
                />
              </div>
            )}
            <div className={classes.pickers}>
              <div>
                <DateCalendar value={value} onChange={handleChange} />
                <Divider />
              </div>
              {displayTime && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <MultiSectionDigitalClock
                    value={value}
                    onChange={handleChange}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    sx={DIGITAL_CLOCK_SX}
                  />
                </>
              )}
            </div>
          </LocalizationProvider>
        </div>
      </DateTimePickerSheet.Content>

      <DateTimePickerSheet.Footer>
        {!forceValue ? (
          <Button
            onPress={onErase}
            variant="QUATERNARY"
            destructive
            text={translate("erase")}
          />
        ) : (
          <div /> // empty div to push submit button to the right
        )}
        <Button
          onPress={handleSubmit}
          variant="PRIMARY"
          text={translate("submit")}
          disabled={!isValid}
        />
      </DateTimePickerSheet.Footer>
    </DateTimePickerSheet.Paper>
  );
};

const useStyles = makeStyles({
  field: {
    padding: Spacing.S16,
  },
  pickers: {
    display: "flex",
    flexDirection: "row",
    paddingRight: Spacing.S16,
  },
});
