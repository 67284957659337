import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { Message } from "@kraaft/shared/core/modules/message/messageState";
import { canShowRemoveMessage } from "@kraaft/shared/core/modules/message/messageUtils";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserAccountOwner } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { superadminDeleteMessageRequest } from "@kraaft/shared/core/modules/room/roomActions";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";

export function useSuperadminMenuItems(roomId: string, message: Message) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSuperadmin = isUserSuperadmin(useSelector(selectCurrentUser));
  const poolId = useSelector(selectCurrentPoolId);
  const isAccountOwner = useSelector(selectCurrentUserAccountOwner(poolId));

  return useMemo(() => {
    const items: ActionSheetItem[] = [];

    if (!isSuperadmin) {
      return [];
    }

    if (canShowRemoveMessage(message, isSuperadmin, isAccountOwner)) {
      items.push({
        label: t("deleteMessageButton"),
        onPress: () =>
          newConfirmationAlert({
            title: t("removeMessageAlertTitle"),
            message: t("removeMessageSuperadminAlertContent"),
            confirmText: t("del"),
            onConfirm: () =>
              dispatch(
                superadminDeleteMessageRequest({
                  messageId: message.id,
                }),
              ),
          }),
        style: "destructive",
        icon: "trash-03",
      });
    }
    return items;
  }, [isSuperadmin, isAccountOwner, message, t, dispatch]);
}
