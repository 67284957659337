import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet/legacyActionSheetProps";
import { useDownloadReport } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsFooter/useDownloadReport";
import { useNavigateToModularFolderHistory } from "@kraaft/shared/components/modularFolders/modularFolderDetails/useNavigateToModularFolderHistory";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import {
  selectModularFolderHasAtLeastOneLockedColumn,
  selectModularFolderTitle,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

type UseModularFolderContextMenuProps = {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
  anchorRef: React.RefObject<HTMLElement>;
};

export function useModularFolderContextMenu(
  props: UseModularFolderContextMenuProps,
) {
  const { roomId, schemaId, modularFolderId, anchorRef } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canDelete = Guard.use("ModularFolder.delete");

  const navigationService = useNavigationService();

  const schemaName = useSelector(selectSchemaName(schemaId));

  const hasAtLeastOneLockedColumn = useSelector(
    selectModularFolderHasAtLeastOneLockedColumn(modularFolderId),
  );

  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const removeModularFolder = useCallback(() => {
    newConfirmationAlert({
      title: t("modularFolderDeleteAlertTitle.details"),
      confirmText: t("del"),
      onConfirm: () => {
        dispatch(
          OfflineModularFolderActions.delete({
            id: modularFolderId,
          }),
        );
        if (isNative()) {
          navigationService.goBack();
        } else {
          navigationService.navigate("ModularFolders", {
            roomId,
            schemaId,
          });
        }
      },
    });
  }, [t, dispatch, modularFolderId, navigationService, roomId, schemaId]);

  const { downloadReport, isGenerating } = useDownloadReport({
    schemaId,
    modularFolderId,
  });

  const { ReportTemplateSelection, openReportTemplateSelection } =
    useReportTemplateSelection({
      schemaId,
      callback: downloadReport,
      anchorRef,
      allowPDF: true,
    });

  const navigateToModularFolderHistory = useNavigateToModularFolderHistory({
    roomId,
    schemaId,
    modularFolderId,
  });

  const duplicate = useCallback(() => {
    const { payload } = dispatch(
      OfflineModularFolderActions.duplicate({
        modularFolderId,
      }),
    );
    const [firstId] = payload.ids;
    if (!firstId) {
      return;
    }
    navigationService.navigate("ModularFolderDetails", {
      roomId,
      schemaId,
      folderId: firstId,
    });
    trackEvent({
      eventName: "Duplicate Object Record",
      room_id: roomId,
      schema_id: schemaId,
      schema_name: schemaName ?? "",
      record_title: modularFolderTitle,
      from: "information_panel",
    });
  }, [
    dispatch,
    modularFolderId,
    modularFolderTitle,
    navigationService,
    roomId,
    schemaId,
    schemaName,
  ]);

  const sheetOptions = useMemo(() => {
    const items: ActionSheetItem[] = [
      {
        label: t("duplicate"),
        onPress: duplicate,
        icon: "copy-06",
      },
      {
        label: isNative()
          ? t("shareExportNative")
          : t("generateFolderExportWeb"),
        onPress: openReportTemplateSelection,
        icon: isNative() ? "share-02" : "download-01",
        disabled: isGenerating,
      },
      {
        label: t("history.seeHistory"),
        onPress: navigateToModularFolderHistory,
        icon: "clock-rewind",
      },
    ];

    if (!hasAtLeastOneLockedColumn && canDelete) {
      items.push({
        label: t("del"),
        onPress: removeModularFolder,
        icon: "trash-03",
        style: "destructive",
      });
    }

    return items;
  }, [
    duplicate,
    navigateToModularFolderHistory,
    hasAtLeastOneLockedColumn,
    isGenerating,
    openReportTemplateSelection,
    removeModularFolder,
    t,
    canDelete,
  ]);

  return {
    ReportTemplateSelection,
    sheetOptions,
  };
}
