import { GeoLocation } from "@kraaft/shared/core/types";

export enum DraggableMessageType {
  MessageDocument = "message-document",
  MessageImage = "message-image",
  MessageVideo = "message-video",
  MessageText = "message-text",
  MessageGeolocation = "message-geolocation",
}

export type DroppableItem = {
  messageId: string;
  type: DraggableMessageType;
  files?: File[];
  renderText?: () => string;
  geolocation?: GeoLocation;
};

export interface UseDroppableProps {
  acceptType?: string | string[];
  acceptSystemFile?: boolean;
  onDrop?: (item: DroppableItem) => void;
  onDropFiles?: (files: File[]) => void;
  shallow?: boolean;
}
