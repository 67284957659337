import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Button, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";

import { InviteCollaboratorsAtPoolCreationInput } from "./inviteCollaboratorsAtPoolCreationInput";

const InviteCollaboratorsAtPoolCreation = (props: {
  poolId: string;
  onInvited?: (ids?: string[]) => void;
}) => {
  const { poolId } = props;
  const { t } = useTranslation();
  const navigationService = useNavigationService();

  const goToNextPage = useCallback(() => {
    navigationService.replace("LoaderCreatingPool", {
      hasInvited: false,
    });
  }, [navigationService]);

  return (
    <Box>
      <Box mb="S12">
        <Text weight="bold" size="H1">
          {t("inviteCollaboratorsAtPoolCreation.title")}
        </Text>
      </Box>
      <Box mb="S40">
        <InviteCollaboratorsAtPoolCreationInput poolId={poolId} />
      </Box>
      <Button
        variant="PRIMARY"
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={goToNextPage}
      />
    </Box>
  );
};

export { InviteCollaboratorsAtPoolCreation };
