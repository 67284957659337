import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { DetailedActionCard } from "@kraaft/shared/components/detailedActionCard";
import { Color, Sheet, Spacing } from "@kraaft/ui";

interface CreateOrJoinPoolSheetProps {
  onJoinPress: () => void;
  onCreatePress: () => void;
}

export const CreateOrJoinPoolSheet = Sheet({
  web: "popup",
  native: "bottom",
})
  .create<CreateOrJoinPoolSheetProps>(
    ({ Paper, Header, Content }) =>
      ({ onClose, onJoinPress, onCreatePress }) => {
        const { t } = useTranslation();
        return (
          <Paper>
            <Header onClose={onClose}>{t("addPool.addPool")}</Header>
            <Content>
              <View style={styles.actionCardsContainer}>
                <DetailedActionCard
                  title={t("addPool.create")}
                  subtitle={t("addPool.createDesc")}
                  icon="plus"
                  iconColor={Color.BLUE_KRAAFT}
                  onPress={onCreatePress}
                />
                <DetailedActionCard
                  title={t("addPool.join")}
                  subtitle={t("addPool.joinDesc")}
                  icon="align-right-01"
                  iconColor={Color.BLUE_KRAAFT}
                  onPress={onJoinPress}
                />
              </View>
            </Content>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });

const styles = StyleSheet.create({
  actionCardsContainer: {
    gap: Spacing.S8,
  },
});
