import { EventChannel, eventChannel } from "redux-saga";
import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { MAX_ROOM_CARDS_PER_PAGE } from "@kraaft/shared/core/modules/roomCard/const";
import {
  FirestoreRoomCard,
  firestoreSubscribeToRoomCards,
  normalizeRoomCards,
} from "@kraaft/shared/core/modules/roomCard/queries/firestore.roomCard";
import { loadRoomCards } from "@kraaft/shared/core/modules/roomCard/queries/loadRoomCards";
import {
  RoomCardActions,
  RoomCardStateActions,
} from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import {
  selectAlreadyMoreRoomCardsLoaded,
  selectCursorForFirstLoadedRoomCard,
  selectRoomCardQueryContext,
} from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { RoomCardCursor } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";
import { takeCountedDeep, waitFor } from "@kraaft/shared/core/utils/sagas";

type Meta = EventChannel<FirestoreRoomCard[]> | undefined;

function createChannel(
  poolId: string,
  userId: string,
  filters: RoomFilters | undefined,
) {
  return eventChannel<FirestoreRoomCard[]>((emit) => {
    return firestoreSubscribeToRoomCards(
      poolId,
      userId,
      filters,
      MAX_ROOM_CARDS_PER_PAGE,
      emit,
    );
  });
}

function* subscribeToRoomCards(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof RoomCardActions.subscribe>,
) {
  const filterId = RoomCardUtils.computeSubscriptionFilterId(action.payload);

  yield* put(
    RoomCardStateActions.setPageInitialState({
      filterId,
      queryContext: action.payload,
    }),
  );

  const channel = createChannel(
    action.payload.poolId,
    action.payload.userId,
    action.payload.filters,
  );

  registerMeta(channel);
  yield* takeEvery(channel, (firestoreRoomCards) => {
    return receiveRoomCards(firestoreRoomCards, filterId);
  });
}

function* receiveRoomCards(
  firestoreRoomCards: FirestoreRoomCard[],
  filterId: string,
) {
  const queryContext = yield* select(selectRoomCardQueryContext(filterId));

  const lastDocFromSubscription = firestoreRoomCards.at(-1);

  if (!lastDocFromSubscription || !queryContext) {
    yield* put(
      BatchActions({
        actions: [
          RoomCardStateActions.setFromSubscription({
            filterId,
            roomCards: {},
          }),
          RoomCardStateActions.setIsLoading({ filterId, isLoading: false }),
        ],
      }),
    );
    return;
  }

  const schema = yield* waitFor(selectRoomSchema(queryContext.poolId));

  const allSchemaColumns = schema
    ? KSchemaUtils.flattenColumnsDict(schema.rootSection)
    : {};

  const alreadyMoreLoaded = yield* select(
    selectAlreadyMoreRoomCardsLoaded(filterId),
  );

  const lastFromSubscriptionCursor =
    RoomCardUtils.createCursorFromFirestoreRoomCard(lastDocFromSubscription);

  if (firestoreRoomCards.length >= MAX_ROOM_CARDS_PER_PAGE) {
    if (alreadyMoreLoaded && lastFromSubscriptionCursor) {
      yield* fixPotentialOffset(
        filterId,
        queryContext,
        lastFromSubscriptionCursor,
        allSchemaColumns,
      );
    } else {
      yield* put(
        BatchActions({
          actions: [
            RoomCardStateActions.setCursorForLastAtAll({
              filterId,
              cursor: lastFromSubscriptionCursor,
            }),
            RoomCardStateActions.setHasMore({ filterId, hasMore: true }),
          ],
        }),
      );
    }
  }

  if (firestoreRoomCards.length < MAX_ROOM_CARDS_PER_PAGE) {
    yield* put(
      BatchActions({
        actions: [
          RoomCardStateActions.setLoadedIds({ filterId, roomCards: {} }),
          RoomCardStateActions.setHasMore({ filterId, hasMore: false }),
          RoomCardStateActions.setAlreadyMoreLoaded({
            filterId,
            alreadyMoreLoaded: false,
          }),
        ],
      }),
    );
  }

  yield* put(
    BatchActions({
      actions: [
        RoomCardStateActions.setCursorForLastFromSubscription({
          filterId,
          cursor: lastFromSubscriptionCursor,
        }),
        RoomCardStateActions.setFromSubscription({
          filterId,
          roomCards: normalizeRoomCards(firestoreRoomCards, allSchemaColumns),
        }),
        RoomCardStateActions.setIsLoading({ filterId, isLoading: false }),
      ],
    }),
  );
}

function* fixPotentialOffset(
  filterId: string,
  currentFilters: {
    poolId: string;
    filters: RoomFilters | undefined;
    userId: string;
  },
  lastFromSubscription: RoomCardCursor,
  allSchemaColumns: { [p: string]: KSchemaColumn },
) {
  const firstLoaded = yield* select(
    selectCursorForFirstLoadedRoomCard(filterId),
  );

  if (!firstLoaded) {
    return;
  }

  const roomCardsBetweenLastReceivedAndFirstLoaded = yield* call(
    loadRoomCards,
    currentFilters.poolId,
    currentFilters.userId,
    currentFilters.filters,
    lastFromSubscription,
    firstLoaded,
  );

  const newFirstLoadedDoc = roomCardsBetweenLastReceivedAndFirstLoaded[0];

  if (newFirstLoadedDoc) {
    yield* put(
      BatchActions({
        actions: [
          RoomCardStateActions.addToLoaded({
            poolId: currentFilters.poolId,
            filterId,
            roomCards: normalizeRoomCards(
              roomCardsBetweenLastReceivedAndFirstLoaded,
              allSchemaColumns,
            ),
          }),
          RoomCardStateActions.setCursorForFirstLoadedDoc({
            filterId,
            cursor:
              RoomCardUtils.createCursorFromFirestoreRoomCard(
                newFirstLoadedDoc,
              ),
          }),
        ],
      }),
    );
  }
}

function* unsubscribeFromRoomCards(meta: Meta) {
  meta?.close();
}

export function* subscribeToRoomCardsSaga() {
  yield takeCountedDeep(
    RoomCardActions.subscribe,
    RoomCardActions.unsubscribe,
    subscribeToRoomCards,
    unsubscribeFromRoomCards,
    (action) => RoomCardUtils.computeSubscriptionFilterId(action.payload),
  );
}
