import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import { WorkspaceNameInput } from "@kraaft/shared/components/createWorkspace/workspaceNameInput";
import { useCreateOwnWorkspace } from "@kraaft/shared/core/modules/pool/hooks/useCreateOwnWorkspace.hook";
import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { PoolRoleType } from "@kraaft/shared/core/modules/pool/poolState";
import { useDeviceLanguage } from "@kraaft/shared/core/services/i18next/languageDetector/useLanguage.hook";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { getDeviceTimeZone } from "@kraaft/shared/core/utils";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./createWorkspace.styles";

const CreateWorkspace = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useDeviceLanguage();
  const navigationService = useNavigationService();
  const timeZone = getDeviceTimeZone();
  const [name, setName] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [create, { loading: createLoading, poolId }] = useCreateOwnWorkspace(
    name,
    language,
    timeZone,
  );

  useEffect(() => {
    if (poolId) {
      dispatch(
        PoolActions.switchPool({
          poolId: poolId,
          roleType: PoolRoleType.AT_LEAST_STANDARD,
        }),
      );
    }
  }, [poolId, dispatch, navigationService]);

  return (
    <View style={styles.container}>
      <Text
        weight="bold"
        size="H1"
        color="FONT_HIGH_EMPHASIS"
        style={styles.title}
      >
        {t("newWorkspace.title")}
      </Text>
      <WorkspaceNameInput
        setName={setName}
        name={name}
        submit={create}
        setIsSubmitDisabled={setIsSubmitDisabled}
      />
      <View style={styles.buttonSpacer} />
      <Button
        accessibilityLabel={t("newWorkspace.createButton")}
        text={t("newWorkspace.createButton")}
        loading={createLoading}
        disabled={isSubmitDisabled}
        onPress={create}
      />
    </View>
  );
};

export { CreateWorkspace };
