import { SchemaLibraryTagOfflineFeature } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagOfflineFeature";
import { ReduxOfflineFeature } from "@kraaft/shared/core/store/offline/offlineBundle";

export const {
  Actions: OfflineSchemaLibraryTagActions,
  Selectors: OfflineSchemaLibraryTagSelectors,
  StateActions: OfflineSchemaLibraryTagStateActions,
} = ReduxOfflineFeature.Create(
  SchemaLibraryTagOfflineFeature,
  {
    selectRawAggregate: (state: any) =>
      state.schemaLibraryTag.schemaLibraryTags,
  },
  () => ({}),
);
