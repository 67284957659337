import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { RoomCardStateActions } from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import {
  selectPinnedRoomsAmount,
  selectRoomCard,
} from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";

const MAX_PINNED_ROOM_AMOUNT = 3;

export function* pinRoomSaga(
  action: ReturnType<typeof roomActions.RoomActions.pin>,
) {
  const roomCard = yield* select(selectRoomCard(action.payload.roomId));

  if (!roomCard || roomCard.type === "pool" || roomCard.pinned) {
    return;
  }

  const pinnedRoomsAmount = yield* select(
    selectPinnedRoomsAmount(roomCard.poolId),
  );

  if (pinnedRoomsAmount >= MAX_PINNED_ROOM_AMOUNT) {
    yield* put(
      showError({
        title: i18n.t("roomPinning.tooManyPinnedRooms"),
      }),
    );
    return;
  }

  try {
    yield* put(
      RoomCardStateActions.setPinned({
        poolId: roomCard.poolId,
        roomId: action.payload.roomId,
        at: new Date(),
      }),
    );
    yield* call(Api.pinRoom, {
      roomId: action.payload.roomId,
      poolId: roomCard.poolId,
    });
  } catch (error) {
    yield* put(
      BatchActions({
        actions: [
          RoomCardStateActions.setUnpinned({
            poolId: roomCard.poolId,
            roomId: action.payload.roomId,
          }),
          showError({ title: i18n.t("internalError") }),
        ],
      }),
    );
  }
}
