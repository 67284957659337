import {
  Context,
  createElement,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";

import { MeshContext } from "./meshContext";

export const useContextForwarder = (
  ForwardedContext: Context<any> | MeshContext<any>,
) => {
  const value = useContext(ForwardedContext);

  return {
    Wrapper: useCallback(
      ({ children }: PropsWithChildren) =>
        createElement(ForwardedContext.Provider, {
          value,
          children,
        }),
      [ForwardedContext.Provider, value],
    ),
  };
};
