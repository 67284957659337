import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { useStartEditingMessageText } from "@kraaft/shared/components/messageTextEditor";
import { getEditMessageMenuLabel } from "@kraaft/shared/components/messageTextEditor/messageTextEditorUtils";
import { utils as messageUtils } from "@kraaft/shared/core/modules/message";
import {
  ImageMessage,
  Message,
  VideoMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useNetwork } from "@kraaft/shared/core/utils/useNetwork";

export function useEditCaptionMenuItems(roomId: string, message: Message) {
  const { t } = useTranslation();
  const { ensureOnline } = useNetwork();

  const startEditingMessageText = useStartEditingMessageText();

  const canEdit = Guard.use("Message.editCaption", message);

  return useMemo(() => {
    if (!canEdit || !hasEditableCaption(message)) {
      return [];
    }

    return [
      {
        label: getEditMessageMenuLabel(t, message),
        onPress: ensureOnline(() => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "edit caption",
          });
          startEditingMessageText(message);
        }),
        icon: "edit-02",
      } as ActionSheetItem,
    ];
  }, [canEdit, ensureOnline, message, roomId, startEditingMessageText, t]);
}

export function hasEditableCaption(
  message: Message,
): message is ImageMessage | VideoMessage {
  return (
    (message.type === "image" || message.type === "video") &&
    messageUtils.isPersisted(message)
  );
}
