import { createSelector } from "@reduxjs/toolkit";
import { memoize, orderBy, partition } from "lodash";

import { compactMap } from "@kraaft/helper-functions";
import { OfflineLibrarySchemaSelectors } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { RootState } from "@kraaft/shared/core/store";
import { selectCurrentPoolCompanyId } from "@kraaft/shared/core/modules/pool/poolSelectors";

export const selectState = ({ librarySchema }: RootState) => librarySchema;

export const selectRawPrivateLibrarySchemas = createSelector(
  selectState,
  ({ librarySchemas, companyLibrarySchemaIds }) =>
    compactMap(companyLibrarySchemaIds, (id) => librarySchemas[id]),
);

export const selectRawPublicLibrarySchemas = createSelector(
  selectState,
  ({ librarySchemas, publicLibrarySchemaIds }) =>
    compactMap(publicLibrarySchemaIds, (id) => librarySchemas[id]),
);

export const selectOrderedLibrarySchemas = createSelector(
  OfflineLibrarySchemaSelectors.selectAllArray,
  (librarySchemas) =>
    orderBy(librarySchemas, (librarySchema) => librarySchema.index),
);

export const selectPublicEnabledLibrarySchemaCount = memoize(
  (language: LibrarySchemaLanguage | undefined) =>
    createSelector(selectOrderedLibrarySchemas, (librarySchemas) => {
      return librarySchemas.filter(
        (librarySchema) =>
          librarySchema.language === language &&
          librarySchema.enabled &&
          !librarySchema.companyId,
      ).length;
    }),
);

export const selectGridLibrarySchemasForLanguage = memoize(
  (language: LibrarySchemaLanguage | undefined) =>
    createSelector(
      selectOrderedLibrarySchemas,
      selectCurrentUserIsSuperadmin,
      selectCurrentPoolCompanyId,
      (librarySchemas, isSuperadmin, currentPoolIdCompany) => {
        const [enabled, disabled] = partition(
          librarySchemas.filter((librarySchema) => {
            return (
              librarySchema.language === language &&
              (!librarySchema.companyId ||
                librarySchema.companyId === currentPoolIdCompany)
            );
          }),
          (librarySchema) => librarySchema.enabled,
        );
        return isSuperadmin ? [...enabled, ...disabled] : enabled;
      },
    ),
);

export const selectCreatedColumn = memoize((librarySchemaId: string) =>
  createSelector(selectState, ({ createdColumnKey }) =>
    createdColumnKey?.librarySchemaId === librarySchemaId
      ? createdColumnKey.columnKey
      : undefined,
  ),
);
