export type Path<O extends Record<string, Record<string, any>>> = {
  [D in keyof O]-?: {
    [G in keyof O[D]]-?: `${D & string}.${G & string}`;
  }[keyof O[D]];
}[keyof O];

export type Access<
  O extends Record<string, Record<string, any>>,
  S,
> = S extends `${infer D & string}.${infer G & string}`
  ? D extends keyof O
    ? G extends keyof O[D]
      ? O[D][G]
      : never
    : never
  : never;

export const access = <
  O extends Record<string, Record<string, any>>,
  S extends Path<O>,
>(
  obj: O,
  specifier: S,
): Access<O, S> => {
  const [district, guard] = specifier.split(".") as [keyof O, keyof O[keyof O]];
  return (obj as any)?.[district]?.[guard];
};
