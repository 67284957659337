import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  panelsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S16,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 700,
  },
  addButtonContainer: {
    marginTop: Spacing.S12,
    alignSelf: "flex-end",
  },
  rowContainer: {
    padding: `${Spacing.S12 / 2}px 0`,
  },
  filterRow: {
    display: "flex",
    flexDirection: "row",
    columnGap: Spacing.S12,
  },
  filterCompany: {
    maxWidth: 300,
    display: "flex",
    flex: 1,
  },
});
