import { useEffect, useMemo, useRef } from "react";

import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";

interface Navigation {
  navigateToHome: () => void;
  openConversation: (roomId: string) => void;
  changeConversation: (roomId: string) => void;
}

export function useRoomAutoNavigation({
  navigation,
  paramsRoomId,
  isMapOpen,
  roomCards,
  filters,
}: {
  navigation: Navigation;
  roomCards: AnyRoomCard[];
  paramsRoomId: string | undefined;
  filters: RoomFilters | undefined;
  isMapOpen: boolean | undefined;
}) {
  const shouldTrustParamRoomId = useRef(true);
  const prevParamsRoomId = usePrevious(paramsRoomId);

  const isRoomInList = useMemo(
    () => roomCards.some((roomCard) => roomCard.roomId === paramsRoomId),
    [paramsRoomId, roomCards],
  );

  useEffect(() => {
    if (prevParamsRoomId !== paramsRoomId && !isRoomInList) {
      shouldTrustParamRoomId.current = true;
    }
  }, [isRoomInList, paramsRoomId, prevParamsRoomId]);

  useEffect(() => {
    if (shouldTrustParamRoomId && isRoomInList) {
      shouldTrustParamRoomId.current = false;
    }
  }, [paramsRoomId, roomCards, shouldTrustParamRoomId, isRoomInList]);

  useEffect(() => {
    if (paramsRoomId !== undefined && shouldTrustParamRoomId.current) {
      return;
    }

    const [firstRoom] = roomCards;

    if (!paramsRoomId && firstRoom !== undefined) {
      navigation.openConversation(firstRoom.roomId);
    } else if (!isRoomInList && !filters?.searchText && !isMapOpen) {
      if (firstRoom !== undefined) {
        navigation.changeConversation(firstRoom.roomId);
      } else {
        navigation.navigateToHome();
      }
    }
  }, [
    filters?.searchText,
    isMapOpen,
    isRoomInList,
    navigation,
    paramsRoomId,
    prevParamsRoomId,
    roomCards,
  ]);
}
