import { useTranslation } from "react-i18next";

import { useHasLibrarySchemas } from "@kraaft/shared/core/modules/librarySchema/librarySchema.hooks";
import { NavigationButtonElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/navigationButtonElement";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";

export const LibrarySchemaNavigationButton = () => {
  const { t } = useTranslation();

  const hasLibrarySchemas = useHasLibrarySchemas();

  return hasLibrarySchemas ? (
    <NavigationButtonElement
      id="navbar-schema-library"
      icon="book-open-01"
      text={t("schemaLibraryView.navItem")}
      route={routes.SchemaLibraryView}
    />
  ) : null;
};
