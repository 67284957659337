import { useCallback } from "react";
import { StyleSheet, View } from "react-native";

import { capitalize } from "@kraaft/helper-functions";
import { conditionalEntry } from "@kraaft/shared/core/utils/utils";
import { Button, Color, ColorStyle, Spacing, Text } from "@kraaft/ui";

type SchemaLibraryTagButtonProps<T> = {
  label: string;
  onPress?: (data?: T) => void;
  data?: T;
  selected?: boolean;
  schemaCount: number;
};

export const SchemaLibraryTagButton = <T,>({
  label,
  onPress,
  data,
  selected,
  schemaCount,
}: SchemaLibraryTagButtonProps<T>) => {
  const handleOnPress = useCallback(() => {
    onPress?.(data);
  }, [data, onPress]);

  return (
    <Button
      onPress={handleOnPress}
      variant="TERTIARY"
      selected={selected}
      renderContent={(renderProps) => (
        <View style={style.container}>
          <Text
            size="BODY"
            numberOfLines={1}
            style={{ color: renderProps.textColor }}
          >
            {capitalize(label)}
          </Text>
          <View style={style.counter}>
            <Text
              style={[
                conditionalEntry(style.counterZero, schemaCount === 0),
                conditionalEntry({ color: renderProps.textColor }, !!selected),
              ]}
              size="BODY"
            >
              {schemaCount}
            </Text>
          </View>
        </View>
      )}
    />
  );
};

const style = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  counter: {
    marginLeft: Spacing.S8,
    width: Spacing.S24,
    aspectRatio: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Color.GREY_ANTIFLASH,
    borderRadius: 6,
  },
  counterZero: {
    color: ColorStyle.FONT_LOW_EMPHASIS,
  },
  labelSelected: {
    color: Color.BLUE_KRAAFT,
  },
});
