import { memoize } from "lodash";

import { NotUndefined } from "../types";

export function maybeId<Id extends NotUndefined, R>(
  selector: (id: Id) => (state: any) => R,
) {
  return memoize((id: Id | undefined) =>
    id !== undefined ? selector(id) : () => undefined,
  );
}
