import { createSelector } from "@reduxjs/toolkit";
import { memoize, orderBy, pickBy } from "lodash";
import { Dictionary } from "ts-essentials";

import { selectPools } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { isAtLeastPoolStandard } from "@kraaft/shared/core/modules/pool/poolUtil";
import { selectAllRoomMembersIds } from "@kraaft/shared/core/modules/room/selectors";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { Guards } from "@kraaft/shared/core/services/auth/permissions/guards";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { RootState } from "@kraaft/shared/core/store";
import { lodashKeyResolver } from "@kraaft/shared/core/utils";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

export const selectCurrentUserId = ({ user }: RootState) => user.userAuth?.uid;

export const selectCurrentUserAuth = ({ user }: RootState) => user.userAuth;

export const selectAuthState = ({ user }: RootState) => user.authState;

export const selectCurrentUser = ({ user }: RootState) => user.currentUser;

export const selectCurrentUserCreatedAt = ({ user }: RootState) =>
  user.currentUser?.createdAt;

export const selectOnboardingFetched = ({ user }: RootState) =>
  user.onboarding?.fetched;

export const selectOnboardingState = ({ user }: RootState) =>
  user.onboarding?.state;

export const selectMayShowHomeEmptyState = ({ user }: RootState) =>
  user.mayShowHomeEmptyState;

export const selectCurrentUserIsSuperadmin = ({ user }: RootState) =>
  user.currentUser?.superRole === "administrator";

export const selectUsers = ({ user }: RootState) => user.users;

export const selectPoolUsers = ({ user }: RootState) => user.poolUsers;

export const selectBlockedUserIds = ({ user }: RootState) =>
  user.blockedUserIds;

const emptyDict: Dictionary<User> = {};

export const selectAllPoolUsers = memoize(
  (poolId: string | undefined, ignoreDemoUsers = false) =>
    createSelector(
      selectPoolUsers,
      selectActor,
      selectAllRoomMembersIds,
      (users, actor, knownRoomMembers): Dictionary<User> => {
        if (!poolId || !actor) {
          return emptyDict;
        }

        const poolUsers = users[poolId];

        const [canListPoolMembers] = Guards.Members.listPoolMembers(
          actor,
          poolId,
        );
        const filteredPoolUsers = pickBy(poolUsers, (poolUser, userId) => {
          if (poolUser.isClonedFromDemo && ignoreDemoUsers) {
            return false;
          }

          if (!canListPoolMembers && !knownRoomMembers.includes(userId)) {
            return false;
          }

          return true;
        });

        return filteredPoolUsers;
      },
    ),
  lodashKeyResolver,
);

export const selectInternalPoolUsers = memoize(
  (poolId: string | undefined, ignoreDemoUsers = false) =>
    createSelector(selectAllPoolUsers(poolId, ignoreDemoUsers), (poolUsers) => {
      if (!poolId) {
        return emptyDict;
      }

      const internalPoolUsers = pickBy(poolUsers, (poolUser) =>
        isAtLeastPoolStandard(poolUser.pools[poolId]?.role),
      );

      return internalPoolUsers;
    }),
  lodashKeyResolver,
);

export const selectExternalPoolUsers = memoize(
  (poolId: string | undefined) =>
    createSelector(selectAllPoolUsers(poolId), (poolUsers) => {
      if (!poolId) {
        return emptyDict;
      }

      const externalPoolUsers = pickBy(
        poolUsers,
        (poolUser) => poolUser.pools[poolId]?.role === UserPoolRole.EXTERNAL,
      );

      return externalPoolUsers;
    }),
  lodashKeyResolver,
);

export function selectUser(userId: string | undefined) {
  return ({ user }: RootState) =>
    userId !== undefined ? user.users[userId] : undefined;
}

export function selectMultipleUsers(userIds: string[]) {
  return ({ user }: RootState) =>
    userIds.map((userId) =>
      userId !== undefined ? user.users[userId] : undefined,
    );
}

export function selectUsername(userId: string | undefined) {
  return ({ user }: RootState): string =>
    getUsername(userId !== undefined ? user.users[userId] : undefined);
}

export const selectUsernames = memoize(
  (userIds: string[]) =>
    createSelector(selectUsers, (users) =>
      userIds.map((userId) => getUsername(users[userId])),
    ),
  lodashKeyResolver,
);

export const selectCurrentUserPools = ({ user }: RootState) =>
  user.currentUser?.pools;

export const selectCurrentUserFirstPool = createSelector(
  selectCurrentUserPools,
  selectPools,
  (userPools, pools) => {
    if (userPools === undefined) {
      return;
    }

    const poolOrdered = orderBy(Object.entries(userPools), ([_, data]) =>
      data.joinedAt.getTime(),
    );

    const [firstPoolId] = poolOrdered.shift() ?? [];

    if (firstPoolId === undefined) {
      return;
    }

    return pools[firstPoolId];
  },
);

export function* waitForCurrentUserPools() {
  yield waitFor((state: RootState) => {
    const currentUser = selectCurrentUser(state);

    const userPools = currentUser?.pools;
    if (userPools === undefined) {
      return false;
    }

    return Object.keys(userPools).length > 0;
  });
}

export function* waitForPools() {
  yield waitFor((state: RootState) => {
    const currentUser = selectCurrentUser(state);
    const pools = selectPools(state);

    const userPools = currentUser?.pools;
    if (userPools === undefined) {
      return false;
    }

    return Object.keys(userPools).length > 0 && Object.keys(pools).length > 0;
  });
}

export const selectActor = createSelector(
  selectCurrentUserId,
  selectCurrentUserPools,
  selectCurrentUserIsSuperadmin,
  (userId, userPools, isSuperadmin) => {
    if (!userId || !userPools) {
      return;
    }

    return {
      userId,
      claims: {
        pools: userPools,
        support: isSuperadmin,
      },
    };
  },
);

export const selectCurrentUserHaveBeenPranked = ({ user }: RootState) =>
  Boolean(user.currentUser?.pranked);
