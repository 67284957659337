import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { poolCompanySizes } from "@kraaft/shared/components/onboarding/askPoolCompanySize/poolCompanySize";

interface AskPoolCompanySizeFormProps {
  setSelected: (value: string) => void;
  selected: string;
}

export const AskPoolCompanySizeForm = ({
  selected,
  setSelected,
}: AskPoolCompanySizeFormProps) => {
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (newSelection: string[]) => {
      const [newSelected] = newSelection;
      if (!newSelected) {
        return;
      }
      setSelected(newSelected);
    },
    [setSelected],
  );

  const items = useMemo(
    () =>
      poolCompanySizes.map((companySize) => ({
        label: t(`poolCompanySize.choices.${companySize}`),
        identifier: companySize,
      })),
    [t],
  );

  return (
    <KSelectionList
      selected={[selected]}
      setSelected={handleSelect}
      items={items}
      noPadding
    />
  );
};
