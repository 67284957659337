import type { Spacing } from "../../constants";
import { OrderableListContextProvider } from "./orderableList.context";
import type { OrderableListRow, SourceType } from "./orderableList.types";
import {
  OrderableListContent,
  type OrderableListContentProps,
} from "./orderableListContent";
import { OrderableListDragLayer } from "./orderableListDragLayer";

interface OrderableListProps {
  sourceType?: SourceType;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  grow?: boolean;
  paddingTop?: Spacing | number;
  paddingBottom?: Spacing | number;
  disabled?: boolean;
}

export const OrderableList = <T extends OrderableListRow>({
  sourceType = "orderable-list-row",
  testId,
  rows,
  RowRenderer,
  withHandle,
  rowContainerClassName,
  targetType,
  onRequestReorder,
  onExternalTypeDrop,
  onDragStart,
  onDragEnd,
  grow,
  paddingTop,
  paddingBottom,
  disabled,
  rowGap,
}: OrderableListProps & OrderableListContentProps<T>) => {
  return (
    <OrderableListContextProvider
      sourceType={sourceType}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      disabled={disabled}
    >
      <OrderableListContent
        testId={testId}
        rows={rows}
        RowRenderer={RowRenderer}
        withHandle={withHandle}
        rowContainerClassName={rowContainerClassName}
        targetType={targetType}
        onRequestReorder={onRequestReorder}
        onExternalTypeDrop={onExternalTypeDrop}
        grow={grow}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        rowGap={rowGap}
      />

      <OrderableListDragLayer
        RowRenderer={RowRenderer}
        withHandle={withHandle}
      />
    </OrderableListContextProvider>
  );
};
