import { useMeshContext } from "@kraaft/helper-hooks";
import { AskPoolCompanyIndustryForm } from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry/askPoolCompanyIndustryForm";

import { CreatePoolFlowContext } from "../createPoolFlow.context";

export const CompanyIndustryStep = () => {
  const {
    setOtherCompanyIndustry,
    companyIndustry,
    setCompanyIndustry,
    otherCompanyIndustry,
  } = useMeshContext(CreatePoolFlowContext);

  return (
    <AskPoolCompanyIndustryForm
      setOther={setOtherCompanyIndustry}
      other={otherCompanyIndustry}
      selected={companyIndustry}
      setSelected={setCompanyIndustry}
    />
  );
};
