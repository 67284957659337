import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { GenericCarouselAttachmentProps } from "@kraaft/shared/components/carousel/placeholders/carousel.props";
import { useGeolocatedMediaSheet } from "@kraaft/shared/components/geolocatedMediaDialog/useGeolocatedMediaSheet";
import { formatDate } from "@kraaft/shared/components/message/messageUtils";
import { PartitionTextView } from "@kraaft/shared/components/textInputWithMentions/partitionTextView";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { formatAddress } from "@kraaft/shared/core/utils/utils";
import { Color, FontSize, Icon, Spacing, Text, useCarousel } from "@kraaft/ui";
import { GeolocationOpener } from "@kraaft/web/src/components/geolocationOpener";

export const GenericCarouselAttachment = ({
  index,
  roomId,
  attachment,
  onLocationChange,
  children,
}: GenericCarouselAttachmentProps) => {
  const { Page, Title, Caption } = useCarousel();
  const users = useSelector(selectUsers);

  const { geolocation } = attachment;

  const geolocationContext = useMemo(
    () =>
      ({
        source: "media_viewer",
        roomId,
      }) as const,
    [roomId],
  );

  return (
    <>
      <Page index={index}>
        <View style={styles.content}>{children}</View>
        <Title index={index}>
          <View style={styles.title}>
            <Text color="WHITE" size="BODY" weight="medium">
              {getUsername(users[attachment.senderUserId])}
            </Text>
            <Text color="WHITE" size="MEDIUM">
              {formatDate(attachment.createdAt)}
            </Text>
          </View>
        </Title>
        <Caption index={index} style={styles.caption}>
          <View>
            <Text color="WHITE" size="BODY">
              {attachment.caption ? (
                <PartitionTextView partitions={attachment.caption} />
              ) : null}
            </Text>
          </View>
          <View style={styles.geolocation}>
            {geolocation ? (
              <GeolocationOpener
                testID="ide2e-media-geolocation"
                geolocation={geolocation}
                context={geolocationContext}
              >
                <View style={styles.coords}>
                  <Icon name="marker-pin-01" size="MEDIUM" color="WHITE" />
                  <Text
                    size="BODY"
                    color="WHITE"
                    numberOfLines={2}
                    style={styles.address}
                  >
                    {formatAddress(geolocation)}
                  </Text>
                </View>
              </GeolocationOpener>
            ) : null}
            {onLocationChange && (
              <GenericCarouselAttachmentGeolocationButton
                roomId={roomId}
                attachment={attachment}
                onLocationChange={onLocationChange}
              />
            )}
          </View>
        </Caption>
      </Page>
    </>
  );
};

const GenericCarouselAttachmentGeolocationButton = ({
  roomId,
  attachment,
  onLocationChange,
}: Pick<
  GenericCarouselAttachmentProps,
  "roomId" | "attachment" | "onLocationChange"
>) => {
  const { t } = useTranslation();
  const { openEditor, GeolocatedMediaDialog } = useGeolocatedMediaSheet({
    media: attachment,
    roomId,
    source: "media_viewer",
    onLocationChange,
  });

  const { geolocation } = attachment;
  const editLocationLabel = t(geolocation ? "editLocation" : "addLocation");

  return (
    <>
      <Pressable accessibilityLabel={editLocationLabel} onPress={openEditor}>
        <View style={styles.coords}>
          <Icon
            name={geolocation ? "edit-02" : "marker-pin-04"}
            size="MEDIUM"
            color="WHITE"
          />
          {!geolocation && (
            <Text weight="medium" style={styles.editLocation}>
              {editLocationLabel}
            </Text>
          )}
        </View>
      </Pressable>
      {GeolocatedMediaDialog}
    </>
  );
};

const styles = StyleSheet.create({
  geolocation: {
    marginTop: Spacing.S16,
    marginBottom: Spacing.S8,
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
  coords: {
    flexDirection: "row",
    alignItems: "center",
  },
  editLocation: {
    color: Color.WHITE,
    fontSize: FontSize.BODY,
    marginLeft: Spacing.S8,
  },
  address: {
    textDecorationLine: "underline",
  },
  caption: {
    flexDirection: "column",
    alignItems: "center",
    paddingTop: Spacing.S8,
    textAlign: "center",
    paddingHorizontal: Spacing.S32,
  },
  title: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: Spacing.S32,
  },
  content: {
    flexGrow: 1,
    marginTop: Spacing.S72,
  },
});
