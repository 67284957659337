import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";
import { getStore } from "@kraaft/shared/core/store";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

import { setLoader } from "../../../loaders/loaderActions";
import { LoaderStatus } from "../../../loaders/loaderTypes";
import { generateEditReportTemplateFileLoaderId } from "../../../reportTemplate/reportTemplate.actions.utils";

export const replaceReportTemplateFileOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      librarySchemaId: string;
      reportTemplateId: string;
      file: ModernFile<LocalPath>;
    }>()
    .expected((aggregate, payload) => {
      const { reportTemplateId } = payload;
      const report = aggregate.reportTemplates.find(
        (r) => r.id === reportTemplateId,
      );

      if (report && report.variant === "custom") {
        report.file = {
          filename: payload.file.filename,
          downloadUrl: payload.file.path,
        };
      }

      return aggregate;
    })
    .mutate(async (payload) => {
      const { librarySchemaId, reportTemplateId, file } = payload;

      // this is not ideal but it'll do the trick until we have a way to manage loader withing the offline package
      getStore().dispatch(
        setLoader({
          id: generateEditReportTemplateFileLoaderId(reportTemplateId),
          status: LoaderStatus.LOADING,
        }),
      );

      const storagePathPayload = {
        librarySchemaId,
        filename: file.filename,
      };
      const uploadPath =
        await Api.generateLibrarySchemaReportTemplateUploadPath(
          storagePathPayload,
        );

      await fileUpload.upload({
        file,
        ...uploadPath,
      });

      const { updatedAt } = await Api.replaceFileLibrarySchemaReportTemplate({
        librarySchemaId,
        reportTemplateId,
        storagePath: uploadPath.storagePath,
      });

      getStore().dispatch(
        setLoader({
          id: generateEditReportTemplateFileLoaderId(reportTemplateId),
          status: LoaderStatus.SUCCESS,
        }),
      );

      return { updatedAt };
    });
