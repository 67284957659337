import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import {
  MapOverlayOptimisticActions,
  MapOverlayOptimisticOperations,
} from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.optimistic";
import { MapOverlay } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.state";
import { ColorStyle, Icon } from "@kraaft/ui";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";
import { DraggableLineDecoration } from "@kraaft/web/src/components/draggableLineDecoration";

import { useStyles } from "./mapOverlayItem.styles";

export const MapOverlayItem = (mapOverlay: MapOverlay) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDownloadReportTemplateFile = useCallback(() => {
    fileSaver
      .download(mapOverlay.file.downloadUrl, mapOverlay.file.filename)
      .catch(console.error);
  }, [mapOverlay]);

  const handleDelete = useCallback(
    () =>
      newConfirmationAlert({
        onConfirm: () => {
          dispatch(
            MapOverlayOptimisticActions.addOperation(
              MapOverlayOptimisticOperations.delete.create({
                targetId: mapOverlay.id,
              }),
            ),
          );
        },
        title: t("importMapOverlay.removeMessage"),
        style: "destructive",
      }),
    [dispatch, mapOverlay.id, t],
  );

  const actions = useMemo<ActionSheetItem[]>(
    () => [
      {
        label: t("download"),
        icon: "download-01",
        onPress: handleDownloadReportTemplateFile,
      },

      {
        label: t("del"),
        style: "destructive",
        icon: "trash-01",
        onPress: handleDelete,
      },
    ],
    [t, handleDownloadReportTemplateFile, handleDelete],
  );

  return (
    <DraggableLineDecoration>
      <div className={classes.row}>
        <div className={classes.iconContainer}>
          <Icon name="file-download-02" color={ColorStyle.FONT_HIGH_EMPHASIS} />
        </div>
        <span className={classes.name}>{mapOverlay.name}</span>
        <span className={classes.actionsContainer}>
          <LegacyContextMenuButton actions={actions} vertical />
        </span>
      </div>
    </DraggableLineDecoration>
  );
};
