import { ReactNode, useCallback, useMemo, useRef } from "react";
import { Platform, ScrollViewProps, StyleSheet, View } from "react-native";
import isEqual from "fast-deep-equal";

import { compactMap, isNative } from "@kraaft/helper-functions";
import { useMeshContext } from "@kraaft/helper-hooks";
import { SectionSpacer } from "@kraaft/shared/components/conversationDetails/sectionSpacer";
import { ContextAwareSectionCollapsible } from "@kraaft/shared/components/modular/details/contextAwareSectionCollapsible";
import { ContextAwareSectionSeparator } from "@kraaft/shared/components/modular/details/contextAwareSectionSeparator";
import { ModularDetailsAttachmentContext } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/attachmentsEditor.props";
import { DetailsEditorDescription } from "@kraaft/shared/components/modular/details/editors/detailsEditorDescription";
import { EditorsUtils } from "@kraaft/shared/components/modular/details/editors/editors.utils";
import { ModularDetailsEditorContext } from "@kraaft/shared/components/modular/details/editors/types";
import {
  ElementGroup,
  gatherElementsFromSection,
} from "@kraaft/shared/components/modular/details/gatherElementsFromSection";
import { LockedAnswersBanner } from "@kraaft/shared/components/modular/details/lockedAnswersBanner";
import { ModularDetailsContextProvider } from "@kraaft/shared/components/modular/details/modularDetailsContextProvider";
import { ScrollViewStickyElement } from "@kraaft/shared/components/modular/details/scrollViewStickyElement/scrollViewStickyElement";
import { SeparatedEditors } from "@kraaft/shared/components/modular/details/separatedEditors";
import {
  ColumnUpdater,
  ModularTableValueUpdate,
} from "@kraaft/shared/components/modular/details/utils";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { executeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditions";
import {
  SECTION_LOCK_INFO_FALLBACK,
  SectionLockInfo,
} from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumnLiteralValue,
  KSchemaElement,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecordProperties } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import {
  EMPTY_COLUMN_CONTEXT,
  ModularColumnsContext,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ModularDisplayExtendedRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { Spacing } from "@kraaft/ui";

import { ModularDetailsFactory } from "./modularDetailsFactory";

interface Props {
  id: string;
  testId?: string;
  rootSection: KSchemaSection;
  recordProperties: ModularRecordProperties;
  updateRecord: (update: ModularTableValueUpdate) => void;
  updateRecordSignature: (
    key: string,
    file: ModernFile<LocalPath> | undefined,
  ) => void;
  columnsContext: ModularColumnsContext;
  editorContext?: ModularDetailsEditorContext;
  attachmentContext?: ModularDetailsAttachmentContext;
  disabled?: boolean;
  shouldHideAutonumberedColumns?: boolean;
  offsetIndices?: number;
}

type ScrollableModularDetailsData = {
  modularDetailsElements: React.ReactNode[];
  scrollViewProps: ScrollViewProps;
};

export function useModularDetails({
  id,
  testId,
  rootSection,
  recordProperties,
  updateRecord,
  updateRecordSignature,
  editorContext,
  columnsContext,
  attachmentContext,
  disabled,
  shouldHideAutonumberedColumns,
  offsetIndices = 0,
}: Props) {
  const canCurrentUserReadAllPoolUsers = Guard.use("Members.listPoolMembers");

  const displayContext = useMeshContext(
    ModularDisplayExtendedRequirementsContext,
  );

  const basicUpdate = useCallbackRealtime(
    ([_rootSection], key: string) =>
      <C extends KColumnType>(value: KSchemaColumnLiteralValue<C>) => {
        const column = KSchemaUtils.findColumn(_rootSection, key);
        if (column !== undefined) {
          updateRecord({ id, column, value });
        } else {
          console.error(`Column with key (${key}) does not exist`);
        }
      },
    [id, updateRecord],
    [rootSection],
  );

  const allSections = useMemo(
    () => KSchemaUtils.getAllSections(rootSection),
    [rootSection],
  );

  const updateFunctions = useRef<
    Record<string, ColumnUpdater[keyof ColumnUpdater]>
  >({});

  // eslint-disable-next-line complexity
  useMemo(() => {
    updateFunctions.current = {};
    for (const column of KSchemaUtils.iterateColumns(rootSection)) {
      switch (column.type) {
        case KColumnType.automatedAutoIncrement:
        case KColumnType.automatedCreatedAt:
        case KColumnType.automatedCreatedBy:
        case KColumnType.join:
        case KColumnType.roomName:
          break;
        case KColumnType.attachment:
        case KColumnType.checkbox:
        case KColumnType.currency:
        case KColumnType.date:
        case KColumnType.geolocation:
        case KColumnType.longText:
        case KColumnType.number:
        case KColumnType.selectSingle:
        case KColumnType.selectMultiple:
        case KColumnType.shortText:
        case KColumnType.user:
        case KColumnType.roomMembers:
          updateFunctions.current[column.key] = basicUpdate(column.key);
          break;
        case KColumnType.signature:
          updateFunctions.current[column.key] = (
            file: ModernFile<LocalPath> | undefined,
          ) => updateRecordSignature(column.key, file);
          break;
      }
    }
  }, [basicUpdate, rootSection, updateRecordSignature]);

  const sectionLockInfos = useRef<Record<string, SectionLockInfo>>({});

  useMemo(() => {
    for (const { key } of KSchemaUtils.iterateAllSections(rootSection)) {
      const existing = sectionLockInfos.current[key];
      const computed = columnsContext.getSectionLockInfoFromSectionKey?.(
        key,
        recordProperties,
      );

      if (isEqual(existing, computed)) {
        continue;
      }
      if (computed) {
        sectionLockInfos.current[key] = computed;
      } else {
        delete sectionLockInfos.current[key];
      }
    }
  }, [columnsContext, recordProperties, rootSection]);

  const renderedColumns = useMemo(
    () =>
      [
        ...KSchemaUtils.iterateColumnsWithParentSection(
          rootSection.key,
          rootSection,
        ),
      ].reduce<Record<string, ReactNode>>(
        // eslint-disable-next-line complexity
        (allColumns, [parentSectionKey, column]) => {
          if (
            shouldHideAutonumberedColumns &&
            column.type === KColumnType.automatedAutoIncrement
          ) {
            return allColumns;
          }

          const value = recordProperties[column.key];
          const sectionLockInfo = sectionLockInfos.current[parentSectionKey];

          const columnContext = columnsContext.columns[column.key];

          const columnEditable =
            columnContext?.editable?.(recordProperties, id) ?? true;

          const columnDisabled =
            !!disabled ||
            (!columnEditable && !sectionLockInfo?.isLocked) ||
            (column.type === KColumnType.user &&
              !canCurrentUserReadAllPoolUsers);

          const updater = updateFunctions.current[column.key];

          const editorExists = EditorsUtils.editorExists(column.type);

          if (editorExists) {
            allColumns[column.key] = (
              <View key={column.key}>
                <ModularDetailsFactory
                  testID={testId}
                  value={value?.value}
                  tableColumn={column}
                  updateRecord={updater}
                  disabled={columnDisabled}
                  editorContext={editorContext}
                  columnContext={columnContext ?? EMPTY_COLUMN_CONTEXT}
                  attachmentContext={attachmentContext}
                  recordType={displayContext.recordType}
                  sectionLockInfo={
                    sectionLockInfo ?? SECTION_LOCK_INFO_FALLBACK
                  }
                />
              </View>
            );
          }
          return allColumns;
        },
        {},
      ),
    [
      canCurrentUserReadAllPoolUsers,
      attachmentContext,
      columnsContext.columns,
      disabled,
      displayContext.recordType,
      editorContext,
      id,
      recordProperties,
      rootSection,
      shouldHideAutonumberedColumns,
      testId,
    ],
  );

  const shouldAddElement = useCallback(
    (element: KSchemaElement) => {
      const isElementSection = element.elementType === "section";
      const elementHasEditor = Boolean(renderedColumns[element.key]);
      const elementFulfillsCondition =
        !element.condition ||
        executeCondition(
          rootSection,
          { room: displayContext.getRoomFromRecordId(id) },
          {} as any,
          recordProperties,
          element.condition,
          "folder",
        );
      return elementFulfillsCondition && (elementHasEditor || isElementSection);
    },
    [displayContext, id, recordProperties, renderedColumns, rootSection],
  );

  const getElementsToRender = useCallback(
    (
      group: ElementGroup,
    ): { header: React.ReactNode; content: React.ReactNode } => {
      const editors = compactMap(
        group.editorsKeys,
        (key) => renderedColumns[key],
      );

      switch (group.type) {
        case "orphan": {
          return {
            header: <ScrollViewStickyElement />,
            content:
              editors.length > 0 ? (
                <SeparatedEditors footerComponent={<SectionSpacer />}>
                  {editors}
                </SeparatedEditors>
              ) : null,
          };
        }
        case "section": {
          const section = allSections[group.sectionKey];
          let sectionLockInfo: SectionLockInfo | undefined;

          if (section) {
            sectionLockInfo = ModularRecordUtils.getSectionLockInfo(
              rootSection,
              recordProperties,
              section.key,
            );
          }

          return {
            header:
              section !== undefined ? (
                <ScrollViewStickyElement>
                  <ContextAwareSectionSeparator
                    sectionKey={section.key}
                    label={section.name}
                    color={section.color}
                  />
                </ScrollViewStickyElement>
              ) : null,
            content:
              section !== undefined ? (
                <>
                  {sectionLockInfo?.isLocked && (
                    <LockedAnswersBanner
                      lockedBy={sectionLockInfo?.lockedBy}
                      lockedAt={sectionLockInfo?.lockedAt}
                      mode="plural"
                    />
                  )}
                  <ContextAwareSectionCollapsible sectionKey={section.key}>
                    {!KSchemaUtils.isDescriptionEmpty(section.description) && (
                      <DetailsEditorDescription
                        style={styles.sectionDescription}
                        description={section.description}
                        backgroundColor={section.color}
                      />
                    )}
                    <SeparatedEditors>{editors}</SeparatedEditors>
                  </ContextAwareSectionCollapsible>
                  <SectionSpacer />
                </>
              ) : (
                <SeparatedEditors footerComponent={<SectionSpacer />}>
                  {editors}
                </SeparatedEditors>
              ),
          };
        }
      }
    },
    [allSections, recordProperties, renderedColumns, rootSection],
  );

  const rootSectionLockInfo = ModularRecordUtils.getSectionLockInfo(
    rootSection,
    recordProperties,
    rootSection.key,
  );

  const scrollableModularDetailsData =
    useMemo<ScrollableModularDetailsData>(() => {
      const groups = gatherElementsFromSection(rootSection, shouldAddElement);

      return groups.reduce<ScrollableModularDetailsData>(
        (_scrollableModularDetailsData, group) => {
          if (isNative()) {
            const stickyHeaderIndices =
              _scrollableModularDetailsData.scrollViewProps
                .stickyHeaderIndices ?? [];
            stickyHeaderIndices.push(group.headerIndice + offsetIndices);

            _scrollableModularDetailsData.scrollViewProps.stickyHeaderIndices =
              stickyHeaderIndices;
          }

          const { header, content } = getElementsToRender(group);

          _scrollableModularDetailsData.modularDetailsElements.push(
            ...Platform.select({
              web: [
                <View key={group.editorsKeys.join(",")}>
                  {header}
                  {content}
                </View>,
              ],
              default: [header, content],
            }),
          );

          return _scrollableModularDetailsData;
        },
        { modularDetailsElements: [], scrollViewProps: {} },
      );
    }, [rootSection, shouldAddElement, getElementsToRender, offsetIndices]);

  return {
    ModularDetailsContextProvider,
    modularDetailsElements: scrollableModularDetailsData.modularDetailsElements,
    scrollViewProps: scrollableModularDetailsData.scrollViewProps,
    topElement: (
      <>
        {rootSectionLockInfo.isLocked ? (
          <LockedAnswersBanner
            lockedBy={rootSectionLockInfo.lockedBy}
            lockedAt={rootSectionLockInfo.lockedAt}
            mode="plural"
          />
        ) : null}
      </>
    ),
  };
}

const styles = StyleSheet.create({
  sectionDescription: {
    paddingHorizontal: Spacing.S16,
    paddingVertical: Spacing.S8,
    paddingBottom: Spacing.S16,
  },
});
