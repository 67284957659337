import { memo, ReactNode } from "react";
import { noop } from "ts-essentials";

import { AttachmentsEditor } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor";
import { ModularDetailsAttachmentContext } from "@kraaft/shared/components/modular/details/editors/attachmentsEditor/attachmentsEditor.props";
import { DetailsEditorBox } from "@kraaft/shared/components/modular/details/editors/detailsEditorBox";
import { EditorsUtils } from "@kraaft/shared/components/modular/details/editors/editors.utils";
import {
  ModularDetailsEditorContext,
  ReadOnlyEditorProps,
  ReadWriteEditorProps,
} from "@kraaft/shared/components/modular/details/editors/types";
import {
  ColumnUpdater,
  modularColumnIsUpdateable,
  UpdateableModularColumnType,
} from "@kraaft/shared/components/modular/details/utils";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaColumnLiteralValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  ModularColumnContext,
  ModularDisplayExtendedRequirements,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";

interface Props {
  disabled: boolean;
  tableColumn: KSchemaColumn;
  value: KSchemaColumnLiteralValue;
  updateRecord: ColumnUpdater[UpdateableModularColumnType] | undefined;
  attachmentContext?: ModularDetailsAttachmentContext;
  editorContext?: ModularDetailsEditorContext;
  testID: string | undefined;
  columnContext: ModularColumnContext;
  sectionLockInfo: SectionLockInfo;
  recordType: ModularDisplayExtendedRequirements["recordType"];
}

const ModularDetailsFactory_ = ({
  disabled,
  tableColumn,
  value,
  updateRecord,
  attachmentContext,
  testID,
  editorContext,
  columnContext,
  sectionLockInfo,
  recordType,
}: Props) => {
  const EditorComponent = EditorsUtils.getEditor(tableColumn.type);
  let editorComponent: ReactNode;

  if (tableColumn.type === KColumnType.attachment && attachmentContext) {
    editorComponent = (
      <AttachmentsEditor
        column={tableColumn}
        value={value as KSchemaColumnLiteralValue<KColumnType.attachment>} // @TODO improve typing
        context={attachmentContext}
        testID={testID}
        isLocked={sectionLockInfo.isLocked}
        recordType={recordType}
        disabled={disabled}
      />
    );
  } else if (EditorComponent && modularColumnIsUpdateable(tableColumn)) {
    const editorProps: ReadWriteEditorProps<UpdateableModularColumnType> = {
      editor: {
        label: tableColumn.name,
        disabled,
        value,
        setValue: updateRecord ?? noop,
        context: editorContext ?? {},
        sectionLockInfo,
      },
      columnContext,
      column: tableColumn,
      testID,
    };

    editorComponent = <EditorComponent {...editorProps} />;
  } else if (EditorComponent) {
    const editorProps: ReadOnlyEditorProps<KColumnType> = {
      editor: {
        label: tableColumn.name,
        disabled,
        value,
        context: editorContext ?? {},
        sectionLockInfo,
      },
      columnContext,
      column: tableColumn,
      testID,
    };

    editorComponent = <EditorComponent {...editorProps} />;
  }

  if (!editorComponent) {
    return null;
  }

  return (
    <DetailsEditorBox
      columnKey={tableColumn.key}
      description={tableColumn.description}
      isLocked={sectionLockInfo.isLocked}
      label={tableColumn.name}
    >
      {editorComponent}
    </DetailsEditorBox>
  );
};

const ModularDetailsFactory = memo(ModularDetailsFactory_);

export { ModularDetailsFactory };
