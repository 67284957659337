import {
  KeyboardEventHandler,
  memo,
  MouseEventHandler,
  useCallback,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import isEqual from "fast-deep-equal";

import { MODULAR_LABEL_LIMIT } from "@kraaft/shared/constants/constants";
import {
  KSchemaColumn,
  KSchemaColumnDefinition,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ContextMenus } from "@kraaft/ui";
import { ContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";
import { EditableText } from "@kraaft/web/src/components/editableText";
import { EditableTextHandle } from "@kraaft/web/src/components/editableText/editableText";
import { getFieldProp } from "@kraaft/web/src/components/modularTable/components/fields";
import { ColumnIcon } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementIcon/columnIcon";
import { ElementCondition } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementCondition/elementCondition";
import { ElementMenuActionSheet } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementMenu.actionSheet";
import { ElementOptionsSheet } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementOptions.sheet";
import { ElementsEditorColumnItemKey } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorColumnItem/elementsEditorColumnItemKey";

import { ElementDescription } from "../elementDescription";
import { AnyColumnEditor } from "./anyColumnEditor";

import { useStyles } from "./elementsEditorColumnItem.styles";

export interface ElementsEditorItemProps {
  column: KSchemaColumn;
  onDelete: (key: string) => void;
  onChangeName: (key: string, newName: string, hasEditor: boolean) => void;
  onChangeDefinition: (
    key: string,
    newDefinition: KSchemaColumnDefinition,
  ) => void;
  creating?: boolean;
  editing?: boolean;
  onClick?: (key: string) => void;
  forceInstantDeletion?: boolean;
  nonDraggable: boolean;
}

const NAME_ID = "column-name";

// eslint-disable-next-line complexity
const ElementsEditorColumnItem_ = ({
  column,
  onDelete,
  editing,
  creating,
  onClick,
  onChangeDefinition,
  onChangeName,
  forceInstantDeletion = false,
  nonDraggable,
}: ElementsEditorItemProps) => {
  const classes = useStyles();
  const ColumnEditor = getFieldProp("columnEditor", column.type);
  const { t } = useTranslation();
  const inputRef = useRef<EditableTextHandle>(null);
  const [tempName, setTempName] = useState(column.name);
  const autofocus = useRef(false);

  const startName = useRef(column.name);
  startName.current = column.name;

  const internOnChangeDefinition = useCallback(
    (newDefinition: KSchemaColumnDefinition) => {
      onChangeDefinition(column.key, newDefinition);
    },
    [column.key, onChangeDefinition],
  );

  const { element: optionsElement, open: openElementOptions } =
    ElementOptionsSheet.use({
      element: column,
    });

  const { element: elementMenuActionSheet, open } = ElementMenuActionSheet.use({
    element: column,
    forceInstantDeletion: creating || forceInstantDeletion,
    onDelete,
    openOptions: openElementOptions,
  });

  const internOnChangeName = useCallback(() => {
    if (tempName.length === 0 && !creating) {
      setTempName(startName.current);
      return;
    }
    onChangeName(column.key, tempName, Boolean(ColumnEditor));
  }, [tempName, creating, onChangeName, column.key, ColumnEditor]);

  const blur = useCallback(() => {
    internOnChangeName();
    inputRef.current?.blur();
  }, [internOnChangeName]);

  const handleKey = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter" || event.key === "Escape") {
        inputRef.current?.blur();
      }
    },
    [],
  );

  const internOnClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      autofocus.current = (event.target as HTMLDivElement).id === NAME_ID;
      onClick?.(column.key);
    },
    [column.key, onClick],
  );

  const rootRef = useRef<HTMLDivElement>(null);
  ContextMenus.useFromActionSheet(rootRef, (anchor) =>
    open({ anchor: { current: anchor } }),
  );

  return (
    <>
      <div
        id="column-editor"
        onClick={internOnClick}
        role="button"
        className={classes.root}
        ref={rootRef}
      >
        <div className={classes.infos}>
          <div className={classes.left}>
            <ColumnIcon columnType={column.type} />
            <EditableText
              id={NAME_ID}
              editing={!!editing && !column.nonEditableName}
              ref={inputRef}
              inputLimit={MODULAR_LABEL_LIMIT.COLUMN_LABEL}
              overLimitHelperMessage={t("formBuilder.overLimitSectionLabel", {
                count: MODULAR_LABEL_LIMIT.COLUMN_LABEL,
              })}
              textInputProps={{
                className: classes.input,
                placeholder: t("formBuilder.schema.columnEditorPlaceholder"),
                onChangeText: setTempName,
                onKeyDown: handleKey,
                onBlur: blur,
                value: tempName,
                hasError:
                  startName.current.length > 0 && column.name.length === 0,
                disabledAutoComplete: true,
                autoFocus: autofocus.current || creating || !ColumnEditor,
              }}
            />
          </div>
          <div className={classes.right}>
            <ElementsEditorColumnItemKey column={column} hideKey={creating} />
            <ContextMenuButton onPress={(anchor) => open({ anchor })} />
          </div>
        </div>
        {KSchemaUtils.hasConditions(column.condition) && (
          <ElementCondition condition={column.condition} />
        )}
        {!KSchemaUtils.isDescriptionEmpty(column.description) && (
          <ElementDescription />
        )}
        {editing &&
          !creating &&
          !nonDraggable &&
          getFieldProp("columnEditor", column.type) && (
            <div className={classes.editorContainer}>
              <AnyColumnEditor
                column={column}
                onChangeDefinition={internOnChangeDefinition}
                forceInstantDeletion={forceInstantDeletion}
              />
            </div>
          )}
      </div>
      {elementMenuActionSheet}
      {optionsElement}
    </>
  );
};

export const ElementsEditorColumnItem = memo(
  ElementsEditorColumnItem_,
  isEqual,
) as typeof ElementsEditorColumnItem_;
