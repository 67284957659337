import { useMeshContext } from "@kraaft/helper-hooks";
import { AskUserJobForm } from "@kraaft/shared/components/onboarding/askUserJob/askUserJobForm";
import { CreatePoolFlowContext } from "@kraaft/shared/core/modules/pool/components/addPool/createPoolFlow/createPoolFlow.context";

export const UserJobStep = () => {
  const { userJob, setUserJob, otherUserJob, setOtherUserJob } = useMeshContext(
    CreatePoolFlowContext,
  );

  return (
    <AskUserJobForm
      setSelected={setUserJob}
      setOther={setOtherUserJob}
      selected={userJob}
      other={otherUserJob}
    />
  );
};
