import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { Button, Spacing } from "@kraaft/ui";

interface CreatePoolButtonProps {
  onPress: () => void;
}

export const CreatePoolButton = ({ onPress }: CreatePoolButtonProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.buttonContainer}>
      <Button
        variant={isNative() ? "PRIMARY" : "QUATERNARY"}
        onPress={onPress}
        icon="plus"
        text={t("addPool.addPool")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    paddingVertical: Spacing.S4,
    paddingHorizontal: Spacing.S16,
  },
});
