import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet } from "react-native";

import { KInput } from "@kraaft/shared/components/input/KInput";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { userJobs } from "@kraaft/shared/components/onboarding/askUserJob/userJob";

interface AskUserJobFormProps {
  setSelected: (value: string) => void;
  setOther: (value: string) => void;
  selected: string;
  other: string;
}

export const OTHER_USER_JOB_IDENTIFIER = "other";

export const AskUserJobForm = ({
  selected,
  setSelected,
  other,
  setOther,
}: AskUserJobFormProps) => {
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (newSelection: string[]) => {
      const [newSelected] = newSelection;
      if (!newSelected) {
        return;
      }
      setSelected(newSelected);
    },
    [setSelected],
  );

  const items = useMemo(
    () => [
      ...userJobs.map((job) => ({
        label: t(`userJob.jobs.${job}`),
        identifier: job,
      })),
      { label: t("userJob.jobs.other"), identifier: OTHER_USER_JOB_IDENTIFIER },
    ],
    [t],
  );

  return (
    <>
      <ScrollView style={styles.scrollView}>
        <KSelectionList
          selected={[selected]}
          setSelected={handleSelect}
          items={items}
          noPadding
          disableScroll
        />
      </ScrollView>
      {selected === OTHER_USER_JOB_IDENTIFIER && (
        <KInput
          label={t("userJob.fillYourJob")}
          value={other}
          onChangeText={setOther}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 0,
    flexShrink: 1,
  },
});
