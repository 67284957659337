import { CSSProperties, FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { Color, ColorStyle, FontSize, Icon, Radius } from "@kraaft/ui";

const styles: CSSProperties = {
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
  backgroundColor: ColorStyle.ACTION_CTA,
  color: Color.WHITE,
  fontSize: FontSize.SMALL,
  borderRadius: Radius.SMALL,
  padding: "8px 12px",
  transform: "translate(-105%, -105%)",
};

export interface DragPreviewMessageProps {
  item: unknown;
}

export const DragPreviewMessageImage: FC<DragPreviewMessageProps> = memo(() => {
  const { t } = useTranslation();

  return (
    <div style={styles}>
      <Icon name="image-03" size="MEDIUM" />
      <span>{t("linkPictureToFolder")}</span>
    </div>
  );
});

export const DragPreviewMessageDocument: FC<DragPreviewMessageProps> = memo(
  () => {
    const { t } = useTranslation();

    return (
      <div style={styles}>
        <Icon name="file-04" size="MEDIUM" />
        <span>{t("linkDocumentToFolder")}</span>
      </div>
    );
  },
);

export const DragPreviewMessageText: FC<DragPreviewMessageProps> = memo(() => {
  const { t } = useTranslation();

  return (
    <div style={styles}>
      <Icon name="message-circle-01" size="MEDIUM" />
      <span>{t("linkTextToFolder")}</span>
    </div>
  );
});

export const DragPreviewMessageGeolocation: FC<DragPreviewMessageProps> = memo(
  () => {
    const { t } = useTranslation();

    return (
      <div style={styles}>
        <Icon name="message-circle-01" size="MEDIUM" />
        <span>{t("linkGeolocationToFolder")}</span>
      </div>
    );
  },
);

export const DragPreviewMessageVideo: FC<DragPreviewMessageProps> = memo(() => {
  const { t } = useTranslation();

  return (
    <div style={styles}>
      <Icon name="play" size="MEDIUM" />
      <span>{t("linkVideoToFolder")}</span>
    </div>
  );
});
