import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { Company } from "@kraaft/shared/core/modules/company/company.state";
import { Button, Spacing } from "@kraaft/ui";
import { ColorPicker } from "@kraaft/web/src/components/colorPicker";
import { PopupMenuProvider } from "@kraaft/web/src/components/popupMenu";

interface CompanyLineProps {
  company: Company;
  onDelete: (companyId: string) => Promise<void>;
  onChangeColor: (companyId: string, color: string) => void;
}

export const CompanyLine = ({
  company,
  onDelete,
  onChangeColor,
}: CompanyLineProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const doDelete = useCallback(async () => {
    setLoading(true);
    await onDelete(company.id);
    setLoading(false);
  }, [company.id, onDelete]);

  const handleDeletion = useCallback(async () => {
    newConfirmationAlert({
      title: t("support.deleteCompanyConfirmation", {
        companyName: company.name,
      }),
      confirmText: t("del"),
      onConfirm: doDelete,
      style: "destructive",
    });
  }, [company.name, doDelete, t]);

  const handleChangeColor = useCallback(
    (color: string) => {
      onChangeColor(company.id, color);
    },
    [company.id, onChangeColor],
  );

  return (
    <div className={classes.root}>
      {company.name}
      <div className={classes.right}>
        <PopupMenuProvider>
          <ColorPicker value={company.color} onChange={handleChangeColor} />
        </PopupMenuProvider>
        <Button
          onPress={handleDeletion}
          icon="trash-01"
          loading={loading}
          variant="TERTIARY"
          accessibilityLabel={t("del")}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
});
