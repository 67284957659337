import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AlertDialog } from "@kraaft/shared/components/alertDialog/index";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  WorkflowOptimisticActions,
  WorkflowOptimisticOperations,
} from "@kraaft/shared/core/modules/workflows/workflow.optimistic";
import { WorkflowActions } from "@kraaft/shared/core/modules/workflows/workflowActions";
import { ActionSheetContent, ActionSheetItem } from "@kraaft/ui";

export interface WorkflowContextMenuSheetContentProps {
  workflowId: string;
  closeSheet: () => void;
}

export const WorkflowContextMenuSheetContent = ({
  workflowId,
  closeSheet,
}: WorkflowContextMenuSheetContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const duplicateWorkflowLoaderId = `duplicate-workflow-${workflowId}`;
  const duplicateWorkflowLoader = useSelector(
    selectLoader(duplicateWorkflowLoaderId),
  );
  const [shouldCloseAfterLoading, setShouldCloseAfterLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      duplicateWorkflowLoader &&
      duplicateWorkflowLoader.status !== LoaderStatus.LOADING &&
      shouldCloseAfterLoading
    ) {
      closeSheet();
    }
  }, [
    closeSheet,
    dispatch,
    duplicateWorkflowLoader,
    duplicateWorkflowLoader?.status,
    duplicateWorkflowLoaderId,
    shouldCloseAfterLoading,
  ]);

  const handleDuplicateWorkflow = useCallback(() => {
    setShouldCloseAfterLoading(true);
    dispatch(
      WorkflowActions.duplicate({
        workflowId: workflowId,
        loaderId: duplicateWorkflowLoaderId,
      }),
    );
  }, [dispatch, duplicateWorkflowLoaderId, workflowId]);

  const handleDeleteWorkflow = useCallback(() => {
    closeSheet();
    AlertDialog.alert(t("deleteWorkflowTitle"), "", [
      {
        style: "destructive",
        text: t("confirm"),
        onPress: () =>
          dispatch(
            WorkflowOptimisticActions.addOperation(
              WorkflowOptimisticOperations.delete.create({
                targetId: workflowId,
              }),
            ),
          ),
      },
      { style: "cancel", text: t("cancel") },
    ]);
  }, [closeSheet, dispatch, t, workflowId]);

  return (
    <ActionSheetContent>
      <ActionSheetItem
        icon="copy-06"
        label={t("duplicate")}
        onClick={handleDuplicateWorkflow}
        loading={duplicateWorkflowLoader?.status === LoaderStatus.LOADING}
      />
      <ActionSheetItem
        destructive
        icon="trash-01"
        label={t("del")}
        onClick={handleDeleteWorkflow}
      />
    </ActionSheetContent>
  );
};
