import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { LottieManager } from "@kraaft/shared/components/lottieManager";
import CreatingWorksiteLottie from "@kraaft/shared/components/onboarding/loaderCreatingPool/assets/truck.json";
import { Text } from "@kraaft/ui";

export const CreationLoadingStep = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <LottieManager
        style={styles.lottieContainer}
        source={CreatingWorksiteLottie}
        loop
        autoPlay
      />
      <Text size="H1" weight="bold">
        {t("onboarding.loadingCreatePool")}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  lottieContainer: {
    height: 300,
    width: 300,
  },
});
