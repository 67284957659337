import { MutableRefObject, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { selectIsLoading } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { deselectMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { useGalleryFooterActions } from "@kraaft/shared/core/utils/mediaSelection/footers/useMediaFooterActions";

export const useDirectoryListActions = ({
  roomId,
  directoryId,
  setSheetOpen,
  moreButtonAnchorRef,
  removeLoaderId,
  downloadLoaderId,
}: {
  roomId: string;
  directoryId: string;
  setSheetOpen: () => void;
  moreButtonAnchorRef: MutableRefObject<null>;
  removeLoaderId: string;
  downloadLoaderId: string;
}) => {
  const isDownloadLoading = useSelector(selectIsLoading(downloadLoaderId));
  const isRemoveLoading = useSelector(selectIsLoading(removeLoaderId));

  const canDelete = Guard.use("Directory.delete");

  const { onDownload, onRemoveFromDirectory } = useGalleryFooterActions({
    roomId,
    selectionSource: "directoryList",
    downloadLoaderId,
    removeLoaderId,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clearSelection = useCallback(() => {
    dispatch(deselectMessage({ roomId, all: true }));
  }, [dispatch, roomId]);

  const options = useMemo<ActionSheetItem[]>(
    () => [
      ...(canDelete
        ? [
            {
              label: t("directoryActions.remove"),
              icon: "link-broken-01",
              onPress: () => onRemoveFromDirectory(directoryId),
            } as ActionSheetItem,
          ]
        : []),
    ],
    [canDelete, directoryId, onRemoveFromDirectory, t],
  );

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("download"),
        text: t("download"),
        icon: "download-01",
        variant: "PRIMARY",
        onPress: onDownload,
        loading: isDownloadLoading,
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: clearSelection,
      },
      ...(options.length
        ? [
            {
              accessibilityLabel: t("moreOptions"),
              icon: "dots-horizontal",
              onPress: setSheetOpen,
              ref: moreButtonAnchorRef,
              loading: isRemoveLoading,
            } as FooterActions[number],
          ]
        : []),
    ],
    [
      clearSelection,
      isDownloadLoading,
      isRemoveLoading,
      moreButtonAnchorRef,
      onDownload,
      setSheetOpen,
      t,
      options,
    ],
  );

  return { actions, options };
};
