import { GeolocationDetailStatus } from "@kraaft/shared/core/utils/useMapNavigation/types";

export const routes = {
  // Login
  SignIn: "/login",
  ConfirmationCode: "/login/code",
  SignInEmail: "/login/email",
  SignInPhone: "/login/phone",
  EnterPassword: "/login/password",
  ForgotPasswordEmailSent: "/login/reset-password",
  SignInSSO: "/login/sso",
  EnterUsername: "/login/name",
  CongratsScreen: "/login/team",
  WelcomeOnKraaft: "/login/no-team",
  CreateWorkspace: "/create-workspace",
  VerifyInvitation: "/invites/:team/:id",
  InvalidInvitation: "/invite-failed",

  OnboardingAskPoolCompanySize: "/onboarding/ask-pool-company-size",
  OnboardingAskPoolCompanyIndustry: "/onboarding/ask-pool-company-industry",
  OnboardingAskPoolAcquisitionSource: "/onboarding/ask-pool-acquisition-source",
  OnboardingAskUserJob: "/onboarding/ask-user-job",
  OnboardingAskUserEmail: "/onboarding/ask-user-email",
  OnboardingInviteToPool: "/onboarding/invite-to-pool",
  OnboardingPoolCreationLoader: "/onboarding/create-pool-loader",

  // Layout Routes
  Messenger: "/conversations",
  Room: "/conversations/:roomId",
  ConversationFolder: "/conversations/:roomId/folder",
  ConversationInfo: "/conversations/:roomId/info",
  Database: "/database",
  ModularFoldersTable: "/database/folders/:schemaId",
  ConversationTable: "/database/conversations",
  ModularFoldersTableForRoom:
    "/database/folders/:schemaId/conversation/:roomId",
  SchemaView: "/database/views/:schemaViewId",

  //  Admin
  ManageWorkflows: "/workflows",

  // Schemas
  SchemasView: "/schemas",
  FolderSchemaBuilder: "/schemas/:schemaId",
  SchemaLibraryView: "/schema-library",
  LibrarySchemaShowcase: "/schema-library/:librarySchemaId",

  // Settings
  Settings: "/settings",
  Profile: "/settings/profile",
  ManagePool: "/settings/pool",
  Billing: "/settings/billing",
  ManageDefaultDirectories: "/settings/directories",
  ManageMembers: "/settings/members",
  ManageTaskTemplates: "/settings/tasktemplates",
  ManageTemplates: "/settings/templates",
  ManageChecklist: "/settings/tasks",
  Analytics: "/settings/analytics",
  Integrations: "/settings/integration",
  RoomSchemaBuilder: "/settings/conversations",
  ManageNotifications: "/settings/notifications",

  // Special
  ManageMapOverlays: "/settings/map-overlays",

  // SuperAdmin
  Superadmin: "/settings/superadmin",
  SchemaLibraryList: "/settings/schema-library",
  LibrarySchemaBuilder: "/settings/schema-library/:librarySchemaId",
  IdentityProviders: "/settings/identity-providers",

  Sandbox: "/sandbox",

  // sharepoint
  SharepointSuccess: "/sharepoint/success",
  SharepointFailure: "/sharepoint/failure",
};

export enum queries {
  poolId = "teamId",
  labelId = "labelId",
  viewMode = "view",
  detailScreen = "detail",
  folderId = "folderId",
  directoryId = "directoryId",
  schemaId = "schemaId",
  geoDetail = "geoDetail",
  animateBack = "animateBack",
  roomFilter = "filterBy",
  format = "format",
  previousDetailScreen = "previousDetail",
  tab = "tab",
  workflowId = "workflowId",
  tagId = "tagId",
}

export enum archivedQuery {
  true = "true",
  false = "false",
}

export enum animateBackQuery {
  true = "true",
  false = "false",
}

export enum viewModeQuery {
  conversation = "conversation",
  geolocation = "geolocation",
}

export const geoDetailQuery: Record<
  GeolocationDetailStatus,
  GeolocationDetailStatus
> = {
  rooms: "rooms",
  room: "room",
  modularFolder: "modularFolder",
};

export const geoQuery = {
  initialCenter: "initialCenter",
} as const;

export enum detailsQuery {
  modularFolders = "modular-folders",
  modularFolder = "modular-folder",
  modularFolderHistory = "modular-folder-history",
  directory = "directory",

  responsible = "responsible",
  labels = "labels",
  editMembers = "editMembers",
  members = "members",
  status = "status",
  location = "location",

  sharepointConnector = "sharepoint-connector",

  report = "report",

  photoGallery = "photo-gallery",
  documentGallery = "document-gallery",

  roomSchemaVisibility = "visible-reports",
}

export const ModularFolderDetailsScreenQueryParam = {};
