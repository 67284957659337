import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { search } from "fast-fuzzy";
import take from "lodash/take";

import { SearchBar } from "@kraaft/shared/components/searchBar";
import { SearchBarProps } from "@kraaft/shared/components/searchBar/searchBar";
import { selectGridLibrarySchemasForLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { selectCurrentPoolLanguage } from "@kraaft/shared/core/modules/pool/poolSelectors";

import {
  SchemaLibrarySuggestionsSheet,
  useSuggestionSheetNavigableRef,
} from "./schemaLibrarySuggestionsSheet";

export type SchemaLibrarySearchBarProps = {
  onSelectSchema: (librarySchemaID: string) => void;
  maxSuggestions?: number;
};

export const SchemaLibrarySearchBar = ({
  onSelectSchema,
  maxSuggestions,
}: SchemaLibrarySearchBarProps) => {
  const searchBarRef = useRef(null);
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");

  const currentPoolLanguage = useSelector(selectCurrentPoolLanguage);

  const librarySchemas = useSelector(
    selectGridLibrarySchemasForLanguage(currentPoolLanguage),
  );

  const filteredLibrarySchema = useMemo(() => {
    const filtered = search(searchValue, librarySchemas, {
      keySelector: (item) => item.name,
    });
    return maxSuggestions ? take(filtered, maxSuggestions) : filtered;
  }, [maxSuggestions, librarySchemas, searchValue]);

  const handleOnSelectSchema = useCallback(
    (librarySchema: LibrarySchema) => {
      onSelectSchema(librarySchema.id);
    },
    [onSelectSchema],
  );

  const sheetRef = useSuggestionSheetNavigableRef();

  const { element, open, close } = SchemaLibrarySuggestionsSheet.use({
    bubbleOnClose: true,
    followRefWidth: true,
    withoutBackdrop: true,
    lockPlacement: true,
    preventOverflow: true,
    disableFocus: true,
    placement: "bottom",
    anchor: searchBarRef,
    items: filteredLibrarySchema,
    onClick: handleOnSelectSchema,
    suggestionSheetRef: sheetRef,
  });

  useEffect(() => {
    if (filteredLibrarySchema.length > 0) {
      open();
    } else {
      close();
    }
  }, [filteredLibrarySchema.length, open, close]);

  const handleOnFocus = useCallback(() => {
    if (filteredLibrarySchema.length > 0) {
      open();
    }
  }, [filteredLibrarySchema, open]);

  const handleOnKeyPress = useCallback<
    NonNullable<SearchBarProps["onKeyPress"]>
  >(
    (event) => {
      if (filteredLibrarySchema.length === 0) {
        return;
      }
      const keyboardEvent = event.nativeEvent as KeyboardEvent;
      if (keyboardEvent.code === "Enter") {
        sheetRef.current?.selectActive?.();
        event.preventDefault();
      } else if (keyboardEvent.code === "ArrowUp") {
        sheetRef.current?.up?.();
        event.preventDefault();
      } else if (keyboardEvent.code === "ArrowDown") {
        sheetRef.current?.down?.();
        event.preventDefault();
      }
    },
    [filteredLibrarySchema.length, sheetRef],
  );

  return (
    <View ref={searchBarRef} style={styles.container}>
      <SearchBar
        isStatic
        withPlaceholderIcon
        value={searchValue}
        onChange={setSearchValue}
        placeholder={t("search")}
        onFocus={handleOnFocus}
        onKeyPress={handleOnKeyPress}
      />
      {element}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 400,
  },
});
