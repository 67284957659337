import { and, granted, GuardParams } from "./guards.tools";
import { MessageGuards } from "./message.guards";
import { Access, Path } from "./types";

export const Guards = {
  Room: {
    create: granted("Room.create"),
    editRecord: granted("Room.edit"),
    archive: granted("Room.archive"),
    unarchive: granted("Room.unarchive"),
    toggleSchemas: and(granted("Room.edit"), granted("Schema.view")),
  },
  Directory: {
    create: granted("Room.talk"),
    addFile: granted("Room.talk"),
    rename: granted("Room.correct"),
    delete: granted("Room.correct"),
    manageRoomTree: granted("Room.correct"),
    removeFile: granted("Room.correct"),
  },
  ModularFolder: {
    create: and(granted("Room.talk"), granted("Record.create")),
    delete: and(granted("Room.edit"), granted("Record.delete")),
  },
  Message: MessageGuards,
  Members: {
    listPoolMembers: granted("Members.view"),
  },
  Schema: {
    listSchemas: granted("Schema.view"),
  },
};

export type Capabilities = Path<typeof Guards>;
export type GuardOf<C extends Capabilities> = Access<typeof Guards, C>;
export type GuardParamsOf<C extends Capabilities> = GuardParams<GuardOf<C>>;
