import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import {
  MessageTypes,
  utils as messageUtils,
} from "@kraaft/shared/core/modules/message";
import { deleteOptimisticMessage } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { removeMessageRequest } from "@kraaft/shared/core/modules/room/roomActions";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { i18n } from "@kraaft/shared/core/services/i18next";

interface UseRemoveMessageProps {
  roomId: string;
}

export const useRemoveMessage = ({ roomId }: UseRemoveMessageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const guardDelete = Guard.useGuard("Message.delete");

  return useCallback(
    (message: MessageTypes.Message) => {
      if (!messageUtils.isPersisted(message)) {
        return dispatch(
          deleteOptimisticMessage({ roomId, optimisticId: message.id }),
        );
      }

      const [canDelete] = guardDelete(message);
      if (!canDelete) {
        return AlertDialog.alert(
          i18n.t("messageCannotBeRemovedAlertTitle"),
          i18n.t("messageCannotBeRemovedAlertContent"),
          [
            {
              text: i18n.t("cancel"),
              style: "cancel",
            },
          ],
        );
      }

      return newConfirmationAlert({
        title: t("removeMessageAlertTitle"),
        message: t("removeMessageAlertContent"),
        confirmText: t("del"),
        onConfirm: () =>
          dispatch(
            removeMessageRequest({
              messageId: message.id,
            }),
          ),
      });
    },
    [t, dispatch, roomId, guardDelete],
  );
};
