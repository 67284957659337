import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { ComplexHeaderLayout } from "@kraaft/shared/components/defaultLayoutHeader/complexHeaderLayout";
import { DefaultLayoutHeaderContentProps } from "@kraaft/shared/components/defaultLayoutHeader/defaultLayoutHeader.types";
import { SimpleHeaderLayout } from "@kraaft/shared/components/defaultLayoutHeader/simpleHeaderLayout";
import { useSafeAreaInsets } from "@kraaft/shared/core/utils/useSafeAreaInsets";
import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export interface DefaultLayoutHeaderProps
  extends DefaultLayoutHeaderContentProps {
  id?: string;
  transparent?: boolean;
  disableSafeAreaBehavior?: boolean;
  children?: React.ReactNode;
  noDivider?: boolean;
  noFixedHeight?: boolean;
  backgroundColor?: React.CSSProperties["backgroundColor"];
}

export const DefaultLayoutHeader = (props: DefaultLayoutHeaderProps) => {
  const {
    children,
    id,
    transparent,
    disableSafeAreaBehavior,
    noDivider = false,
    noFixedHeight = false,
    backgroundColor,
    ...headerContentProps
  } = props;

  const safeAreaInsets = useSafeAreaInsets();

  const headerStyle = useMemo(
    () => [
      !transparent && styles.baseColors,
      backgroundColor && { backgroundColor },
      !noDivider && styles.divider,
      !disableSafeAreaBehavior && { paddingTop: safeAreaInsets.top },
    ],
    [
      backgroundColor,
      disableSafeAreaBehavior,
      noDivider,
      safeAreaInsets.top,
      transparent,
    ],
  );

  const hasMiddlePart =
    headerContentProps.header || headerContentProps.headerTitle;

  return (
    <View style={headerStyle}>
      <View
        nativeID={id}
        testID={id}
        style={[
          styles.headerStyle,
          noFixedHeight ? styles.noFixedHeight : styles.fixedHeight,
        ]}
      >
        {children ??
          (hasMiddlePart ? (
            <ComplexHeaderLayout {...headerContentProps} />
          ) : (
            <SimpleHeaderLayout {...headerContentProps} />
          ))}
      </View>
    </View>
  );
};

const FIXED_HEADER_HEIGHT = isNative() ? 54 : 49;

const styles = StyleSheet.create({
  baseColors: {
    backgroundColor: Color.WHITE,
  },
  divider: {
    borderBottomColor: ColorStyle.SEPARATOR,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderStyle: "solid",
  },
  fixedHeight: {
    height: FIXED_HEADER_HEIGHT,
  },
  noFixedHeight: {
    paddingTop: Spacing.S12,
  },
  headerStyle: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
});
