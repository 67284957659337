import { UserPoolRole } from "../../firestore/firestoreTypes";

export type Actor = {
  userId: string;
  claims: {
    support: boolean;
    pools: {
      [key: string]: {
        role: UserPoolRole;
      };
    };
  };
};

export function getActorRoleInPool(actor: Actor, poolId: string) {
  if (actor.claims.support) {
    return UserPoolRole.OWNER;
  }
  return actor.claims.pools[poolId]?.role || UserPoolRole.OUTSIDER;
}
