import {
  Capabilities,
  GuardOf,
  GuardParamsOf,
} from "@kraaft/shared/core/services/auth/permissions/guards";
import { getGuard } from "@kraaft/shared/core/services/auth/permissions/hooks";

import { Actor, getActorRoleInPool } from "./actor";
import { AllGrants, defaultRolesGrants } from "./roles";
import { access, Path } from "./types";

export const getRoomPermissions = (actor: Actor, poolId: string) => {
  const role = getActorRoleInPool(actor, poolId);
  return defaultRolesGrants[role]?.Room;
};

export function granted<P extends Path<AllGrants>>(specifier: P): Guard {
  return (actor: Actor, poolId: string) => {
    const role = getActorRoleInPool(actor, poolId);
    const roleGrants = defaultRolesGrants[role];
    if (!roleGrants) {
      return [false, "unauthorized"] as const;
    }
    const hasGrant = access(roleGrants, specifier);
    return hasGrant ? ([true] as const) : ([false, "unauthorized"] as const);
  };
}

export const and = <Args extends any[]>(
  ...condition: Array<(...args: Args) => GuardOutput>
) => {
  return (...args: Args) => {
    const hasGrant = condition.every((c) => c(...args)[0]);
    return hasGrant ? ([true] as const) : ([false, "unauthorized"] as const);
  };
};

export type GuardOutput = readonly [true] | readonly [false, reason: string];

export type Guard<Args extends any[] = any[]> = (
  actor: Actor,
  poolId: string,
  ...args: Args
) => GuardOutput;

export type GuardParams<G extends Guard> = G extends Guard<infer Args>
  ? Args
  : [];

export function checkGuard<C extends Capabilities>(
  cap: C,
  actor: Actor | undefined,
  poolId: string | undefined,
  ...args: GuardParamsOf<C>
) {
  const guard = getGuard(cap);
  if (!actor || !poolId) {
    return [false, "unauthorized"] as const;
  }

  return guard(actor, poolId, ...args) as ReturnType<GuardOf<C>>;
}
