import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RoomList } from "@kraaft/shared/components/roomList";
import { RoomListEmptyState } from "@kraaft/shared/components/roomList/emptyState/roomListEmptyState";
import { RoomListProvider } from "@kraaft/shared/components/roomList/roomListProvider";
import { selectRoomFilterArchived } from "@kraaft/shared/core/modules/filter/filterSelectors";
import { clearLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentContextAtLeastStandardOrSuperadmin } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { geolocationContextHover } from "@kraaft/shared/core/modules/room/roomActions";
import { getSubscribeToRoomLoaderId } from "@kraaft/shared/core/modules/room/roomUtils";
import { useLastReadRoomId } from "@kraaft/shared/core/modules/roomCard/hooks/useLastReadRoomId";
import { useRoomCards } from "@kraaft/shared/core/modules/roomCard/hooks/useRoomCards";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { useShouldShowHomeEmptyState } from "@kraaft/shared/core/modules/user/homeEmptyState.hooks";
import { useAppRoomFilters } from "@kraaft/shared/core/utils/useAppRoomFilters";
import { useMapNavigation } from "@kraaft/shared/core/utils/useMapNavigation";
import { AddOrImportRoomButton } from "@kraaft/web/src/components/addRoomButton/addOrImportRoomButton";
import { useNavigationService } from "@kraaft/web/src/core/services/navigation/useNavigationService";
import { useConversationLayoutInfo } from "@kraaft/web/src/views/messenger/useConversationLayoutInfo";

import { useRoomAutoNavigation } from "./useRoomAutoNavigation";

interface Props {
  onAddRoomButtonPress: () => void;
}

const Rooms = (props: Props) => {
  const { onAddRoomButtonPress } = props;
  const params: { roomId?: string } = useParams();
  const navigationService = useNavigationService();

  const dispatch = useDispatch();
  const currentPoolId = useSelector(selectCurrentPoolId);
  const isArchived = useSelector(selectRoomFilterArchived(currentPoolId));

  const shouldShowEmptyState = useShouldShowHomeEmptyState();

  const { canDisplayConversation } = useConversationLayoutInfo();

  const mapNavigation = useMapNavigation();

  const canAddRoom = useSelector(
    selectCurrentContextAtLeastStandardOrSuperadmin,
  );

  const { filters } = useAppRoomFilters();
  const justReadRoomId = useLastReadRoomId();
  const { pinnedRoomCards, otherRoomCards } = useRoomCards({
    poolId: currentPoolId,
    filters,
    justReadRoomId,
  });

  const roomCards = useMemo(
    () => [...pinnedRoomCards, ...otherRoomCards],
    [otherRoomCards, pinnedRoomCards],
  );

  const handleRoomCardPress = useCallback(
    (roomCard: AnyRoomCard) => {
      navigationService.clickOnConversationFromRoomList(roomCard.roomId);
    },
    [navigationService],
  );

  const handleRoomCardHover = useCallback(
    (roomCard: AnyRoomCard | undefined) => {
      dispatch(
        geolocationContextHover({
          hovered: roomCard !== undefined,
          id: roomCard?.roomId,
        }),
      );
    },
    [dispatch],
  );

  const navigation = useMemo(
    () => ({
      navigateToHome: () => navigationService.navigate("Home"),
      changeConversation: (roomId: string) =>
        navigationService.changeConversation(roomId),
      openConversation: (roomId: string) =>
        navigationService.navigate(
          canDisplayConversation ? "ConversationFolder" : "Conversation",
          { roomId },
        ),
    }),
    [canDisplayConversation, navigationService],
  );

  useRoomAutoNavigation({
    navigation,
    paramsRoomId: params.roomId,
    isMapOpen: mapNavigation.isOpen,
    filters,
    roomCards,
  });

  // handle navigation error (for example, arriving on a roomId but I don't have the permission to see it)
  const loader = useSelector(
    selectLoader(getSubscribeToRoomLoaderId(params.roomId)),
  );
  useEffect(() => {
    if (loader?.status === LoaderStatus.FAILURE) {
      dispatch(clearLoader(loader.id));
      navigationService.navigate("Home");
    }
  }, [dispatch, loader, navigationService]);

  return shouldShowEmptyState ? (
    <RoomListEmptyState onPressCreate={onAddRoomButtonPress} />
  ) : (
    <RoomListProvider>
      <RoomList
        onItemHover={handleRoomCardHover}
        onItemPress={handleRoomCardPress}
      />
      {!isArchived && canAddRoom && currentPoolId ? (
        <AddOrImportRoomButton onPressCreate={onAddRoomButtonPress} />
      ) : null}
    </RoomListProvider>
  );
};

export { Rooms };
