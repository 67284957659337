import { call, put, takeLatest } from "typed-redux-saga/macro";

import { UserContactInfosSlice } from "@kraaft/shared/core/modules/userContactInfos/userContactInfos.reducer";
import { Api } from "@kraaft/shared/core/services/api";
import { PayloadActionFrom } from "@kraaft/shared/core/types";

export function* userContactInfoSaga() {
  yield* takeLatest(
    UserContactInfosSlice.actions.getUserContactInfos,
    fetchUserContactInfo,
  );
}

export function* fetchUserContactInfo({
  payload,
}: PayloadActionFrom<
  typeof UserContactInfosSlice.actions.getUserContactInfos
>) {
  try {
    const userContactInfos = yield* call(Api.getUserContactInfo, payload);

    yield* put(
      UserContactInfosSlice.actions.userContactInfosSuccess({
        userContactInfos,
      }),
    );
  } catch (e) {
    if (e instanceof Error) {
      yield* put(
        UserContactInfosSlice.actions.userContactInfosFailure({
          error: e.message,
          userId: payload.userId,
        }),
      );
    }
  }
}
