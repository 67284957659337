import { ActionPattern } from "@redux-saga/types";
import { ActionCreatorWithPayload, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { EventChannel, Task } from "redux-saga";
import {
  call,
  cancel,
  debounce,
  FixedTask,
  fork,
  select,
  take,
  takeEvery,
} from "typed-redux-saga/macro";

import { RootState } from "@kraaft/shared/core/store";
import { AnyUnexplained, NotUndefined } from "@kraaft/shared/core/types";
import { wait } from "@kraaft/shared/core/utils/promiseUtils";

export function* waitFor<T>(
  selector: (state: RootState) => T,
): Generator<unknown, NonNullable<T>> {
  while (true) {
    const result = yield* select(selector);
    if (result) {
      return result;
    }
    yield* take("*");
  }
}

export function* waitOnFor<T>(
  actions: ActionPattern<Action<any>>,
  selector: (state: RootState) => T,
): Generator<unknown, NonNullable<T>> {
  while (true) {
    const result = yield* select(selector);
    if (result) {
      return result;
    }
    yield* take(actions);
  }
}

// Allows to take leading by giving an id from the payload
// Won't call saga if task already exists
export const takeLeadingDeep = <P>(
  pattern: ActionCreatorWithPayload<P>,
  identifier: (action: PayloadAction<P>) => string,
  fn: (action: PayloadAction<P>) => void,
) =>
  fork(function* () {
    const tasks: Record<string, Task> = {};
    while (true) {
      const action: ReturnType<typeof pattern> = yield* take(pattern);
      const id = identifier(action);

      const task = tasks[id];

      if (task) {
        continue;
      }

      let isTaskEnded = false;
      const newTask: Task = yield* fork(function* () {
        yield* call(fn, action);
        delete tasks[id];
        isTaskEnded = true;
      });

      if (!isTaskEnded) {
        tasks[id] = newTask;
      }
    }
  });

/* eslint-disable @typescript-eslint/no-explicit-any */
export const takeCounted = <
  IncreasingAction extends ActionCreatorWithPayload<any>,
  DecreasingAction extends ActionCreatorWithPayload<any>,
  Meta,
  RegisterFn extends (
    registerMeta: (meta: Meta) => void,
    ...args: any[]
  ) => void,
  UnregisterFn extends (
    meta: Parameters<Parameters<RegisterFn>["0"]>["0"],
    ...args: any[]
  ) => any,
>(
  increasing: IncreasingAction,
  decreasing: DecreasingAction,
  register: RegisterFn,
  unregister: UnregisterFn,
) =>
  fork(function* () {
    const currentState: {
      count: number;
      meta: Meta | undefined;
      currentTask: Task | undefined;
    } = {
      count: 0,
      currentTask: undefined,
      meta: undefined,
    };

    function* increase(action: any) {
      if (currentState.count === 0) {
        const registerMeta = (meta: Meta) => {
          currentState.meta = meta;
        };

        const createdTask = yield* fork(register as any, registerMeta, action);
        currentState.currentTask = createdTask;
      }
      currentState.count += 1;
    }

    function* decrease(action: any) {
      // if we will unregister, wait a bit for another subscription
      if (currentState.count === 1) {
        yield wait(0);
      }

      currentState.count -= 1;
      if (currentState.count === 0) {
        if (currentState.currentTask) {
          yield* cancel(currentState.currentTask);
        }
        yield* fork(unregister as any, currentState.meta, action);
      }
      if (currentState.count < 0) {
        currentState.count = 0;
      }
    }

    yield* takeEvery(increasing, increase);
    yield* takeEvery(decreasing, decrease);
  });

/**
 * This saga helper manages subscriptions efficiently by ensuring that each unique identifier has only one active subscription at a time, even if multiple components or pages request it.
 * The `identifier` function generates a unique key from each action's payload to track subscriptions.
 * When multiple components subscribe to the same key, only one subscription is maintained, with a counter tracking the number of subscribers. Once the counter hits zero, the subscription is automatically terminated.
 * Example usage:
 *
 * yield takeCountedDeep(
 *   MessageDataActions.subscribe,
 *   MessageDataActions.unsubscribe,
 *   subscribeToMessages,
 *   unsubscribeFromMessages,
 *   (action) => action.payload.roomId,
 * );
 *
 * // Initiates subscription for roomId 1234
 * yield put(MessageDataActions.subscribe({ roomId: 1234 }));
 *
 * // Increases counter for roomId 1234, no new subscription
 * yield put(MessageDataActions.subscribe({ roomId: 1234 }));
 *
 * // Decreases counter, subscription remains active
 * yield put(MessageDataActions.unsubscribe({ roomId: 1234 }));
 *
 * // Unsubscribes when counter reaches zero for roomId 1234
 * yield put(MessageDataActions.unsubscribe({ roomId: 1234 }));
 *
 * @param increasing - Action to trigger subscription increase
 * @param decreasing - Action to trigger subscription decrease
 * @param register - Function to initiate a subscription
 * @param unregister - Function to terminate a subscription
 * @param identifier - Function to derive a unique key from actions
 * @param reloading - Optional action for reloading subscriptions
 * @returns A generator function managing the subscription lifecycle
 */
export const takeCountedDeep = <
  IncreasingAction extends ActionCreatorWithPayload<any>,
  DecreasingAction extends ActionCreatorWithPayload<any>,
  ReloadingAction extends ActionCreatorWithPayload<any>,
  Meta,
  RegisterFn extends (
    registerMeta: (meta: Meta) => void,
    ...args: any[]
  ) => void,
  UnregisterFn extends (
    meta: Parameters<Parameters<RegisterFn>["0"]>["0"],
    ...args: any[]
  ) => any,
>(
  increasing: IncreasingAction,
  decreasing: DecreasingAction,
  register: RegisterFn,
  unregister: UnregisterFn,
  identifier: (action: {
    payload: Parameters<IncreasingAction | DecreasingAction>[0];
  }) => string,
  reloading?: ReloadingAction,
) =>
  fork(function* () {
    const currentStates: Record<
      string,
      {
        count: number;
        meta: Meta | undefined;
        currentTask: Task | undefined;
      }
    > = {};

    function ensureIdExists(id: string): (typeof currentStates)[string] {
      let current = currentStates[id];
      if (!current) {
        current = { count: 0, meta: undefined, currentTask: undefined };
        currentStates[id] = current;
      }
      return current;
    }

    function* increase(action: any) {
      const id = identifier(action);
      const currentTaskState = ensureIdExists(id);

      if (currentTaskState.count === 0) {
        const registerMeta = (meta: Meta) => {
          currentTaskState.meta = meta;
        };

        const createdTask = yield* fork(register as any, registerMeta, action);
        currentTaskState.currentTask = createdTask;
      }
      currentTaskState.count += 1;
    }

    function* decrease(action: any) {
      const id = identifier(action);
      const currentState = ensureIdExists(id);

      // if we will unregister, wait a bit for another subscription
      if (currentState.count === 1) {
        yield wait(0);
      }

      currentState.count -= 1;
      if (currentState.count === 0) {
        if (currentState.currentTask) {
          yield* cancel(currentState.currentTask);
        }
        yield* fork(unregister as any, currentState.meta, action);
      }
      if (currentState.count < 0) {
        currentState.count = 0;
      }
    }

    function* reload(action: any) {
      const id = identifier(action);
      const current = ensureIdExists(id);

      yield* fork(unregister as any, current.meta, action);
      const registerMeta = (meta: Meta) => {
        current.meta = meta;
      };
      yield* fork(register as any, registerMeta, action);
    }

    yield* takeEvery(increasing, increase);
    yield* takeEvery(decreasing, decrease);
    if (reloading) {
      yield* takeEvery(reloading, reload);
    }
  });
/* eslint-enable @typescript-eslint/no-explicit-any */

export function* takeFirstAndDebounce<T extends NotUndefined>(
  channel: EventChannel<T>,
  ms: number,
  worker: (payload: T) => void,
) {
  const args = yield* take(channel);
  yield worker(args);
  yield* debounce(ms, channel, worker);
}

export function* waitForCondition<AP extends ActionPattern>(
  pattern: AP,
  check: (action: AP extends ActionPattern<infer K> ? K : never) => boolean,
) {
  while (true) {
    const a = yield* take(pattern);
    if (check(a as AnyUnexplained)) {
      break;
    }
  }
}

export function* takeLatestDeep<
  Action extends ActionCreatorWithPayload<AnyUnexplained>,
>(
  action: Action,
  worker: (action: ReturnType<Action>) => Generator,
  identifier: (action: { payload: Parameters<Action>[0] }) => string,
) {
  const store: Record<string, FixedTask<Generator>> = {};

  function* analyzer(givenAction: ReturnType<Action>) {
    const id = identifier(givenAction);
    const stored = store[id];
    if (stored) {
      yield* cancel(stored);
    }
    store[id] = yield* fork(worker, givenAction);
  }

  yield* takeEvery(action as AnyUnexplained, analyzer);
}
