import { useCallback } from "react";

import { Text } from "../../display";
import { TextInput } from "../textInput";
import { TextInputProps } from "../textInput/textInput.types";

interface LimitedTextInputProps extends TextInputProps {
  maxLength: number;
  maxLengthLabelThreshold: number;
}

export const LimitedTextInput = ({
  value,
  onTextInput,
  onChangeText,
  onFocus,
  onBlur,
  inputStyle,
  containerStyle,
  helperText,
  withHelperTextPadding,
  nativeID,
  status,
  disabled,
  disableAutocomplete,
  accessibilityLabel,
  multiline,
  placeholder,
  autoFocus,
  keyboardType,
  returnKeyType,
  returnKeyLabel,
  onSubmitEditing,
  editable,
  autoSizeWrapperProps,
  onChange,
  onKeyPress,
  onSelectionChange,
  onLayout,
  autoComplete,
  maxLength,
  maxLengthLabelThreshold,
}: LimitedTextInputProps) => {
  const handleChangeText = useCallback(
    (newText: string) => {
      onChangeText?.(newText.slice(0, maxLength));
    },
    [maxLength, onChangeText],
  );

  return (
    <>
      <TextInput
        value={value}
        onTextInput={onTextInput}
        onChangeText={handleChangeText}
        onFocus={onFocus}
        onBlur={onBlur}
        inputStyle={inputStyle}
        containerStyle={containerStyle}
        helperText={helperText}
        withHelperTextPadding={withHelperTextPadding}
        nativeID={nativeID}
        status={status}
        disabled={disabled}
        disableAutocomplete={disableAutocomplete}
        accessibilityLabel={accessibilityLabel}
        multiline={multiline}
        placeholder={placeholder}
        autoFocus={autoFocus}
        keyboardType={keyboardType}
        returnKeyType={returnKeyType}
        returnKeyLabel={returnKeyLabel}
        onSubmitEditing={onSubmitEditing}
        editable={editable}
        autoSizeWrapperProps={autoSizeWrapperProps}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onSelectionChange={onSelectionChange}
        onLayout={onLayout}
        autoComplete={autoComplete}
      />
      {value && value.length > maxLengthLabelThreshold && (
        <Text color="FONT_LOW_EMPHASIS">
          {value.length}/{maxLength}
        </Text>
      )}
    </>
  );
};
