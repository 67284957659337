import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { RootState } from "@kraaft/shared/core/store";
import { rainbowColors } from "@kraaft/ui";

import { Pool, PoolLanguage } from "./pool";

export const selectPoolState = (state: RootState) => {
  return state.pool;
};

export const selectCurrentPoolLocation = ({
  pool: { currentLocation },
}: RootState) => currentLocation;

export const selectPoolLocations = ({ pool: { locations } }: RootState) =>
  locations;

export const selectPoolAndColor = memoize((poolId: string) =>
  createSelector(
    selectOnePool(poolId),
    selectPoolLocations,
    (pool, poolLocations) => {
      const poolIndex = (poolLocations ?? [])?.findIndex(
        (location) => location.poolId === poolId,
      );
      if (pool === undefined || poolIndex === -1) {
        return {
          pool,
          color: rainbowColors[0],
        };
      }

      return {
        pool,
        color:
          rainbowColors[poolIndex % rainbowColors.length] ?? rainbowColors[0],
      };
    },
  ),
);

export const selectPoolLocation = memoize((poolId: string) =>
  createSelector(selectPoolState, (state) =>
    state.locations?.find((e) => e.poolId === poolId),
  ),
);

export const selectCurrentPool = ({
  pool: { pools, currentLocation: current },
}: RootState) => {
  if (current) {
    return pools[current.poolId];
  }
  return undefined;
};

export const selectCurrentPoolId = createSelector(
  [selectCurrentPool],
  (pool): string | undefined => pool?.id,
);

export const selectCurrentPoolName = createSelector(
  [selectCurrentPool],
  (pool): string | undefined => pool?.name,
);

export const selectCurrentPoolLanguage = createSelector(
  [selectCurrentPool],
  (pool): PoolLanguage | undefined => pool?.poolLanguage,
);

export const selectCurrentPoolCompanyId = createSelector(
  [selectCurrentPool],
  (pool) => pool?.companyId,
);

export const selectCurrentPoolFreemiumState = createSelector(
  [selectCurrentPool],
  (pool): boolean | undefined => pool?.isFreemium,
);

export function selectOnePool(poolId: string | undefined) {
  return ({ pool: { pools } }: RootState): Pool | undefined =>
    poolId ? pools[poolId] : undefined;
}

export const selectPoolCurrencyCode = memoize((poolId: string | undefined) =>
  createSelector(selectOnePool(poolId), (pool) => pool?.currencyCode ?? "EUR"),
);

export const selectPools = (state: RootState) => {
  return state.pool.pools;
};

export const selectPoolsLocation = (state: RootState) => {
  return state.pool.locations;
};

export const selectUserPoolCount = createSelector(
  selectPoolsLocation,
  (locations) => locations?.length ?? 0,
);

export const selectUnreadPools = createSelector(
  selectPoolState,
  (state) => state.unreadPools,
);

export const selectFetchedPoolOnce = createSelector(
  selectPoolState,
  (state) => state.fetchedOnce,
);
