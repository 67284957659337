import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { AddTagSheet } from "@kraaft/shared/core/modules/schemaLibraryTag/components/addTag.sheet";
import { EditableTagRow } from "@kraaft/shared/core/modules/schemaLibraryTag/components/editableTagRow";
import { OfflineSchemaLibraryTagActions } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.offline";
import {
  selectSchemaLibraryTagArrayByLanguage,
  selectSchemaLibraryTagByLanguage,
} from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.selectors";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import {
  Button,
  OrderableList,
  type ReorderPlacement,
  Sheet,
  Spacing,
} from "@kraaft/ui";

type ManageTagsSheetProps = {
  language: LibrarySchemaLanguage;
};

export const ManageTagsSheet = Sheet({
  web: "popup",
})
  .create<ManageTagsSheetProps>(
    ({ Content, Footer, Header, Paper }) =>
      ({ onClose, language }) => {
        const { t } = useTranslation();
        const schemaLibraryTags = useSelector(
          selectSchemaLibraryTagByLanguage(language),
        );
        const orderedSchemaLibraryTags = useSelector(
          selectSchemaLibraryTagArrayByLanguage(language),
        );
        const dispatch = useDispatch();

        const reorderTags = useCallback(
          (orderedIds: Array<string>) => {
            dispatch(
              OfflineSchemaLibraryTagActions.reorderSchemaLibraryTags({
                orderedIds,
                language,
              }),
            );
          },
          [language, dispatch],
        );

        const renameTag = useCallback(
          (tag: SchemaLibraryTag) => {
            dispatch(
              OfflineSchemaLibraryTagActions.renameSchemaLibraryTag({
                id: tag.id,
                name: tag.name,
                language,
              }),
            );
          },
          [dispatch, language],
        );

        const deleteTag = useCallback(
          (tag: SchemaLibraryTag) => {
            dispatch(
              OfflineSchemaLibraryTagActions.deleteSchemaLibraryTag({
                id: tag.id,
                language,
              }),
            );
          },
          [language, dispatch],
        );

        const { open, element } = AddTagSheet.use({ language });

        const handleRequestReorder = useCallback(
          (
            sourceKey: string,
            _placement: ReorderPlacement,
            targetKey: string,
          ) => {
            const orderedSchemaLibraryTagIds = orderedSchemaLibraryTags.map(
              (tag) => tag.id,
            );
            const sourceKeyIndex =
              orderedSchemaLibraryTagIds.indexOf(sourceKey);
            const targetKeyIndex =
              orderedSchemaLibraryTagIds.indexOf(targetKey);
            if (sourceKeyIndex === -1 || targetKeyIndex === -1) {
              console.warn(
                "An error occurred, found invalid item index while reordering",
              );
              return;
            }
            const newTagList = [...orderedSchemaLibraryTagIds];
            newTagList.splice(sourceKeyIndex, 1);
            newTagList.splice(targetKeyIndex, 0, sourceKey);

            reorderTags(newTagList);
          },
          [orderedSchemaLibraryTags, reorderTags],
        );

        const classes = useStyles();

        return (
          <>
            <Paper>
              <Header onClose={onClose}>
                {t("schemaLibraryViewTags.settings.manageTag")}
              </Header>
              <Content>
                <OrderableList
                  withHandle
                  rows={schemaLibraryTags}
                  rowGap="S8"
                  onRequestReorder={handleRequestReorder}
                  rowContainerClassName={classes.rowContainer}
                  RowRenderer={(tag) => {
                    return (
                      <EditableTagRow
                        tag={tag}
                        onNameChange={renameTag}
                        onDelete={deleteTag}
                      />
                    );
                  }}
                />
              </Content>
              <Footer>
                <Button
                  text={t("schemaLibraryViewTags.settings.createTag")}
                  onPress={open}
                />
              </Footer>
            </Paper>
            {element}
          </>
        );
      },
  )
  .withDefaults({ size: "SMALL" });

const useStyles = makeStyles({
  rowContainer: {
    height: Spacing.S32,
    width: "100%",
  },
});
