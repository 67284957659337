import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { styles } from "@kraaft/shared/components/createWorkspace/workspaceNameInput.styles";
import { LimitedTextInput, Text } from "@kraaft/ui";

import { CreatePoolFlowContext } from "../createPoolFlow.context";

export const CREATION_REASON_MAX_LENGTH = 400;
export const CREATION_REASON_MAX_LENGTH_LABEL_THRESHOLD = 350;

export const CreationReasonStep = () => {
  const { t } = useTranslation();

  const { creationReason, setCreationReason } = useMeshContext(
    CreatePoolFlowContext,
  );

  return (
    <>
      <Text size="BODY" color="FONT_LOW_EMPHASIS" style={styles.subtitle}>
        {t("addPool.reason.subtitle")}
      </Text>
      <LimitedTextInput
        multiline
        accessibilityLabel={t("addPool.reason.placeholder")}
        value={creationReason}
        onChangeText={setCreationReason}
        maxLength={CREATION_REASON_MAX_LENGTH}
        maxLengthLabelThreshold={CREATION_REASON_MAX_LENGTH_LABEL_THRESHOLD}
      />
    </>
  );
};
