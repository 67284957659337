import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import clsx from "clsx";

import { Icon } from "@kraaft/ui";
import { HandleSide } from "@kraaft/web/src/components/sidebar/sidebar.types";

import {
  DEFAULT_DRAWER_WIDTH,
  MAX_DRAWER_WIDTH,
  useStyles,
} from "./sidebar.styles";

interface Props {
  className?: string;
  children: ReactNode;
  handleSide?: HandleSide;
  collapsed?: boolean;
  fullWidth?: boolean;
  onWidthUpdate?: (newWidth: number) => void;
  minimizable?: boolean;
  defaultWidth?: number;
}

export const Sidebar = (props: Props) => {
  const {
    children,
    className,
    handleSide = "right",
    collapsed,
    fullWidth,
    onWidthUpdate,
    minimizable = false,
    defaultWidth = DEFAULT_DRAWER_WIDTH,
  } = props;

  const MIN_DRAWER_WIDTH = minimizable ? 88 : Math.min(defaultWidth, 360);

  const sidebarRef = useRef<HTMLDivElement>(null);
  const holdingPositionRef = useRef(0);
  const [isResizingManually, setResizingManually] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(defaultWidth);
  const classes = useStyles({
    width: drawerWidth,
    handleSide,
    collapsed: Boolean(collapsed),
    isResizingManually,
    fullWidth: Boolean(fullWidth),
  });

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      const sidebarBoundingRect = sidebarRef.current?.getBoundingClientRect();

      if (sidebarBoundingRect === undefined) {
        return;
      }
      let newWidth = defaultWidth;

      if (handleSide === "left") {
        newWidth = Math.abs(
          e.clientX - sidebarBoundingRect.right - holdingPositionRef.current,
        );
      } else {
        newWidth = Math.abs(
          e.clientX - sidebarBoundingRect.left - holdingPositionRef.current,
        );
      }

      if (newWidth > MIN_DRAWER_WIDTH && newWidth < MAX_DRAWER_WIDTH) {
        setDrawerWidth(newWidth);
        onWidthUpdate?.(newWidth);
      }
    },
    [defaultWidth, handleSide, onWidthUpdate, MIN_DRAWER_WIDTH],
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    document.body.style.cursor = "unset";
    setResizingManually(false);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const sidebarBoundingRect = sidebarRef.current?.getBoundingClientRect();

      if (sidebarBoundingRect === undefined) {
        return;
      }

      const x = event.nativeEvent.clientX;
      const offset =
        handleSide === "left"
          ? sidebarBoundingRect.left
          : sidebarBoundingRect.right;

      holdingPositionRef.current = x - offset;
      document.addEventListener("mouseup", handleMouseUp, true);
      document.addEventListener("mousemove", handleMouseMove, true);
      document.body.style.cursor = "ew-resize";
      setResizingManually(true);
    },
    [handleMouseMove, handleMouseUp, sidebarRef, handleSide],
  );

  return (
    <div
      ref={sidebarRef}
      className={clsx(
        classes.sidebar,
        !isResizingManually && classes.shift,
        {
          [classes.sidebarWithBorderLeft]: handleSide === "left",
          [classes.sidebarWithBorderRight]: handleSide === "right",
        },
        className,
      )}
    >
      <div className={classes.content}>{children}</div>
      {!collapsed && !fullWidth && (
        <div onMouseDown={handleMouseDown} className={classes.dragger}>
          <div className={classes.draggerLine}>
            <div className={classes.draggerIcon}>
              <Icon name="dots-vertical" size="MINI" color="WHITE" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
