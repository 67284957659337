import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useWorkspaceNameInput } from "@kraaft/shared/components/createWorkspace/useWorkspaceNameInput";
import { Text } from "@kraaft/ui";

import { styles } from "./workspaceNameInput.styles";

interface WorkspaceNameInputProps {
  setName: (name: string) => void;
  setIsSubmitDisabled: (is: boolean) => void;
  name: string;
  submit: () => void;
}

export const WorkspaceNameInput = ({
  setName,
  name,
  submit,
  setIsSubmitDisabled,
}: WorkspaceNameInputProps) => {
  const [element, { isLoading, isTaken, isError }] = useWorkspaceNameInput(
    name,
    setName,
    submit,
  );

  useEffect(() => {
    setIsSubmitDisabled(
      isError || isLoading || isTaken === undefined || isTaken,
    );
  }, [isError, isLoading, isTaken, setIsSubmitDisabled]);

  const { t } = useTranslation();

  return (
    <>
      <Text size="BODY" color="FONT_LOW_EMPHASIS" style={styles.subtitle}>
        {t("newWorkspace.helper")}
      </Text>
      <View style={styles.inputContainer}>{element}</View>
    </>
  );
};
