import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersFlowNavigator } from "@kraaft/shared/components/filters/roomFilters.flow";
import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { useFilterRooms } from "@kraaft/shared/components/filters/useFilterRooms";
import { useLabelsSummary } from "@kraaft/shared/components/filters/useLabelsSummary";
import { useReadingStatusSummary } from "@kraaft/shared/components/filters/useReadingStatusSummary";
import { useResponsibleSummary } from "@kraaft/shared/components/filters/useResponsibleSummary";
import { useStatusSummary } from "@kraaft/shared/components/filters/useStatusSummary";
import { useVisibilitySummary } from "@kraaft/shared/components/filters/useVisibilitySummary";
import { Button, SummarizedActionCard } from "@kraaft/ui";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";

interface RoomFiltersOverviewProps {
  onClose: () => void;
  onNavigate: RoomFiltersFlowNavigator;
}

export const RoomFiltersOverview = ({
  onClose,
  onNavigate,
}: RoomFiltersOverviewProps) => {
  const { t } = useTranslation();
  const { isDefaultAllValues, onReset } = useMeshContext(RoomFiltersContext);
  const visibilityLabel = useVisibilitySummary();
  const statusLabel = useStatusSummary();
  const labelsLabel = useLabelsSummary();
  const responsibleLabel = useResponsibleSummary();
  const readingStatusLabel = useReadingStatusSummary();

  const canCurrentUserReadAllPoolUsers = Guard.use("Members.listPoolMembers");

  const filterRooms = useFilterRooms();

  const handleVisibilityPress = useCallback(
    () => onNavigate("visibility"),
    [onNavigate],
  );

  const handleStatusPress = useCallback(
    () => onNavigate("status"),
    [onNavigate],
  );

  const handleCategoryPress = useCallback(
    () => onNavigate("label"),
    [onNavigate],
  );

  const handleResponsiblePress = useCallback(
    () => onNavigate("responsible"),
    [onNavigate],
  );

  const handlerReadingStatusPress = useCallback(
    () => onNavigate("readingStatus"),
    [onNavigate],
  );

  const handleResetPress = useCallback(() => {
    onReset();
    onClose();
  }, [onClose, onReset]);

  const handleSubmitFilters = useCallback(() => {
    filterRooms();
    onClose();
  }, [filterRooms, onClose]);

  return (
    <>
      <RoomFiltersSheet.Header onClose={onClose}>
        {t("roomFilters.filter")}
      </RoomFiltersSheet.Header>
      <RoomFiltersSheet.Content>
        <SummarizedActionCard
          label={t("roomFilters.visibility")}
          summary={visibilityLabel}
          onPress={handleVisibilityPress}
        />
        <SummarizedActionCard
          label={t("roomFilters.status")}
          summary={statusLabel}
          onPress={handleStatusPress}
        />
        <SummarizedActionCard
          label={t("roomFilters.label")}
          summary={labelsLabel}
          onPress={handleCategoryPress}
        />
        {canCurrentUserReadAllPoolUsers ? (
          <SummarizedActionCard
            label={t("roomFilters.responsible")}
            summary={responsibleLabel}
            onPress={handleResponsiblePress}
          />
        ) : null}
        <SummarizedActionCard
          label={t("roomFilters.readingStatus")}
          summary={readingStatusLabel}
          onPress={handlerReadingStatusPress}
        />
      </RoomFiltersSheet.Content>
      <RoomFiltersSheet.Footer>
        <Button
          text={t("roomFilters.resetActionLabel")}
          variant="QUATERNARY"
          onPress={handleResetPress}
          disabled={isDefaultAllValues}
        />
        <Button
          text={t("roomFilters.filterActionLabel")}
          variant="PRIMARY"
          onPress={handleSubmitFilters}
        />
      </RoomFiltersSheet.Footer>
    </>
  );
};
