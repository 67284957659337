import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { useCompany } from "@kraaft/shared/core/modules/company/hooks/useCompany";
import {
  selectGridLibrarySchemasForLanguage,
  selectPublicEnabledLibrarySchemaCount,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { Spacing } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import * as Tabs from "@kraaft/web/src/components/tabBar";
import { useQueryString } from "@kraaft/web/src/core/utils/useQueryString";
import { queries } from "@kraaft/web/src/views/app/appRouter/routes";
import { SchemaLibraryBanner } from "@kraaft/web/src/views/schemaLibrary/schemaLibraryCompanyBanner";
import { SchemaLibraryGrid } from "@kraaft/web/src/views/schemaLibrary/schemaLibraryGrid";
import { SchemaLibraryTab } from "@kraaft/web/src/views/schemaLibrary/schemaLibraryTab";

import { Sidebar } from "../../components/sidebar";
import { useOrderedSchemaLibraryTagWithCounter } from "./tagNavigationMenu/useOrderedSchemaLibraryTagWithCounter";
import { SchemaLibrarySearchBar } from "./schemaLibrarySearchBar";
import { TagNavigationMenu } from "./tagNavigationMenu";

import { useStyles } from "./schemaLibrary.styles";

// Honestly I think complexity rule is less important when applied to templating, should be a warning a most
// eslint-disable-next-line complexity
const SchemaLibrary = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const pool = useSelector(selectCurrentPool);
  const schemaLibraryTags = useOrderedSchemaLibraryTagWithCounter();
  const tab =
    (useQueryString().get(queries.tab) as SchemaLibraryTab | undefined) ??
    "public";

  const tagId = useQueryString().get(queries.tagId);

  const navigationService = useNavigationService();

  const onSelectSchema = useCallback(
    (librarySchemaId: string) => {
      navigationService.navigate("LibrarySchemaShowcase", {
        librarySchemaId,
      });
    },
    [navigationService],
  );

  const setTab = useCallback(
    (newTab: SchemaLibraryTab) => {
      navigationService.navigate("SchemaLibraryView", {
        tab: newTab,
      });
    },
    [navigationService],
  );

  const setSelectedTag = useCallback(
    (newTagId: string | undefined) => {
      navigationService.navigate("SchemaLibraryView", {
        tagId: newTagId,
      });
    },
    [navigationService],
  );

  const company = useCompany(pool?.companyId);

  const librarySchemas = useSelector(
    selectGridLibrarySchemasForLanguage(pool?.poolLanguage),
  );

  const publicLibrarySchemas = useMemo(
    () =>
      librarySchemas.filter((librarySchema) => {
        if (librarySchema.companyId) {
          return false;
        }
        if (tagId && !librarySchema.tagIds?.includes(tagId)) {
          return false;
        }
        return true;
      }),
    [librarySchemas, tagId],
  );

  const companyLibrarySchemas = useMemo(
    () =>
      pool
        ? librarySchemas.filter(
            (librarySchema) => pool.companyId === librarySchema.companyId,
          )
        : [],
    [librarySchemas, pool],
  );

  const displayPrivateLibrary =
    pool?.companyId && companyLibrarySchemas.length > 0;

  useEffect(() => {
    if (!displayPrivateLibrary && tab === "company") {
      setTab("public");
    }
  }, [displayPrivateLibrary, setTab, tab]);

  const publicLibrarySchemaCount = useSelector(
    selectPublicEnabledLibrarySchemaCount(pool?.poolLanguage),
  );

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <PageHeader
          title={
            <div className={classes.headerTitle}>
              <View style={styles.titleContent}>
                <div>{t("schemaLibraryView.title")}</div>
                <SchemaLibrarySearchBar onSelectSchema={onSelectSchema} />
              </View>
              <View style={styles.tabs}>
                <Tabs.Container<SchemaLibraryTab> value={tab} onChange={setTab}>
                  <Tabs.Tab<SchemaLibraryTab>
                    label={t("schemaLibrary.publicSchemaLibraryTabName")}
                    value="public"
                    icon="book-open-01"
                  />
                  {displayPrivateLibrary && (
                    <Tabs.Tab<SchemaLibraryTab>
                      label={company?.name ?? ""}
                      value="company"
                      icon="building-07"
                    />
                  )}
                </Tabs.Container>
              </View>
            </div>
          }
        />
      </div>
      <div className={classes.body}>
        {!tab || tab === "public" ? (
          <Sidebar defaultWidth={260}>
            <TagNavigationMenu
              schemaLibraryTags={schemaLibraryTags}
              selectedTagId={tagId}
              setSelectedTag={setSelectedTag}
              totalCount={publicLibrarySchemaCount}
            />
          </Sidebar>
        ) : null}
        <div className={classes.main}>
          <div className={classes.gridContainer}>
            {pool && (
              <>
                {tab === "public" && (
                  <SchemaLibraryGrid
                    librarySchemas={publicLibrarySchemas}
                    onSelectSchema={onSelectSchema}
                  />
                )}
                {tab === "company" && (
                  <>
                    <SchemaLibraryBanner
                      companyName={company?.name ?? ""}
                      style={styles.banner}
                    />
                    <SchemaLibraryGrid
                      librarySchemas={companyLibrarySchemas}
                      onSelectSchema={onSelectSchema}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  tabs: {
    marginTop: Spacing.S16,
  },
  banner: {
    marginBottom: Spacing.S24,
  },
  bannerTitle: {
    marginBottom: Spacing.S8,
  },
  titleContent: {
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
  },
});

export { SchemaLibrary };
