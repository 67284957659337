import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

import { ANIMATION_PAGE_DURATION, useStyles } from "./transition.styles";

interface TransitionProps {
  identifier: string;
  children: React.ReactNode;
}

function roll(index: 0 | 1) {
  return ((index + 1) % 2) as 0 | 1;
}

export const Transition = (props: TransitionProps) => {
  const { identifier, children } = props;

  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState<0 | 1>(0);
  const contents = useRef<[ReactNode | undefined, ReactNode | undefined]>([
    children,
    undefined,
  ]);

  const previousIdentifier = useRef<TransitionProps["identifier"]>();

  if (previousIdentifier.current === identifier) {
    contents.current[currentIndex] = children;
  }

  const animateTransition = useCallback(() => {
    setCurrentIndex((index) => {
      const newIndex = roll(index);
      contents.current[newIndex] = children;
      return newIndex;
    });
  }, [children]);

  useLayoutEffect(() => {
    if (
      identifier !== previousIdentifier.current &&
      previousIdentifier.current !== undefined
    ) {
      animateTransition();
    }
    previousIdentifier.current = identifier;
  }, [animateTransition, identifier, previousIdentifier]);

  return (
    <div className={classes.container}>
      <CSSTransition
        in={currentIndex === 0}
        classNames={{
          enter: classes.withSlideInAnimation,
          exit: classes.withSlideOutAnimation,
        }}
        timeout={ANIMATION_PAGE_DURATION}
        unmountOnExit
      >
        <div className={classes.absoluteContainer}>{contents.current[0]}</div>
      </CSSTransition>
      <CSSTransition
        in={currentIndex === 1}
        classNames={{
          enter: classes.withSlideInAnimation,
          exit: classes.withSlideOutAnimation,
        }}
        timeout={ANIMATION_PAGE_DURATION}
        unmountOnExit
      >
        <div className={classes.absoluteContainer}>{contents.current[1]}</div>
      </CSSTransition>
    </div>
  );
};
