import { pickBy } from "lodash";
import React, {
  type ComponentType,
  memo,
  type ReactNode,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { Platform, type PlatformOSType } from "react-native";
import {
  assert,
  type RequiredKeys,
  type UnionToIntersection,
} from "ts-essentials";

import { isSyntheticEvent } from "@kraaft/helper-functions";
import { useBooleanState } from "@kraaft/helper-hooks";
import { Without } from "@kraaft/helper-types";

import { anchoredSheetDefinition } from "./anchoredSheet/anchoredSheet.definition";
import { bottomSheetDefinition } from "./bottomSheet/bottomSheet.definition";
import { overlaySheetDefinition } from "./overlaySheet/overlaySheet.definition";
import { popupSheetDefinition } from "./popupSheet/popupSheet.definition";
import type { SheetDefinition } from "./sheet.types";

const sheetFactory = {
  bottom: bottomSheetDefinition,
  anchored: anchoredSheetDefinition,
  popup: popupSheetDefinition,
  overlay: overlaySheetDefinition,
} satisfies { [variant: string]: SheetDefinition };

type AvailableVariantForPlatform = {
  native: "bottom" | "overlay";
  ios: "bottom" | "overlay";
  android: "bottom" | "overlay";
  web: "anchored" | "popup" | "overlay";
} & { [P in PlatformOSType]: SheetVariant };

type SheetFactoryType = typeof sheetFactory;
type SheetVariant = keyof SheetFactoryType;

type Specifics = { [P in PlatformOSType]?: AvailableVariantForPlatform[P] } & {
  default?: SheetVariant;
};

type ExtractVariant<S extends Specifics> = Extract<S[keyof S], SheetVariant>;

function getSheet<S extends Specifics>(
  specifics: S,
): SheetFactoryType[ExtractVariant<S>] {
  const variantForPlatform = Platform.select(specifics);

  if (variantForPlatform !== undefined) {
    return sheetFactory[variantForPlatform as ExtractVariant<S>];
  }

  assert(false, "sheet variant is not defined for platform");
}

type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never;

export function Sheet<S extends Specifics>(specifics: S) {
  const { Host, ...definition } = getSheet(specifics);

  type HostProps = UnionToIntersection<
    PropsOf<ReturnType<typeof getSheet<S>>["Host"]>
  >;
  type PartialHostProps = Partial<HostProps>;

  // Merge the props of the Host component with the props of the wrapped component
  // Allows for easier usage of the sheets
  function Wrap<P, StaticProps extends PartialHostProps>(
    Component: React.FC<P>,
    staticProps?: StaticProps,
  ) {
    type WrappedComponentProps = HostProps & P;
    type OnlySetKeys = RequiredKeys<StaticProps>;
    type SheetContentProps = Omit<
      WrappedComponentProps,
      OnlySetKeys | "open" | "onClose" | "children" | "setPreventClose"
    > & {
      [k in keyof StaticProps]?: StaticProps[k] | undefined;
    };

    const MemoizedComponent = memo(Component);
    const WrappedComponent = (props: WrappedComponentProps) => {
      const { open, ...sanitizedProps } = pickBy(
        props as any,
        (element) => element !== undefined,
      );

      return (
        <Host {...(staticProps ?? {})} open={open} {...(sanitizedProps as any)}>
          <MemoizedComponent {...(sanitizedProps as any)} />
        </Host>
      );
    };

    function useSheet<PartialProps extends Partial<SheetContentProps>>(
      props: PartialProps,
    ): {
      isOpen: boolean;
      open: (
        ...args: RequiredKeys<
          Without<SheetContentProps, PartialProps>
        > extends never
          ? [] | [Partial<SheetContentProps>] | [params: SyntheticEvent]
          : [params: Without<SheetContentProps, PartialProps>]
      ) => void;
      close: () => void;
      element: ReactNode;
    } {
      const preventClose = useRef(false);
      const [isOpen, setOpen, close] = useBooleanState();

      const handleClose = useCallback(
        (params = { bypassPreventClose: false }) => {
          if (preventClose.current && !params.bypassPreventClose) {
            return;
          }
          close();
        },
        [close],
      );

      const setPreventClose = useCallback((value: boolean) => {
        preventClose.current = value;
      }, []);

      const [state, setState] = useState<
        Partial<SheetContentProps> | undefined
      >(undefined);

      const open = useCallback(
        (params: Partial<SheetContentProps> | SyntheticEvent | undefined) => {
          if (!isSyntheticEvent(params)) {
            setState(params);
          }
          setOpen();
        },
        [setOpen],
      );

      return {
        isOpen,
        open: open as any,
        close: handleClose,
        element: (
          <WrappedComponent
            open={isOpen}
            onClose={handleClose}
            {...({
              ...pickBy(props, (element) => element !== undefined),
              ...pickBy(state, (element) => element !== undefined),
            } as any)}
            setPreventClose={setPreventClose}
          />
        ),
      };
    }

    WrappedComponent.use = useSheet;

    WrappedComponent.withDefaults = <Se extends PartialHostProps>(props: Se) =>
      Wrap(Component, props);

    return WrappedComponent;
  }

  // Function to create an inlined sheet component
  const create = <P extends {}>(
    createFn: (p: typeof definition) => React.FC<
      P &
        Omit<HostProps, "open"> & {
          setPreventClose: (value: boolean) => void;
        }
    >,
  ) => {
    return Wrap(createFn(definition));
  };

  return {
    Host: Host as ComponentType<HostProps>,
    Paper: definition.Paper,
    Header: definition.Header,
    Content: definition.Content,
    GrowingContent: definition.GrowingContent,
    Footer: definition.Footer,
    Wrap,
    create,
  };
}
