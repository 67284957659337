import { useCallback, useMemo } from "react";
import { StatusBar } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { keyBy } from "lodash";

import { compactMap } from "@kraaft/helper-functions";
import { CarouselImage } from "@kraaft/shared/components/carousel/placeholders/carouselImage";
import { CarouselVideo } from "@kraaft/shared/components/carousel/placeholders/carouselVideo";
import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import {
  fetchUnknownMessages,
  MessageActions,
} from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessages } from "@kraaft/shared/core/modules/message/messageSelectors";
import { canShowRemoveMessage } from "@kraaft/shared/core/modules/message/messageUtils";
import { MiniMediaTemporaryCarouselImage } from "@kraaft/shared/core/modules/miniMedia/components/miniMediaCarousel/miniMediaTemporaryCarouselImage";
import {
  selectMiniMediaCarousel,
  selectMiniMediaCarouselData,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentUserAccountOwner } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { GeoLocation } from "@kraaft/shared/core/types";
import { useRemoveMessage } from "@kraaft/shared/core/utils/useRemoveMessage";
import {
  Carousel,
  CarouselUtils,
  Sheet,
  Spacing,
  useCarouselOpenClose,
  useMaxSafeAreaInsets,
} from "@kraaft/ui";
import { CarouselItemRenderer } from "@kraaft/ui/src/display/carousel/carousel.types";

function miniMediaKeyExtractor(miniMedia: MiniMedia) {
  return miniMedia.id;
}

interface MiniMediaCarouselProps {
  roomId: string;
}

const MiniMediaCarouselSheet = Sheet({
  default: "overlay",
}).create<MiniMediaCarouselProps>(({ Content }) => ({ roomId, onClose }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectMiniMediaCarouselData(roomId));
  const messages = keyBy(
    useSelector(selectMessages(roomId)),
    (message) => message.id,
  );
  const poolId = useSelector(selectCurrentPoolId);
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);
  const isAccountOwner = useSelector(
    selectCurrentUserAccountOwner(poolId ?? ""),
  );

  const uploadContext = useMemo<UploadAttachmentContext>(
    () => ({
      type: "room",
      roomId,
    }),
    [roomId],
  );

  const checkCanEditGeolocation = Guard.useGuard("Message.editGeolocation");

  const removeMessage = useRemoveMessage({ roomId });

  const handleGeolocationChange = useCallback(
    (media: MiniMedia, geolocation: GeoLocation | null | undefined) => {
      dispatch(
        MessageActions.updateAttachmentGeolocation({
          roomId,
          messageId: media.messageId,
          geolocation: geolocation ?? undefined,
        }),
      );
    },
    [dispatch, roomId],
  );

  const renderItem = useCallback<CarouselItemRenderer<MiniMedia>>(
    ({ index, item }) => {
      const message = messages[item.messageId];

      if (message) {
        const canShowDelete = canShowRemoveMessage(
          message,
          isSuperadmin,
          isAccountOwner,
        );
        const handleDelete = canShowDelete
          ? () => removeMessage(message)
          : undefined;

        const [canShowRelocate] = checkCanEditGeolocation(message);

        if (message.type === "image") {
          return (
            <CarouselImage
              index={index}
              item={item}
              attachment={message.attachment}
              onClose={onClose}
              onLocationChange={
                canShowRelocate ? handleGeolocationChange : undefined
              }
              roomId={roomId}
              uploadContext={uploadContext}
              onDelete={handleDelete}
            />
          );
        }
        if (message.type === "video") {
          return (
            <CarouselVideo
              index={index}
              item={item}
              attachment={message.attachment}
              onClose={onClose}
              onLocationChange={
                canShowRelocate ? handleGeolocationChange : undefined
              }
              roomId={roomId}
              uploadContext={uploadContext}
              onDelete={handleDelete}
            />
          );
        }
        return null;
      }
      if (item.type === "image") {
        return <MiniMediaTemporaryCarouselImage miniMedia={item} />;
      }
      return null;
    },
    [
      messages,
      isSuperadmin,
      isAccountOwner,
      checkCanEditGeolocation,
      removeMessage,
      onClose,
      handleGeolocationChange,
      roomId,
      uploadContext,
    ],
  );

  const handleFocusedChange = useCallback(
    (focused: number) => {
      const items = compactMap(
        CarouselUtils.getIndexesToRender(focused),
        (index) => data?.medias[index]?.messageId,
      );
      if (items.length === 0) {
        return;
      }
      dispatch(fetchUnknownMessages({ roomId, messageIds: items }));
    },
    [data?.medias, dispatch, roomId],
  );

  const insets = useMaxSafeAreaInsets({
    top: Spacing.S8,
    bottom: Spacing.S8,
  });

  if (!data) {
    return null;
  }

  return (
    <Content>
      <StatusBar backgroundColor="black" barStyle="dark-content" />
      <Carousel
        insets={insets}
        initialIndex={data.initialIndex}
        items={data.medias}
        open
        onClose={onClose}
        keyExtractor={miniMediaKeyExtractor}
        renderItem={renderItem}
        onFocusedChange={handleFocusedChange}
      />
    </Content>
  );
});

export const MiniMediaCarousel = () => {
  const dispatch = useDispatch();
  const miniMediaCarousel = useSelector(selectMiniMediaCarousel);
  const { open, onClose } = useCarouselOpenClose(miniMediaCarousel?.show);

  const closeCarousel = useCallback(() => {
    dispatch(CarouselStateActions.close());
  }, [dispatch]);

  const { element } = MiniMediaCarouselSheet.use({
    open,
    onClose,
    onClosed: closeCarousel,
    roomId: miniMediaCarousel.show ? miniMediaCarousel.roomId : undefined,
  });

  if (!miniMediaCarousel?.show) {
    return null;
  }

  return element;
};
