import { useCallback, useMemo, useRef, useState } from "react";
import { Platform } from "react-native";
import { noop } from "ts-essentials";

import { nullId, uuid } from "@kraaft/shared/core/utils";
import { useEffectRealtime } from "@kraaft/shared/core/utils/hooks";
import { waitForSetStatePropagation as initialWaitForSetStatePropagation } from "@kraaft/shared/core/utils/promiseUtils";
import { bidirectionalLog } from "@kraaft/shared/core/utils/useBidirectional/bidirectionalLog";
import {
  CursorState,
  LinkedList,
} from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";
import { ScrollPosition } from "@kraaft/shared/core/utils/useBidirectional/implementations/bidirectionalList.props";
import { LinkedListHelpers } from "@kraaft/shared/core/utils/useBidirectional/linkedList";
import {
  useDataLoader,
  UseDataLoaderProps,
} from "@kraaft/shared/core/utils/useBidirectional/useDataLoader";
import { useLocked } from "@kraaft/shared/core/utils/useBidirectional/useLocked";
import { useScrollEdgeDetection } from "@kraaft/shared/core/utils/useBidirectional/useScrollEdgeDetection";

interface UseBidirectionalProps<T> {
  startId: string | undefined;
  pageSize: number;
  fetchBeforeId: UseDataLoaderProps<T>["fetchBeforeId"];
  fetchAfterId: UseDataLoaderProps<T>["fetchAfterId"];
  fetchAroundId: UseDataLoaderProps<T>["fetchAroundId"];
  fetchLast: UseDataLoaderProps<T>["fetchLast"];
  storeLinkedList: UseDataLoaderProps<T>["storeLinkedList"];
  getIdFromItem: (item: T) => string;
  getLinkedList: () => LinkedList;
  scrollToDataId: (
    dataId: string,
    position: ScrollPosition,
    shouldAnimate: boolean,
    isAlreadyDisplayed: boolean,
  ) => Promise<void>;
}

type ScrollToDataInView = {
  requestId: string;
  id: string;
  shouldAnimate: boolean;
} & (
  | { alreadyDisplayed: true }
  | { alreadyDisplayed: false; linkedList: LinkedList }
);

const waitForSetStatePropagation = Platform.select({
  web: initialWaitForSetStatePropagation,
  default: noop,
});

export function useBidirectional<T>({
  startId,
  fetchBeforeId,
  fetchAfterId,
  fetchAroundId,
  fetchLast,
  storeLinkedList,
  getIdFromItem,
  getLinkedList,
  scrollToDataId,
  pageSize,
}: UseBidirectionalProps<T>) {
  const [anchorId_, setAnchorId] = useState<string | undefined>(undefined);

  // when the room was empty and a new message appears, the anchorId should change.
  const anchorId = useMemo(
    () =>
      anchorId_ === nullId
        ? (LinkedListHelpers.getEarliest(getLinkedList())?.itemId ?? anchorId_)
        : anchorId_,
    [anchorId_, getLinkedList],
  );

  const linkedListAnchor = useMemo(
    () =>
      anchorId
        ? LinkedListHelpers.getItemFromId(getLinkedList(), anchorId)
        : undefined,
    [anchorId, getLinkedList],
  );

  const { loadBeforeId, loadAfterId, loadAroundId, loadLast } = useDataLoader({
    linkedListAnchor,
    fetchAfterId,
    fetchAroundId,
    fetchBeforeId,
    fetchLast,
    getIdFromItem,
    getLinkedList,
    pageSize,
    storeLinkedList,
  });

  const { onScroll, resetLoadLocks } = useScrollEdgeDetection(
    getLinkedList(),
    linkedListAnchor,
    loadBeforeId,
    loadAfterId,
  );

  const scrollRequestId = useRef<string>("");
  const generateScrollRequestId = useCallback(() => {
    scrollRequestId.current = uuid();
    return scrollRequestId.current;
  }, []);
  const scrollToDataInView = useCallback(
    (scroll: ScrollToDataInView) => {
      if (scroll.requestId !== scrollRequestId.current) {
        bidirectionalLog("Cancelled scroll, another one has been requested");
        return;
      }
      const linkedList = scroll.alreadyDisplayed
        ? getLinkedList()
        : scroll.linkedList;
      const itemInList = LinkedListHelpers.getItemFromId(linkedList, scroll.id);
      if (!itemInList) {
        bidirectionalLog("Could not scroll, item not in list");
        return;
      }

      const index = LinkedListHelpers.positionOfAnchored(
        linkedList,
        itemInList.itemId,
        itemInList.itemId,
      );
      const length = LinkedListHelpers.lenFrom(
        linkedList,
        LinkedListHelpers.getOrigin(linkedList, itemInList),
      );
      if (index < 0) {
        bidirectionalLog("Could not scroll, index < 0");
        return;
      }
      return scrollToDataId(
        scroll.id,
        { index, length },
        scroll.shouldAnimate,
        scroll.alreadyDisplayed,
      );
    },
    [getLinkedList, scrollToDataId],
  );

  const resetToBottom = useCallback(
    async (requestId: string) => {
      const linkedList = getLinkedList();
      // Check if latest element is in view
      if (linkedListAnchor) {
        const latestItem = LinkedListHelpers.getLatestAnchored(
          linkedList,
          linkedListAnchor,
          CursorState.FINISHED,
        );
        if (latestItem) {
          bidirectionalLog("Last element is in view, scrolling");
          return scrollToDataInView({
            requestId,
            id: latestItem.itemId,
            shouldAnimate: true,
            alreadyDisplayed: true,
          });
        }
      }

      // Check if latest element is in memory
      const latestItem = LinkedListHelpers.getLatest(
        linkedList,
        CursorState.FINISHED,
      );
      if (latestItem) {
        const builtLinkedList = LinkedListHelpers.buildEarlier(
          linkedList,
          latestItem.itemId,
          true,
        );
        if (builtLinkedList) {
          bidirectionalLog("Last element is in memory, loading");
          resetLoadLocks();
          setAnchorId(latestItem.itemId);
          return scrollToDataInView({
            requestId,
            id: latestItem.itemId,
            shouldAnimate: true,
            alreadyDisplayed: false,
            linkedList: builtLinkedList,
          });
        }
      }

      bidirectionalLog("Last element not found, fetching");

      // Loads latest element
      setAnchorId(undefined);

      const { linkedList: bottomLinkedList } = await loadLast();
      const firstItem = LinkedListHelpers.getLatest(bottomLinkedList);

      resetLoadLocks();

      if (!firstItem) {
        return;
      }

      setAnchorId(firstItem.itemId);
      if (LinkedListHelpers.isEmpty(bottomLinkedList)) {
        return;
      }
      await waitForSetStatePropagation();
      return scrollToDataInView({
        requestId,
        id: firstItem.itemId,
        shouldAnimate: true,
        alreadyDisplayed: false,
        linkedList: bottomLinkedList,
      });
    },
    [
      getLinkedList,
      linkedListAnchor,
      loadLast,
      resetLoadLocks,
      scrollToDataInView,
    ],
  );

  const safelyResetToBottom = useLocked(resetToBottom);

  const idInView = useCallback(
    (id: string) => {
      if (!anchorId) {
        return false;
      }
      const index = LinkedListHelpers.positionOfAnchored(
        getLinkedList(),
        anchorId,
        id,
      );
      return index >= 0;
    },
    [anchorId, getLinkedList],
  );

  const getAroundIdFromMemory = useCallback(
    (id: string) => {
      const existsInMemory = LinkedListHelpers.getItemFromId(
        getLinkedList(),
        id,
      );
      if (!existsInMemory) {
        return undefined;
      }
      const aroundLinkedList = LinkedListHelpers.insert(
        LinkedListHelpers.buildEarlier(
          getLinkedList(),
          existsInMemory.itemId,
          true,
        ),
        LinkedListHelpers.buildLater(
          getLinkedList(),
          existsInMemory.itemId,
          false,
        ),
      );
      return { linkedList: aroundLinkedList, item: existsInMemory };
    },
    [getLinkedList],
  );

  const resetAroundIdAndScroll = useCallback(
    async (requestId: string, id: string, shouldAnimate: boolean) => {
      const aroundBuiltFromMemory = getAroundIdFromMemory(id);
      if (aroundBuiltFromMemory) {
        bidirectionalLog("Loading around from memory");
        const { item, linkedList } = aroundBuiltFromMemory;
        setAnchorId(item.itemId);
        return scrollToDataInView({
          requestId,
          id: item.itemId,
          shouldAnimate,
          alreadyDisplayed: false,
          linkedList,
        });
      }

      bidirectionalLog("Loading around from network");
      const around = await loadAroundId(id);
      if (!around) {
        return;
      }
      resetLoadLocks();
      setAnchorId(getIdFromItem(around.item));
      await waitForSetStatePropagation();
      return scrollToDataInView({
        requestId,
        id: getIdFromItem(around.item),
        shouldAnimate,
        alreadyDisplayed: false,
        linkedList: around.linkedList,
      });
    },
    [
      getAroundIdFromMemory,
      getIdFromItem,
      loadAroundId,
      resetLoadLocks,
      scrollToDataInView,
    ],
  );

  const handleStartIdChange = useCallback(
    async (id: string | undefined, isAnimated = true) => {
      if (!id) {
        return safelyResetToBottom(generateScrollRequestId());
      }
      if (idInView(id)) {
        return scrollToDataInView({
          requestId: generateScrollRequestId(),
          id,
          shouldAnimate: true,
          alreadyDisplayed: true,
        });
      }
      return resetAroundIdAndScroll(generateScrollRequestId(), id, isAnimated);
    },
    [
      generateScrollRequestId,
      idInView,
      resetAroundIdAndScroll,
      safelyResetToBottom,
      scrollToDataInView,
    ],
  );

  useEffectRealtime(
    ([_handleStartIdChange]) => {
      _handleStartIdChange(startId, false).catch(console.error);
    },
    [startId],
    [handleStartIdChange],
  );

  return {
    onScroll,
    anchor: linkedListAnchor,
    startFromId: handleStartIdChange,
  };
}
