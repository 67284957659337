import { useMemo } from "react";

import { MapControllerLayout } from "@kraaft/shared/components/mapController/mapControllerLayout";
import { MapOrNoMarkers } from "@kraaft/shared/components/mapController/mapOrNoMarkers";
import { useRoomClusterConfig } from "@kraaft/shared/components/mapController/markers/roomMarker/useRoomClusterConfig";
import { useRoomsMarkers } from "@kraaft/shared/components/mapController/roomsMap/useRoomsMarker";
import { MapTrackingInfo } from "@kraaft/shared/components/mapController/types";
import { useMapControllerPortalHostname } from "@kraaft/shared/components/mapController/useMapControllerPortalHostname";
import { useRoomCards } from "@kraaft/shared/core/modules/roomCard/hooks/useRoomCards";
import { useAppRoomFilters } from "@kraaft/shared/core/utils/useAppRoomFilters";
import { RoomsUseMapNavigationReturnType } from "@kraaft/shared/core/utils/useMapNavigation/types";

interface RoomsMapProps {
  mapNavigation: RoomsUseMapNavigationReturnType;
  hideCloseButton?: boolean;
}

const trackingInfo: MapTrackingInfo = { map_type: "conversation_list" };

export const RoomsMap = ({ mapNavigation, hideCloseButton }: RoomsMapProps) => {
  const { geoDetail } = mapNavigation;
  const { poolId, filters } = useAppRoomFilters();
  const { otherRoomCards, pinnedRoomCards, isLoading } = useRoomCards({
    poolId,
    filters,
    loadAll: true,
  });

  const roomCards = useMemo(
    () => [...pinnedRoomCards, ...otherRoomCards],
    [otherRoomCards, pinnedRoomCards],
  );

  const markers = useRoomsMarkers({
    roomCards,
    onMarkerAction: mapNavigation.onMarkerAction,
  });

  const { clusterConfig: roomClusterConfig, roomCardListPanel } =
    useRoomClusterConfig({
      onTooltipPress: mapNavigation.onMarkerAction,
    });

  const mapControllerPortalHostname = useMapControllerPortalHostname();

  return (
    <>
      <MapControllerLayout
        withCloseButton={!hideCloseButton}
        isLoading={isLoading}
        mapControllerPortalHostname={mapControllerPortalHostname}
      >
        <MapOrNoMarkers
          markers={markers}
          geoDetail={geoDetail}
          enableClustering
          clustersConfig={{
            room: roomClusterConfig,
          }}
          trackingInfo={trackingInfo}
          mapControllerPortalHostname={mapControllerPortalHostname}
        />
      </MapControllerLayout>
      {roomCardListPanel}
    </>
  );
};
