import { StyleSheet } from "react-native";

import { Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  subtitle: {
    marginBottom: Spacing.S16,
  },

  inputContainer: {
    marginBottom: Spacing.S8,
  },
});
