import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StatusBar } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { CarouselImage } from "@kraaft/shared/components/carousel/placeholders/carouselImage";
import { CarouselVideo } from "@kraaft/shared/components/carousel/placeholders/carouselVideo";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { getModularFolderAttachmentColumn } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ModularFolderCarouselItem } from "@kraaft/shared/core/modules/modularFolder/components/modularFolderCarousel/modularFolderCarousel.types";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import {
  selectModularFolderCarousel,
  selectModularFolderCarouselColumnInfo,
  selectModularFolderSchemaId,
  selectOfflineModularFolder,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { GeoLocation } from "@kraaft/shared/core/types";
import {
  Carousel,
  Sheet,
  Spacing,
  useCarouselOpenClose,
  useMaxSafeAreaInsets,
} from "@kraaft/ui";
import { CarouselItemRenderer } from "@kraaft/ui/src/display/carousel/carousel.types";

import { ModularRecordUtils } from "../../../schema/modularRecord.utils";
import { selectOfflineModularFolderSchema } from "../../../schema/schema.selectors";
import { KSchemaUtils } from "../../../schema/schema.utils";

function modularFolderAttachmentKeyExtractor(item: ModularFolderCarouselItem) {
  return item.attachment.id;
}

const useCarouselAttachmentsInfo = ({
  modularFolderId,
}: { modularFolderId: string }) => {
  const carouselColumnInfo = useSelector(selectModularFolderCarouselColumnInfo);
  const modularFolder = useSelector(
    selectOfflineModularFolder(modularFolderId),
  );
  const schema = useSelector(
    selectOfflineModularFolderSchema(modularFolder?.schemaId),
  );

  if (
    carouselColumnInfo === undefined ||
    carouselColumnInfo.column === undefined ||
    modularFolder === undefined ||
    schema === undefined
  ) {
    return undefined;
  }

  const { column, initialIndex } = carouselColumnInfo;

  const modularFolderAttachments = getModularFolderAttachmentColumn(
    modularFolder,
    column.key,
    ["image", "video"],
  );

  const isLocked = ModularRecordUtils.getSectionLockInfo(
    schema.rootSection,
    modularFolder.properties,
    KSchemaUtils.getParentSectionKey(schema.rootSection, column.key) ?? "",
  ).isLocked;

  const attachments = modularFolderAttachments.map<ModularFolderCarouselItem>(
    (attachment) => ({ attachment, isLocked }),
  );

  return { column, attachments, initialIndex };
};

interface ModularFolderCarouselProps {
  roomId: string;
  modularFolderId: string;
  schemaId: string;
}

const ModularFolderCarouselSheet = Sheet({
  default: "overlay",
}).create<ModularFolderCarouselProps>(
  ({ Content }) =>
    ({ roomId, schemaId, modularFolderId, onClose }) => {
      const { t } = useTranslation();
      const dispatch = useDispatch();
      const carouselAttachmentsInfo = useCarouselAttachmentsInfo({
        modularFolderId,
      });

      const isColumnPhotoQualityHD =
        carouselAttachmentsInfo?.column.type === KColumnType.attachment &&
        carouselAttachmentsInfo?.column.photoQualityHD;

      const uploadContext = useMemo<UploadAttachmentContext>(
        () => ({
          type: "modularFolder",
          roomId,
          schemaId,
          modularFolderId,
          columnKey: carouselAttachmentsInfo?.column.key,
          isPhotoQualityHD: isColumnPhotoQualityHD,
        }),
        [
          carouselAttachmentsInfo?.column.key,
          isColumnPhotoQualityHD,
          modularFolderId,
          roomId,
          schemaId,
        ],
      );

      const handleGeolocationChange = useCallback(
        (
          item: ModularFolderCarouselItem,
          location: GeoLocation | null | undefined,
        ) => {
          if (carouselAttachmentsInfo === undefined) {
            return;
          }
          dispatch(
            OfflineModularFolderActions.updateAttachmentGeolocation({
              id: modularFolderId,
              attachmentId: item.attachment.id,
              columnKey: carouselAttachmentsInfo.column.key,
              geolocation: location ?? undefined,
            }),
          );
        },
        [carouselAttachmentsInfo, dispatch, modularFolderId],
      );

      const handleDelete = useCallback(
        (item: ModularFolderCarouselItem) => {
          const title =
            item.attachment.type === "image"
              ? t("confirmDeletePhotoTitle")
              : t("confirmDeleteVideoTitle");
          const message =
            item.attachment.type === "image"
              ? t("confirmDeletePhotoFromFolderMessage")
              : t("confirmDeleteVideoFromFolderMessage");
          newConfirmationAlert({
            title,
            message,
            onConfirm: () => {
              dispatch(
                OfflineModularFolderActions.deleteAttachment({
                  id: modularFolderId,
                  attachmentId: item.attachment.id,
                  columnKey: carouselAttachmentsInfo?.column.key,
                }),
              );
            },
          });
        },
        [carouselAttachmentsInfo?.column.key, dispatch, modularFolderId, t],
      );

      const renderItem = useCallback<
        CarouselItemRenderer<ModularFolderCarouselItem>
      >(
        ({ index, item }) => {
          if (item.attachment.type === "image") {
            return (
              <CarouselImage
                index={index}
                item={item}
                attachment={item.attachment}
                roomId={roomId}
                uploadContext={uploadContext}
                onClose={onClose}
                onLocationChange={handleGeolocationChange}
                onDelete={handleDelete}
              />
            );
          }
          if (item.attachment.type === "video") {
            return (
              <CarouselVideo
                index={index}
                item={item}
                attachment={item.attachment}
                roomId={roomId}
                uploadContext={uploadContext}
                onClose={onClose}
                onLocationChange={handleGeolocationChange}
                onDelete={handleDelete}
              />
            );
          }
          return null;
        },
        [onClose, handleDelete, handleGeolocationChange, roomId, uploadContext],
      );

      const insets = useMaxSafeAreaInsets({
        top: Spacing.S8,
        bottom: Spacing.S8,
      });

      if (carouselAttachmentsInfo === undefined) {
        return null;
      }

      return (
        <Content>
          <StatusBar backgroundColor="black" barStyle="light-content" />
          <Carousel
            initialIndex={carouselAttachmentsInfo.initialIndex}
            items={carouselAttachmentsInfo.attachments}
            open
            onClose={onClose}
            keyExtractor={modularFolderAttachmentKeyExtractor}
            renderItem={renderItem}
            insets={insets}
          />
        </Content>
      );
    },
);

export const ModularFolderCarousel = () => {
  const dispatch = useDispatch();
  const modularFolderCarousel = useSelector(selectModularFolderCarousel);
  const schemaId = useSelector(
    selectModularFolderSchemaId(
      modularFolderCarousel?.show ? modularFolderCarousel.folderId : undefined,
    ),
  );
  const { open, onClose } = useCarouselOpenClose(modularFolderCarousel?.show);

  const closeCarousel = useCallback(() => {
    dispatch(CarouselStateActions.close());
  }, [dispatch]);

  const { element } = ModularFolderCarouselSheet.use({
    open,
    onClose,
    onClosed: closeCarousel,
    roomId: modularFolderCarousel?.show ? modularFolderCarousel.roomId : "",
    schemaId,
    modularFolderId: modularFolderCarousel?.show
      ? modularFolderCarousel.folderId
      : "",
  });

  if (!modularFolderCarousel?.show) {
    return null;
  }

  return element;
};
