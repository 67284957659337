import { createReducer, Draft } from "@reduxjs/toolkit";
import { assert } from "ts-essentials";

import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as sendActions from "./send/sendMessageActions";
import * as actions from "./messageActions";
import { MessageSelection, MessageState } from "./messageState";

const initialState: MessageState = {
  rooms: {},
  messageSelectionByRoom: {},
};

function ensureRoomExists(state: Draft<MessageState>, roomId: string) {
  let room = state.rooms[roomId];
  if (!room) {
    room = {
      sentMessagesCount: 0,
      replyingSourceMessageId: null,
      loadingStates: {},
    };
    state.rooms[roomId] = room;
  }
  return room;
}

const messageReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(
    actions.MessageStateActions.clearRoomMessages,
    (state, { payload }) => {
      const { roomId } = payload;

      delete state.rooms[roomId];
    },
  );

  addCase(actions.fetchingUnknownMessages, (state, { payload }) => {
    const room = state.rooms[payload.roomId];
    if (!room) {
      return;
    }
    for (const messageId of payload.messageIds) {
      room.loadingStates[messageId] = true;
    }
  });

  addCase(sendActions.updateSentMessagesCount, (state, { payload }) => {
    const { roomId } = payload;

    const room = ensureRoomExists(state, roomId);
    room.sentMessagesCount++;
  });

  addCase(actions.toggleSelectMessages, (state, { payload }) => {
    const { roomId, selectionType, selectionSource, messageIds, selectState } =
      payload;
    const oldMessageSelection = state.messageSelectionByRoom?.[roomId];

    const newSelection = {
      selectionType: selectionType,
      selectionSource: selectionSource,
      status: undefined,
      selection:
        selectionSource === oldMessageSelection?.selectionSource
          ? (oldMessageSelection?.selection ?? {})
          : {},
    };
    toggleMessageSelection(newSelection, messageIds, selectState);

    state.messageSelectionByRoom[roomId] = newSelection;
  });

  addCase(actions.deselectMessage, (state, { payload }) => {
    const { roomId, messageId, all } = payload;
    if (all === true) {
      state.messageSelectionByRoom[roomId] = {
        selectionType: "notDefined",
        selectionSource: undefined,
        status: undefined,
        selection: {},
      };
    } else if (messageId !== undefined) {
      const oldMessageSelection =
        state.messageSelectionByRoom?.[roomId]?.selection || {};

      const messageSelected = state.messageSelectionByRoom[roomId];
      assert(messageSelected, "messageSelected is undefined");
      const newSelection = {
        ...messageSelected,
        status: undefined,
        selection: oldMessageSelection,
      };

      toggleMessageSelection(newSelection, [messageId], false);

      state.messageSelectionByRoom[roomId] = newSelection;
    }
  });

  addCase(actions.setMessageSelectionProperties, (state, { payload }) => {
    const { roomId, ...properties } = payload;
    const roomMessageSelection = state.messageSelectionByRoom[roomId];
    assert(
      roomMessageSelection,
      `on set selection properties, could not find room message selection for roomId ${roomId}`,
    );
    state.messageSelectionByRoom[roomId] = {
      ...roomMessageSelection,
      ...properties,
    };
  });

  addCase(
    actions.setReplyingSourceMessage,
    (state, { payload: { roomId, messageId } }) => {
      const room = ensureRoomExists(state, roomId);
      room.replyingSourceMessageId = messageId;
    },
  );

  addCase(actions.unsetReplyingSourceMessage, (state, { payload: roomId }) => {
    const room = state.rooms[roomId];
    if (!room) {
      return;
    }
    room.replyingSourceMessageId = null;
  });
});

function toggleMessageSelection(
  messageSelection: MessageSelection,
  messageIds: string[],
  value: boolean,
) {
  if (value) {
    for (const messageId of messageIds) {
      messageSelection.selection[messageId] = true;
    }
  } else {
    for (const messageId of messageIds) {
      delete messageSelection.selection[messageId];
    }
  }

  if (Object.keys(messageSelection.selection).length === 0) {
    messageSelection.selectionSource = undefined;
  }
}

export { messageReducers };
