import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectGridLibrarySchemasForLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";

import { Guards } from "../../services/auth/permissions/guards";
import { selectActor } from "../user/userSelectors";
import { LibrarySchemaActions } from "./librarySchema.actions";

/** this hook subscribes to library schemas for the current pool and returns true if it has at least one */
export function useHasLibrarySchemas() {
  const dispatch = useDispatch();

  const pool = useSelector(selectCurrentPool);
  const actor = useSelector(selectActor);
  const [canListPoolSchema] =
    actor && pool ? Guards.Schema.listSchemas(actor, pool.id) : [false];

  const hasLibrarySchemas =
    useSelector(selectGridLibrarySchemasForLanguage(pool?.poolLanguage))
      .length > 0;

  useEffect(() => {
    if (canListPoolSchema) {
      const payload = {
        companyId: pool?.companyId,
        superadmin: actor?.claims.support === true,
      };
      dispatch(LibrarySchemaActions.subscribe(payload));

      return () => {
        dispatch(LibrarySchemaActions.unsubscribe(payload));
      };
    }
  }, [actor?.claims.support, canListPoolSchema, dispatch, pool?.companyId]);

  return canListPoolSchema && hasLibrarySchemas;
}
