import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import emojiRegex from "emoji-regex";

import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { LibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { getInstantiateLibrarySchemaLoaderId } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions.utils";
import { OfflineLibrarySchemaSelectors } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ModularDisplayExtendedRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { useLoaderState } from "@kraaft/shared/core/utils/useLoader";
import { Button, Icon, Preloader, Text } from "@kraaft/ui";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { FormPreview } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/formPreview";
import { getEmbedYoutubeVideoLink } from "@kraaft/web/src/views/schemaLibrary/librarySchemaShowcase/librarySchemaShowcase.utils";

import { useStyles } from "./librarySchemaShowcase.styles";

type ExplodedEmojiString = (
  | { type: "text"; text: string }
  | { type: "emoji"; emoji: string }
)[];

function splitOnEmojis(input: string): ExplodedEmojiString {
  const regex = emojiRegex();
  const result: ExplodedEmojiString = [];
  let lastIndex = 0;

  for (const match of input.matchAll(regex)) {
    const emojiIndex = match.index;
    if (lastIndex < emojiIndex) {
      result.push({ type: "text", text: input.slice(lastIndex, emojiIndex) });
    }
    result.push({ type: "emoji", emoji: match[0] });
    lastIndex = emojiIndex + match[0].length;
  }

  if (lastIndex < input.length) {
    result.push({ type: "text", text: input.slice(lastIndex) });
  }

  return result;
}

const LibrarySchemaShowcase = () => {
  const { librarySchemaId } = useParams<{ librarySchemaId: string }>();
  const { t } = useTranslation();
  const classes = useStyles();
  const navigationService = useNavigationService();

  const currentUserId = useSelector(selectCurrentUserId);

  const dispatch = useDispatch();
  const currentPoolId = useSelector(selectCurrentPoolId);
  const librarySchema = useSelector(
    OfflineLibrarySchemaSelectors.select(librarySchemaId),
  );

  const { loading: isLoadingUseSchema } = useLoaderState(
    getInstantiateLibrarySchemaLoaderId(librarySchemaId),
  );

  useTrackEventOnce({
    eventName: "View Library Schema",
    schema_id: librarySchemaId,
    schema_name: librarySchema?.schema.name ?? "",
  });

  const handleBackPress = useCallback(() => {
    navigationService.navigate("SchemaLibraryView", {
      tab: librarySchema?.companyId ? "company" : "public",
    });
  }, [librarySchema?.companyId, navigationService]);

  const handleUseTemplatePress = useCallback(() => {
    if (!librarySchema || !currentPoolId) {
      return;
    }
    dispatch(
      LibrarySchemaActions.instantiateLibrarySchema({
        librarySchemaId: librarySchema.id,
        poolId: currentPoolId,
      }),
    );
    trackEvent({
      eventName: "Use Library Schema",
      schema_id: librarySchema.id,
      schema_name: librarySchema.schema.name,
    });
  }, [currentPoolId, dispatch, librarySchema]);

  const handleDownloadSampleReportPress = useCallback(() => {
    if (!librarySchema?.presentation.sampleReport?.downloadUrl) {
      return;
    }

    fileSaver
      .download(
        librarySchema.presentation.sampleReport.downloadUrl,
        librarySchema.presentation.sampleReport.filename,
      )
      .catch(console.error);

    trackEvent({
      eventName: "Download Library Schema Template",
      schema_id: librarySchema.id,
      schema_name: librarySchema.schema.name,
    });
  }, [
    librarySchema?.id,
    librarySchema?.presentation.sampleReport?.downloadUrl,
    librarySchema?.presentation.sampleReport?.filename,
    librarySchema?.schema.name,
  ]);

  if (librarySchema === undefined) {
    return <Preloader />;
  }

  return (
    <ModularDisplayRequirementsProvider>
      <ModularDisplayExtendedRequirementsProvider
        recordType="formPreview"
        noCheckboxConfirmation
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <PageHeader
              title={
                <div className={classes.titleContainer}>
                  <Button
                    accessibilityLabel={t("close")}
                    icon="chevron-left"
                    variant="TERTIARY"
                    size="SMALL"
                    onPress={handleBackPress}
                  />
                  <div className={classes.schemaNameWithIcon}>
                    <Icon
                      name={KSchemaUtils.getSchemaIconName(
                        librarySchema.schema.icon,
                      )}
                    />
                    {librarySchema.schema.name}
                  </div>
                </div>
              }
              titleElementId="settings-schemas-view"
            />
          </div>
          <span className={classes.divider} />
          <div className={classes.content}>
            <div className={classes.leftContainer}>
              <div className={classes.schemaNameWithIcon}>
                <span className={classes.schemaNameTitle}>
                  {splitOnEmojis(librarySchema.schema.name).map(
                    (part, index) =>
                      part.type === "emoji" ? (
                        <span key={index.toString()}>{part.emoji}</span>
                      ) : (
                        part.text
                      ),
                  )}
                </span>
              </div>
              <div className={classes.schemaDescription}>
                <Text size="BODY">
                  {librarySchema.presentation.description ||
                    t("schemaLibraryView.noDescription")}
                </Text>
              </div>
              <div className={classes.actionsContainer}>
                <Button
                  onPress={handleUseTemplatePress}
                  accessibilityLabel={t("schemaLibraryView.useThisTemplate")}
                  text={t("schemaLibraryView.useThisTemplate")}
                  icon="plus"
                  loading={isLoadingUseSchema}
                />
                {librarySchema.presentation.sampleReport ? (
                  <Button
                    onPress={handleDownloadSampleReportPress}
                    accessibilityLabel={t("schemaLibraryView.sampleReport")}
                    text={t("schemaLibraryView.sampleReport")}
                    icon="download-01"
                    variant="SECONDARY"
                  />
                ) : null}
              </div>
              {librarySchema.presentation.video ? (
                <div className={classes.videoContainer}>
                  <div className={classes.textSpacer}>
                    <Text weight="bold" size="TITLE">
                      {t("schemaLibraryView.watchVideo")}
                    </Text>
                  </div>
                  <iframe
                    className={classes.videoFrame}
                    src={getEmbedYoutubeVideoLink(
                      librarySchema.presentation.video?.id,
                    )}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
              ) : null}
            </div>

            <div className={classes.rightContainer}>
              {currentUserId ? (
                <FormPreview
                  currentUserId={currentUserId}
                  schema={librarySchema.schema}
                />
              ) : null}
            </div>
          </div>
        </div>
      </ModularDisplayExtendedRequirementsProvider>
    </ModularDisplayRequirementsProvider>
  );
};

export { LibrarySchemaShowcase };
