import { createSlice } from "@reduxjs/toolkit";

import { OfflineSchemaLibraryTagStateActions } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.offline";
import { SchemaLibraryTagState } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

const initialState: SchemaLibraryTagState = {
  fetched: false,
  schemaLibraryTags: {},
};

export const schemaLibraryTagSlice = createSlice({
  name: "schemaLibraryTag",
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
    addCase(OfflineSchemaLibraryTagStateActions.set, (state, { payload }) => {
      state.fetched = true;
      state.schemaLibraryTags = payload.aggregates;
    });
  },
});
